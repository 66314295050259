import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { UseMutationResult } from "react-query";

import { RTBConfirmationV2 } from "./RTBConfirmationV2";

import { ChatButton } from "../../Components/Chat";

interface Props {
  reservation: any;
  reservationMutation: UseMutationResult;
  handleAmendClick: () => void;
  isAmending: boolean;
  showCancelling?: boolean;
  showAcceptDecline: boolean;
  chatEnabled: boolean;
}

export const BookingCardButtons: React.FC<Props> = ({
  reservation,
  reservationMutation,
  handleAmendClick,
  isAmending,
  showCancelling = false,
  showAcceptDecline,
  chatEnabled,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [action, setAction] = useState<"confirm" | "decline" | "cancel" | null>(null);
  const [dialogText, setDialogText] = useState<string>();

  return (
    <Grid container direction="row" item xs={12} spacing={1} alignItems="center">
      {showAcceptDecline && (
        <>
          <Grid item xs={6} md="auto">
            <Button
              fullWidth
              disabled={isAmending}
              onClick={() => {
                setAction("confirm");
                setDialogText(t("Are you sure you want to accept this reservation"));
                setOpen(true);
              }}
              variant="contained"
              color="success"
            >
              {t("Accept")}
            </Button>
          </Grid>

          <Grid item xs={6} md="auto">
            <Button
              fullWidth
              disabled={isAmending}
              variant="contained"
              onClick={() => {
                setAction("decline");
                setDialogText(t("Are you sure you want to decline this reservation"));
                setOpen(true);
              }}
            >
              {t("Decline")}
            </Button>
          </Grid>
        </>
      )}

      {reservation.status === "AMENDING" && showCancelling === false && (
        <Grid item xs={6} md="auto">
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            onClick={() => {
              setAction("decline");
              setDialogText(t("Are you sure you want to decline this reservation"));
              setOpen(true);
            }}
          >
            {t("Decline")}
          </Button>
        </Grid>
      )}

      {["AMENDING", "AWAITING", "SUBMITTED", "CONFIRMED"].includes(reservation.status) &&
        reservation.source !== "GOOGLE" &&
        showCancelling === false && (
          <Grid item xs={12} md="auto">
            <Button fullWidth color="secondary" variant="contained" onClick={handleAmendClick}>
              {!isAmending ? t("Offer Amendment") : t("Cancel Amendment")}
            </Button>
          </Grid>
        )}

      {chatEnabled && showCancelling === false && (
        <Grid item xs={12} md="auto">
          <ChatButton reservation={reservation} />
        </Grid>
      )}

      {["AMENDING", "AWAITING", "SUBMITTED", "CONFIRMED"].includes(reservation.status) && showCancelling === true && (
        <Grid item xs={6} md="auto">
          <Button
            fullWidth
            variant="contained"
            color="error"
            onClick={() => {
              setAction("cancel");
              setDialogText(t("Are you sure you want to cancel this reservation"));
              setOpen(true);
            }}
          >
            {t("Cancel")}
          </Button>
        </Grid>
      )}

      <RTBConfirmationV2
        open={open}
        setOpen={setOpen}
        action={action}
        text={dialogText}
        subText={
          action === "cancel" &&
          "Cancelling will refund the customer in full. Please provide a reason why you are cancelling this reservation"
        }
        reservation={reservation}
        reservationMutation={reservationMutation}
      />
    </Grid>
  );
};
