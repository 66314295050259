import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onClose: any;
  isOpen: boolean;
  loading?: boolean;
  confirmAction: () => void;
  dialogTitleText: string;
  questionText: string;
  cancelText: string;
  confirmText: string;
  withAcknowledgment?: boolean;
  acknowledgeText?: any;
  acknowledged?: boolean;
  setAcknowledged?: (acknowledged: boolean) => void;
}

const ConfirmationDialog: React.FC<Props> = ({
  onClose,
  isOpen,
  loading,
  confirmAction,
  dialogTitleText,
  questionText,
  cancelText,
  confirmText,
  withAcknowledgment,
  acknowledgeText,
  acknowledged,
  setAcknowledged,
}) => {
  const { t } = useTranslation();
  const [alert, setAlert] = useState<boolean>(false);

  return (
    <Dialog
      className={"ignore-selection-cancel"}
      onClose={() => {
        if (withAcknowledgment) setAcknowledged(false);
        onClose();
      }}
      open={isOpen}
    >
      <DialogTitle>
        <Typography variant="h3">{dialogTitleText}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>{questionText}</Typography>
          {withAcknowledgment && (
            <Box sx={{ mt: 2 }}>
              {acknowledgeText}
              <FormControlLabel
                sx={{
                  display: "block",
                  textAlign: "right",
                  pr: 2,
                }}
                value="start"
                control={
                  <Checkbox
                    checked={acknowledged}
                    onChange={() => {
                      setAcknowledged(!acknowledged);
                      setAlert(false);
                    }}
                    color="primary"
                  />
                }
                label={t<string>("I understand")}
                labelPlacement="start"
              />
              {alert && (
                <Alert severity="warning">{t("You need to acknowledge this warning by checking 'I understand'")}</Alert>
              )}
            </Box>
          )}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" sx={styles.dialogButton} onClick={onClose}>
          {cancelText}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          sx={styles.dialogButton}
          onClick={async () => {
            if (withAcknowledgment && !acknowledged) {
              setAlert(true);
              return;
            }
            await confirmAction();
            onClose();
          }}
          startIcon={loading && <CircularProgress color="inherit" size={20} />}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  dialogButton: {
    minWidth: (theme) => theme.spacing(15),
    ml: 1,
  },
};

export { ConfirmationDialog };
