import { BaseMeetingPoint, BaseProvider, PriceGroup, Provider, PublicReview } from "@skibro/types";
import uniqBy from "lodash.uniqby";
import React, { createContext, useContext, useEffect, useState } from "react";

import { useUser } from "./UserContext";

import API from "../lib/api";
import { deepCopy } from "../lib/helpers";
import { appStorage } from "../lib/storage";

interface ProviderReviewDetails {
  count: number;
  isSummary: boolean;
  metadata: { averageRating: number };
  pages: number;
}

const accountTypes: string[] = [null, "school", "instructor", "guide"];

interface ProviderContextInterface {
  provider: BaseProvider;
  providerId: number | null;
  reviews: PublicReview[];
  reviewsDetails: ProviderReviewDetails;
  priceGroups: { [k: number]: PriceGroup[] };
  error: boolean;
  errorMsg: string;
  loading: boolean;
  meetingPoints: BaseMeetingPoint[];
  loadProvider: (providerId: number, include?: string[]) => void;
  updateProvider: (provider: Partial<BaseProvider | Provider>) => void;
  createProviderPriceGroups: (providerId: number, priceGroups: PriceGroup[]) => void;
  loadPriceGroups: (providerId: number) => void;
  chooseProvider: (providerId: number) => void;
  loadProviderReviewsSummary: (providerId: number) => void;
  loadMeetingPoints: (providerId: number) => void;
  saveMeetingPoint: (meetingPoint: BaseMeetingPoint) => Promise<BaseMeetingPoint>;
  updateMeetingPoint: (meetingPoint: BaseMeetingPoint) => Promise<BaseMeetingPoint>;
  deleteMeetingPoint: (meetingPoint: BaseMeetingPoint) => void;
  loadPageOfProviderReviews: (
    providerId: number,
    page?: number,
    limit?: number,
    sort?: "DESC" | "ASC",
    pageSkippingFrom?: number
  ) => void;
}

const ProviderContext = createContext<ProviderContextInterface>({} as ProviderContextInterface);

const useProviderContextProvider = () => {
  const [provider, setProvider] = useState<BaseProvider>();
  const [providerId, setProviderId] = useState(null);
  const [reviews, setReviews] = useState<PublicReview[]>();
  const [reviewsDetails, setReviewsDetails] = useState<ProviderReviewDetails>();
  const [priceGroups, setPriceGroups] = useState({});
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const { loading: accountLoading, accountType, account, setAccountType } = useUser();
  const [meetingPoints, setMeetingPoints] = useState<BaseMeetingPoint[]>([]);

  useEffect(() => {
    if (accountLoading || !account) return;
    (async () => {
      const pId: number = await appStorage.getItem("providerId");
      if (pId && account?.providers?.map((p) => p.id).includes(pId)) {
        chooseProvider(pId);
      } else {
        chooseProvider(account?.providers[0]?.id);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountLoading, account]);

  useEffect(() => {
    if (provider) {
      setAccountType(accountTypes[provider.providerType.id]);
      setReviews([]);
      setReviewsDetails({ count: 0, isSummary: true, metadata: { averageRating: 0 }, pages: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  const loadMeetingPoints = async (providerId: number): Promise<void> => {
    setLoading(true);
    try {
      const meetingPoints = await API.getMeetingPoints(providerId);
      setMeetingPoints(meetingPoints);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("load meeting points error", error);
      setError(true);
      setErrorMsg(error.message);
    }
  };

  const saveMeetingPoint = async (meetingPoint: BaseMeetingPoint): Promise<BaseMeetingPoint> => {
    setLoading(true);
    try {
      const savedMeetingPoint = await API.createMeetingPoint(meetingPoint);
      setMeetingPoints([...meetingPoints, savedMeetingPoint]);
      setLoading(false);
      return savedMeetingPoint;
    } catch (error) {
      setLoading(false);
      console.error("save meeting points error", error);
      setError(true);
      setErrorMsg(error.message);
    }
  };

  const updateMeetingPoint = async (meetingPoint: BaseMeetingPoint): Promise<BaseMeetingPoint> => {
    setLoading(true);
    try {
      const savedMeetingPoint = await API.updateMeetingPoint(meetingPoint);
      setMeetingPoints(meetingPoints.map((mP) => (mP.id === savedMeetingPoint.id ? savedMeetingPoint : mP)));
      setLoading(false);
      return savedMeetingPoint;
    } catch (error) {
      setLoading(false);
      console.error("update meeting points error", error);
      setError(true);
      setErrorMsg(error.message);
    }
  };

  const deleteMeetingPoint = async (meetingPoint: BaseMeetingPoint): Promise<void> => {
    setLoading(true);
    try {
      await API.deleteMeetingPoint(meetingPoint);
      setMeetingPoints(meetingPoints.filter((mP) => mP.id !== meetingPoint.id));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("delete meeting points error", error);
      setError(true);
      setErrorMsg(error.message);
    }
  };

  const createProviderPriceGroups = async (providerId, newPriceGroups) => {
    try {
      setLoading(true);
      setError(false);
      newPriceGroups = JSON.parse(JSON.stringify(newPriceGroups)).map((p) => {
        if (p.id < 1) p.id = undefined;
        return p;
      });
      const newPostedPriceGroups = await API.createPriceGroups(providerId, newPriceGroups);
      const updatedPriceGroups = deepCopy(priceGroups);
      updatedPriceGroups[providerId] = newPostedPriceGroups;

      setPriceGroups(updatedPriceGroups);
      await appStorage.setItem("storedPriceGroups", {
        storageDate: new Date(),
        data: updatedPriceGroups,
      });
    } catch (error) {
      console.error(error);
      setError(true);
      setErrorMsg(error.message);
    }
    setLoading(false);
  };

  const loadProviderReviewsSummary = async (providerId: number) => {
    try {
      setLoading(true);
      setError(false);
      const result = await API.fetchProviderReviews(providerId, { queryStringParameters: { limit: 1 } });
      setReviewsDetails({
        count: result.count,
        isSummary: true,
        metadata: result.metadata,
        pages: result.pages,
      });
      setReviews(result.items);
    } catch (error) {
      setError(true);
      setErrorMsg(error.message);
    }
    setLoading(false);
  };

  const loadPageOfProviderReviews = async (
    providerId: number,
    page?: number,
    limit?: number,
    sort: "DESC" | "ASC" = "DESC",
    pageSkippingFrom?: number
  ) => {
    try {
      setLoading(true);
      setError(false);
      const { isSummary } = reviewsDetails || {};
      let reviewsFromBetweenPages = [];
      if (pageSkippingFrom && pageSkippingFrom + 1 < page) {
        const diffInPages = page - pageSkippingFrom - 1;
        for (const i of Array(diffInPages).keys()) {
          const { items: extraReviews } = await API.fetchProviderReviews(providerId, {
            queryStringParameters: { limit, page: pageSkippingFrom + i + 1, sort },
          });
          reviewsFromBetweenPages = reviewsFromBetweenPages.concat(extraReviews);
        }
      }
      const result = await API.fetchProviderReviews(providerId, { queryStringParameters: { limit, page, sort } });
      setReviewsDetails({
        count: result.count,
        isSummary: false,
        metadata: result.metadata,
        pages: result.pages,
      });
      // if previous page was summary, replace with full reviews
      if (isSummary || isSummary === undefined) setReviews(result.items);
      // else append to existing reviews
      else setReviews((reviews) => uniqBy([...reviews, ...reviewsFromBetweenPages, ...result.items], "id"));
    } catch (error) {
      console.error(error);
      setError(true);
      setErrorMsg(error.message);
    }
    setLoading(false);
  };

  const loadPriceGroups = async (providerId: number) => {
    try {
      setLoading(true);
      setError(false);
      const result = await API.fetchPriceGroups(providerId);
      const updatedPriceGroups = { ...priceGroups };
      updatedPriceGroups[providerId] = result.items;
      setPriceGroups(updatedPriceGroups);
      await appStorage.setItem("storedPriceGroups", {
        storageDate: new Date(),
        data: updatedPriceGroups,
      });
    } catch (error) {
      setError(true);
      setErrorMsg(error.message);
    }
    setLoading(false);
  };

  const loadProvider = async (providerIdToLoad?: number, include?: string[]) => {
    providerIdToLoad = providerIdToLoad || providerId;
    try {
      setError(false);
      setLoading(true);
      setProvider(null);
      setProviderId(null);
      const newProvider = await API.fetchProviderById(providerIdToLoad, include);
      setProvider(newProvider);
      setProviderId(newProvider.id);
    } catch (error) {
      setError(true);
      console.error(error);
      setErrorMsg(error.message);
    }
    setLoading(false);
  };

  const updateProvider = async (update: Partial<BaseProvider | Provider>) => {
    try {
      setError(false);
      setLoading(true);
      const savedProvider = await API.updateProvider(update);
      setProvider(savedProvider as BaseProvider);
    } catch (error) {
      setError(true);
      setErrorMsg(error.message);
    }
    setLoading(false);
  };

  const chooseProvider = async (providerId: number) => {
    const include =
      accountType === "school"
        ? [
            "languages",
            "qualifications",
            "seasonDates",
            "resorts",
            "chatToken",
            "contact",
            "meetingPoints",
            "userLogins",
            "cancellationPolicy",
          ]
        : [
            "languages",
            "qualifications",
            "seasonDates",
            "chatToken",
            "contact",
            "meetingPoints",
            "userLogins",
            "cancellationPolicy",
          ];
    appStorage.setItem("providerId", providerId);
    API.setProviderId(providerId);
    await loadProvider(providerId, include);
  };

  return {
    provider,
    providerId,
    meetingPoints,
    reviews,
    reviewsDetails,
    priceGroups,
    error,
    errorMsg,
    loading,
    loadProvider,
    updateProvider,
    createProviderPriceGroups,
    loadPriceGroups,
    chooseProvider,
    loadProviderReviewsSummary,
    loadPageOfProviderReviews,
    loadMeetingPoints,
    saveMeetingPoint,
    updateMeetingPoint,
    deleteMeetingPoint,
  };
};

export type { ProviderReviewDetails };

// hook
export const useProvider = () => {
  return useContext(ProviderContext);
};

export const ProviderProvider = ({ children }) => {
  const providerContextProvider = useProviderContextProvider();
  return <ProviderContext.Provider value={providerContextProvider}>{children}</ProviderContext.Provider>;
};
