import { Box, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { Reservation } from "@skibro/types";
import currencyToSymbolMap from "currency-symbol-map/map";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Earnings, getReservationEarnings } from "../../lib/bookings";
import { skiBroColors } from "../../lib/theme";

interface Props {
  reservations: Reservation[];
}
const defaultEarnings: Earnings = {
  past: 0,
  upcoming: 0,
  average: 0,
  next: 0,
};

const Financials: React.FC<Props> = ({ reservations }) => {
  const [earnings, setEarnings] = useState<Earnings>(defaultEarnings);
  const { t } = useTranslation();

  useEffect(() => {
    if (!reservations?.length) {
      return setEarnings(defaultEarnings);
    }
    const newEarnings = getReservationEarnings(reservations);
    setEarnings(newEarnings);
  }, [reservations]);

  return (
    <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <CardHeader title={<Typography variant="h4">{t("Financials")}</Typography>} />

      <CardContent sx={{ height: "100%" }}>
        <Grid container alignItems="space-around" justifyContent="space-around" spacing={3} sx={{ height: "100%" }}>
          <Grid xs={6} item>
            <Box sx={contrastBox}>
              <Typography align="center" variant="h3">
                {currencyToSymbolMap.EUR}
                {(earnings.past / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}
              </Typography>
              <Typography gutterBottom variant="body2" align="center" sx={title}>
                {t("Past earnings")}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={6} item>
            <Box sx={contrastBox}>
              <Typography align="center" variant="h3">
                {currencyToSymbolMap.EUR}
                {(earnings.upcoming / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}
              </Typography>
              <Typography gutterBottom variant="body2" align="center" sx={title}>
                {t("Future earnings")}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={6} item>
            <Box sx={contrastBox}>
              <Typography align="center" variant="h3">
                {currencyToSymbolMap.EUR}
                {(earnings.average / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}
              </Typography>
              <Typography gutterBottom variant="body2" align="center" sx={title}>
                {t("Average earnings")}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={6} item>
            <Box sx={contrastBox}>
              <Typography align="center" variant="h3">
                {currencyToSymbolMap.EUR}
                {(earnings.next / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}
              </Typography>
              <Typography gutterBottom variant="body2" align="center" sx={title}>
                {t("Next payout")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const contrastBox = {
  backgroundColor: skiBroColors.snow[4],
  borderRadius: (theme) => theme.spacing(2),
  p: 2,
};

const title = {
  fontWeight: 600,
};

export { Financials };
