import { Container, Dialog, Divider, FormControl, Grid, MenuItem, TextField } from "@mui/material";
import { Reservation } from "@skibro/types";
import { format, formatDistanceToNowStrict, isAfter, parseISO, subMonths, subWeeks, subYears } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BookingCard from "./BookingsV2/BookingCardV2";
import { BookingValueCards } from "./Dashboard/BookingValueCards";
import { Financials } from "./Dashboard/Financials";
import { ProfileCompletion } from "./Dashboard/ProfileCompletion";
import { ReservationStatusOverview } from "./Dashboard/ReservationStatusOverview";
import { ReviewSummary } from "./Dashboard/ReviewSummary";
import { UpcomingActivity } from "./Dashboard/UpcomingActivity";
import { UpcomingSchedule } from "./Dashboard/UpcomingSchedule";

import { PageHeader } from "../Components/PageHeader";
import { useProvider } from "../Context/ProviderContext";
import { useReservations } from "../Context/ReservationsContext";

const values = [
  {
    label: formatDistanceToNowStrict(subWeeks(new Date(), 1), { addSuffix: true }),
    value: format(subWeeks(new Date(), 1), "yyyy-MM-dd"),
  },
  {
    label: formatDistanceToNowStrict(subMonths(new Date(), 1), { addSuffix: true }),
    value: format(subMonths(new Date(), 1), "yyyy-MM-dd"),
  },
  {
    label: formatDistanceToNowStrict(subMonths(new Date(), 3), { addSuffix: true }),
    value: format(subMonths(new Date(), 3), "yyyy-MM-dd"),
  },
  {
    label: formatDistanceToNowStrict(subMonths(new Date(), 6), { addSuffix: true }),
    default: true,
    value: format(subMonths(new Date(), 6), "yyyy-MM-dd"),
  },
  {
    label: formatDistanceToNowStrict(subYears(new Date(), 1), { addSuffix: true }),
    value: format(subMonths(new Date(), 12), "yyyy-MM-dd"),
  },
  {
    label: `All`,
    value: `1970-01-01`,
  },
];

export const Dashboard: React.FC = () => {
  const { providerId, reviews, reviewsDetails, loadProviderReviewsSummary } = useProvider();
  const { allReservations, reservationMutation } = useReservations();
  const { t } = useTranslation();

  const [filterFromDate, setFilterFromDate] = useState<string>(format(subMonths(new Date(), 6), "yyyy-MM-dd"));
  const [reservations, setReservations] = useState<Reservation[]>(allReservations);
  const [focusedReservation, setFocusedReservation] = useState<Reservation>();
  const [reservationDialogToggle, setReservationDialogToggle] = useState<boolean>(false);

  useEffect(() => {
    const updatedReservations = allReservations.filter((reservation) =>
      isAfter(parseISO(`${reservation.endDate}`), parseISO(filterFromDate))
    );
    setReservations(updatedReservations);
  }, [allReservations, filterFromDate]);

  useEffect(() => {
    if (providerId) loadProviderReviewsSummary(providerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerId]);

  return (
    <>
      <PageHeader
        title={"SkiBro Portal"}
        headerAction={
          <FormControl sx={{ display: { xs: "none", md: "block" } }}>
            <TextField
              sx={{
                minWidth: 100,
              }}
              select
              label={t("Starting from")}
              variant="standard"
              value={filterFromDate}
              type="date"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFilterFromDate(event.target.value);
              }}
            >
              {values.map((menuOption) => (
                <MenuItem
                  selected={menuOption.value === filterFromDate}
                  key={menuOption.value}
                  value={menuOption.value}
                >
                  {t(menuOption.label)}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        }
      />

      <Container maxWidth="xl" sx={{ mb: 15 }}>
        <Grid alignItems={"flex-start"} container spacing={3}>
          <Grid item container xs={12} md={9} spacing={3}>
            <Grid item xs={12}>
              <BookingValueCards reservations={reservations} />
            </Grid>

            <Grid item xs={12}>
              <UpcomingActivity reservations={reservations} />
            </Grid>

            <Grid item xs={12} sm={6} md={12} lg={6}>
              <Financials reservations={reservations} />
            </Grid>

            <Grid item xs={12} sm={6} md={12} lg={6}>
              <ReviewSummary isWidget review={reviews?.[0]} reviewsSummary={reviewsDetails} />
            </Grid>

            <Grid item xs={12} sm={6} md={12} lg={6}>
              <ReservationStatusOverview reservations={reservations}></ReservationStatusOverview>
            </Grid>
          </Grid>

          <Grid item container xs={12} md={3} spacing={3}>
            <Grid item sx={{ display: { xs: "none", md: "block" } }}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item>
              <UpcomingSchedule
                reservations={reservations}
                handleEventClick={(reservation) => {
                  setFocusedReservation(reservation);
                  setReservationDialogToggle(true);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={reservationDialogToggle}
        onClose={() => setReservationDialogToggle(false)}
        maxWidth={"lg"}
        scroll={"body"}
      >
        <BookingCard reservation={focusedReservation} reservationMutation={reservationMutation} isClickable={false} />
      </Dialog>
    </>
  );
};
