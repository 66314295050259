import { Account, User } from "@skibro/types";
import { CognitoUser } from "amazon-cognito-identity-js";
import React, { createContext, useContext, useEffect, useState } from "react";

import API from "../lib/api";

interface UserContextInterface {
  user: User;
  account: Account;
  setAccount: React.Dispatch<React.SetStateAction<Account>>;
  setAccountType: React.Dispatch<React.SetStateAction<string>>;
  accountType: string;
  error: boolean;
  errorMsg: string;
  loading: boolean;
  updateUserProfile: (profile) => void;
  signOut: () => void;
  cognitoUser: CognitoUserAmplify;
}

const UserContext = createContext<UserContextInterface>({
  user: null,
  account: {} as Account,
  setAccount: () => undefined,
  setAccountType: () => undefined,
  accountType: null,
  error: false,
  errorMsg: "",
  loading: true,
  updateUserProfile: () => undefined,
  signOut: () => undefined,
  cognitoUser: null,
});

interface Props {
  cognitoUser: CognitoUserAmplify;
  children: JSX.Element;
  signOut: () => void;
}

const useProvideUser = (cognitoUser: CognitoUserAmplify, signOut: () => void) => {
  const [user, setUser] = useState(null);
  const [account, setAccount] = useState<Account | null>(null);
  const [accountType, setAccountType] = useState<string | null>(null);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        if (cognitoUser.username) {
          setLoading(true);
          const userProfile = await API.getUserProfile(cognitoUser.username);
          setUser(userProfile);
          setAccountType(userProfile.accounts[0].accountType);
          setAccount(userProfile.accounts[0]);
        } else {
          setUser(null);
          setAccountType(null);
          setAccount(null);
        }
      } catch (error) {
        setError(true);
        setErrorMsg(error.message);
      }
      setLoading(false);
    })();
  }, [cognitoUser]);

  const updateUserProfile = async (profile) => {
    setLoading(true);
    try {
      setError(false);
      setErrorMsg("");
      await API.updateProvider(profile);
      setUser(profile);
    } catch (error) {
      console.error("updateProfile error: ", error);
      setError(true);
      setErrorMsg(error.message);
    }
    setLoading(false);
  };

  const logOut = async () => {
    try {
      setLoading(true);
      await signOut();
    } catch (error) {
      console.error("logOut error: ", error);
      setError(true);
      setErrorMsg(error.message);
    }
    setLoading(false);
  };

  return {
    user,
    accountType,
    account,
    setAccount,
    setAccountType,
    error,
    errorMsg,
    loading,
    updateUserProfile,
    signOut: logOut,
    cognitoUser,
  };
};

// hook
export const useUser = () => {
  return useContext(UserContext);
};

export interface CognitoAttributes {
  email: string;
  phone_number: string;
  [key: string]: string;
}
/** Cognito User Interface */
export interface CognitoUserAmplify extends CognitoUser {
  username?: string;
  attributes?: CognitoAttributes;
}

// provider
export const UserProvider: React.FC<Props> = ({ cognitoUser, signOut, children }) => {
  const userContext = useProvideUser(cognitoUser, signOut);
  return <UserContext.Provider value={userContext}>{children}</UserContext.Provider>;
};
