import {
  Button,
  ButtonGroup,
  Checkbox,
  ClickAwayListener,
  Dialog,
  DialogTitle,
  FormControlLabel,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DeclineSummary, Reservation } from "@skibro/types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { UseMutationResult } from "react-query";
import { useProvider } from "../../Context/ProviderContext";
import { ArrowDropDown } from "@mui/icons-material";

import termsAndConditions from "../../resources/TCs.json";

interface Props {
  open: boolean;
  setOpen: (boolean) => void;
  action: "confirm" | "decline" | "cancel";
  text: string;
  subText?: string;
  reservation: Reservation;
  reservationMutation: UseMutationResult;
}

export const RTBConfirmationV2: React.FC<Props> = ({
  open,
  setOpen,
  action,
  text,
  subText,
  reservation,
  reservationMutation,
}) => {
  const { t } = useTranslation();
  const { provider } = useProvider();

  const declineReasons: Omit<DeclineSummary, "details">[] = [
    { id: 1, reason: t("booking.This lesson is full for this period and we have no suitable alternative") },
    { id: 2, reason: t("booking.Lesson is not running") },
    { id: 3, reason: t("booking.Lesson details were incorrect on SkiBro (please provide details)") },
    { id: 4, reason: t("booking.Tried to find an alternative with the client but none were suitable") },
    { id: 5, reason: t("booking.Other (Please provide details)") },
  ];

  const [declineReason, setDeclineReason] = useState<Omit<DeclineSummary, "details">>();
  const [details, setDetails] = useState<DeclineSummary["details"]>("");
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  const [splitBtnOpen, setSplitBtnOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedLocale, setSelectedLocale] = React.useState(provider.contact.language ?? "en");

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    try {
      if (action === "decline") {
        const declineSummary: DeclineSummary = {
          ...declineReason,
          details,
        };

        const updatedReservation = {
          ...reservation,
          declineSummary,
        };

        await reservationMutation
          .mutateAsync({ reservation: updatedReservation })
          .then(async () => await reservationMutation.mutate({ reservation, action }));
      } else if (action === "cancel") {
        await reservationMutation.mutate({ reservation, action, reason: details });
      } else {
        await reservationMutation.mutate({ reservation, action });
      }

      setOpen(false);
    } catch (err) {
      console.error({ err });
    }
  };

  const handleSetDeclineReason = (id: number): void => {
    const reason = declineReasons.find((declineReason) => declineReason.id === id);

    setDeclineReason(reason);
    setDetails("");
  };

  const handleMenuItemClick = (locale) => {
    setSelectedLocale(locale);
    setSplitBtnOpen(false);
  };

  const handleToggle = () => {
    setSplitBtnOpen((prevOpen) => !prevOpen);
  };

  const handleSplitBtnClose = () => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setSplitBtnOpen(false);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ p: 2, pb: 4 }}>
        <Grid item>
          <DialogTitle>
            <Typography variant="h2" component="p" align="center">
              {text}
            </Typography>
          </DialogTitle>
        </Grid>

        {action === "confirm" && (
          <Stack>
            <Typography gutterBottom>{t("By checking the box below you accept the terms and conditions")}</Typography>
            <Stack alignItems="flex-start" justifyContent="center" direction="row" spacing={3} pb={1} flexWrap="wrap">
              <ButtonGroup variant="text" ref={anchorRef}>
                <Button target="_blank" rel="noopener" href={termsAndConditions[selectedLocale].link}>
                  {termsAndConditions[selectedLocale].text}
                </Button>
                <Button size="small" onClick={handleToggle}>
                  <ArrowDropDown />
                </Button>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 9999,
                }}
                open={splitBtnOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleSplitBtnClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {Object.keys(termsAndConditions).map((tC) => (
                            <MenuItem
                              key={tC}
                              disabled={termsAndConditions[tC].link === ""}
                              selected={tC === selectedLocale}
                              onClick={() => handleMenuItemClick(tC)}
                            >
                              {termsAndConditions[tC].text}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              <FormControlLabel
                sx={{
                  ml: 0,
                  justifyContent: "center",
                }}
                control={
                  <Checkbox checked={termsAccepted} onChange={() => setTermsAccepted(!termsAccepted)} color="primary" />
                }
                label={t<string>("Accept terms and conditions")}
                labelPlacement="start"
              />
            </Stack>
          </Stack>
        )}

        {action === "decline" && (
          <Grid item sx={{ width: "100%", pb: 2 }}>
            <Select
              fullWidth
              value={declineReason?.id}
              displayEmpty
              defaultValue={0}
              onChange={(e) => handleSetDeclineReason(e.target.value as number)}
            >
              <MenuItem value={0} disabled>
                {t("booking.Please provide decline reason")}
              </MenuItem>

              {declineReasons.map((declineReason, index) => (
                <MenuItem value={declineReason.id} key={declineReason.id} style={{ whiteSpace: "normal" }}>
                  {`${index + 1}. ${declineReason.reason}`}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}

        {subText && (
          <Grid item>
            <DialogTitle>
              <Typography variant="body1" component="p" align="center">
                {subText}
              </Typography>
            </DialogTitle>
          </Grid>
        )}

        {(action === "decline" || action === "cancel") && (
          <Grid item sx={{ width: "100%", pb: 2 }}>
            <TextField
              label={`${t("booking.Details")} ${[3, 5].includes(declineReason?.id) ? "*" : ""}`}
              fullWidth
              multiline
              value={details}
              rows={2}
              onChange={(e) => setDetails(e.target.value)}
            />
          </Grid>
        )}

        <Grid item container justifyContent="space-around">
          <Grid item xs={5}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              disabled={
                (action === "decline" && !declineReason) ||
                (action === "decline" && [3, 5].includes(declineReason.id) && !details) ||
                (action === "cancel" && !details) ||
                (action === "confirm" && !termsAccepted)
              }
              onClick={handleConfirm}
            >
              {t("SAVE")}
            </Button>
          </Grid>

          <Grid item xs={5}>
            <Button variant="contained" fullWidth color="secondary" onClick={handleClose}>
              {t(action === "cancel" ? "BACK" : "CANCEL")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
