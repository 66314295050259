import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogTitle, Typography } from "@mui/material";
import { refreshAccessToken } from "../config/amplify";
import { useTranslation } from "react-i18next";

interface SessionTimeoutProps {
  signOut: () => void;
}

const SessionTimeoutModal: React.FC<SessionTimeoutProps> = (props) => {
  const { signOut } = props;
  const { t } = useTranslation();
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  useEffect(() => {
    const createTimeoutWarning = () =>
      setTimeout(() => {
        setWarningModalOpen(true);
      }, 1000 * 60 * 50);
    const createTimeoutExpired = () =>
      setTimeout(() => {
        signOut();
      }, 1000 * 60 * 10);
    const timeout = isWarningModalOpen ? createTimeoutExpired() : createTimeoutWarning();
    return () => {
      clearTimeout(timeout);
    };
  }, [isWarningModalOpen]);

  const handleSessionRefreshToken = async () => {
    await refreshAccessToken();
    setWarningModalOpen(false);
  };

  return (
    <Dialog open={isWarningModalOpen} onClose={handleSessionRefreshToken}>
      <DialogTitle>
        <Typography variant="h4">{t("Your session is about to expire")}</Typography>
      </DialogTitle>
      <DialogActions sx={{ px: 3, pb: 3, justifyContent: "center" }}>
        <Button variant="contained" color="primary" onClick={handleSessionRefreshToken}>
          {t("Stay logged on")}
        </Button>
        <Button variant="outlined" color="primary" onClick={signOut}>
          {t("Log off")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionTimeoutModal;
