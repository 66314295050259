import { RestAPI as ampApi, RestAPIClass } from "@aws-amplify/api-rest";
import { Auth } from "@aws-amplify/auth";
import {
  Account,
  Availability,
  BaseMeetingPoint,
  BaseProduct,
  BaseResort,
  BasketV2,
  Block,
  CancellationPolicy,
  Currency,
  File as IFile,
  Product,
  PublicReview,
  Reservation,
  SupportedLanguageCodes,
  User,
  YoutubeVideo,
} from "@skibro/types";
import { ApiResponse, ApisauceInstance, CancelToken, create } from "apisauce";
import { AxiosResponse, CancelTokenSource } from "axios";
import i18next from "i18next";

import { IFormInputs as ISignupFormInputs } from "../Components/SignupDialog";
import { statuses } from "../config/constants";
import envConfig, { adminApiKey, wpRestUrl } from "../config/settings";

class API {
  auth: typeof Auth;
  integrationsApi;
  ampApi: RestAPIClass;
  private bookingsAPIUrl?: string;
  private bookingsAPI: ApisauceInstance;
  private contentApi: ApisauceInstance;
  private providerId?: number;
  private headers = {
    Accept: "application/json",
    "Accept-Language": i18next.language || "en",
  };
  private cancelTokens: { [k: string]: CancelTokenSource } = {};
  adminApiOptions = {
    headers: {
      "x-api-key": adminApiKey,
      "x-provider-id": `${this.providerId}`,
    },
    response: true,
  };
  private awsApiOptions = {
    response: true,
  };
  private handleError: <T>(error: Error & { response: AxiosResponse<T> }) => void;

  constructor() {
    if (process.env.REACT_APP_STAGE === "local") {
      this.adminApiOptions.headers["cognitoIdentityId"] = "e13327ff-9dec-4de4-9e4b-f7f33a7abff1";
    }
    this.auth = Auth;
    this.ampApi = ampApi;
    this.bookingsAPIUrl = envConfig.BOOKINGS_API_URL;

    this.headers = {
      Accept: "application/json",
      "Accept-Language": i18next.language || "en",
    };
    this.bookingsAPI = create({
      baseURL: this.bookingsAPIUrl,
      headers: this.headers,
    });
    this.contentApi = create({
      baseURL: wpRestUrl,
    });

    this.handleError = (error) => {
      console.log(error);
      const { response } = error;
      if (response.status === 401) {
        this.auth.signOut();
      } else {
        throw error;
      }
    };
  }

  setProviderId(id: number) {
    this.providerId = id;
    this.adminApiOptions.headers["x-provider-id"] = `${id}`;
  }

  // WP Content
  fetchTutorials = async (): Promise<YoutubeVideo[]> => {
    const response: ApiResponse<YoutubeVideo[]> = await this.contentApi.get("/youtube-videos", {
      categories: [529, 538, 540, 542, 544],
      per_page: 50,
    });
    if (response.status === 200) return response.data;
    throw new Error(response.problem);
  };

  // V2
  // reservations
  fetchProviderReservations = async (providerId: number): Promise<Reservation[]> => {
    // if (this.cancelTokens.providerBookings) this.cancelTokens.providerBookings.cancel();
    // this.cancelTokens.providerBookings = CancelToken.source();
    try {
      let allReservations: Reservation[] = [];

      const internalReservations: AxiosResponse<{ items: Reservation[] }> = await this.ampApi
        .get("Admin API", `/v2/providers/${providerId}/reservations`, this.adminApiOptions)
        .catch(this.handleError);
      if (internalReservations && internalReservations.status >= 200 && internalReservations.status < 300) {
        allReservations = [...internalReservations.data.items];
      } else {
        this.handleError({ ...new Error(internalReservations.statusText), response: internalReservations });
      }

      const externalReservations: AxiosResponse<{ items: Reservation[] }> = await this.ampApi
        .get("Admin API", `/v2/providers/${providerId}/reservations`, {
          ...this.adminApiOptions,
          queryStringParameters: { source: "GOOGLE" },
        })
        .catch(this.handleError);
      if (externalReservations && externalReservations.status >= 200 && externalReservations.status < 300) {
        allReservations = [...allReservations, ...externalReservations.data.items];
      } else {
        this.handleError({ ...new Error(externalReservations.statusText), response: externalReservations });
      }

      return allReservations;
    } catch (error) {
      // this.cancelTokens.providerBookings = undefined;
      console.log({ error });
      this.handleError({ ...new Error(error), response: { status: 500 } as AxiosResponse });
    }
  };

  updateReservation = async (reservation: Reservation) => {
    const { id: reservationId } = reservation;
    const response = await this.ampApi
      .put(
        "Admin API",
        `/v2/reservations/${reservationId}`,
        Object.assign({}, this.adminApiOptions, {}, { body: reservation })
      )
      .catch(this.handleError);

    if (response) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  commitReservationAction = async (reservationId: Reservation["id"], action: "confirm" | "decline") => {
    const response = await this.ampApi
      .put("Bookings API", `/v2/reservations/${reservationId}/${action}`, Object.assign({}, this.awsApiOptions))
      .catch(this.handleError);
    if (response) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  cancelReservation = async (reservationId: Reservation["id"], reason: string) => {
    const response = await this.ampApi
      .put(
        "Bookings API",
        `/v2/reservations/${reservationId}/cancel`,
        Object.assign({}, this.awsApiOptions, {}, { body: { cancellationReason: reason } })
      )
      .catch(this.handleError);
    if (response) return { item: response.data };
    this.handleError({ ...new Error(response.statusText), response });
  };

  // Bookings
  fetchProviderBookings = async (providerId: number) => {
    if (this.cancelTokens.providerBookings) this.cancelTokens.providerBookings.cancel();
    this.cancelTokens.providerBookings = CancelToken.source();
    const response = await this.ampApi
      .get("Admin API", `/providers/${providerId}/bookings`, this.adminApiOptions)
      .catch(this.handleError);
    this.cancelTokens.providerBookings = undefined;
    if (response) return response.data.items.filter((booking) => statuses[booking.status] !== undefined);
    this.handleError({ ...new Error(response.statusText), response });
  };

  getUserProfile = async (userId: string) => {
    const response: AxiosResponse<User> = await this.ampApi
      .get("Admin API", `/users/${userId}`, this.adminApiOptions)
      .catch(this.handleError);
    if (response) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  updateUserProfile = async (userProfile) => {
    const response = await this.ampApi
      .patch(
        "Admin API",
        `/users/${userProfile.userId}`,
        Object.assign({}, this.adminApiOptions, {}, { body: userProfile })
      )
      .catch(this.handleError);
    if (response) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  activateAccount = async (data: ISignupFormInputs, userId: User["id"]): Promise<Account> => {
    const response = await this.ampApi
      .put("Admin API", `/users/${userId}/activate`, Object.assign({}, this.adminApiOptions, {}, { body: data }))
      .catch(this.handleError);
    return response.data;
  };

  fetchBookingLogs = async (bookingId) => {
    const response = await this.ampApi
      .get("Admin API", `/bookings/${bookingId}/logs`, this.adminApiOptions)
      .catch(this.handleError);
    if (response.data) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  updateBooking = async (booking) => {
    const { bookingId } = booking;
    const response = await this.ampApi
      .patch("Admin API", `/bookings/${bookingId}`, Object.assign({}, this.adminApiOptions, {}, { body: booking }))
      .catch(this.handleError);
    return response.data;

    // call the DM2 function to update status on DM2
    // this.handleError(new Error(`bookingId not found in DM1.  DM2 interaction will be deployed shortly`);
  };

  createCustomBasket = async (providerId, customBookingConfig): Promise<BasketV2> => {
    const response = await this.ampApi
      .post(
        "Admin API",
        `/v2/providers/${providerId}/custom-baskets`,
        Object.assign({}, this.adminApiOptions, {}, { body: customBookingConfig })
      )
      .catch(this.handleError);
    return response.data;
  };

  // /** Admin API */
  // / Providers
  fetchProviderById = async (
    providerId,
    include = [
      "languages",
      "qualifications",
      "seasonDates",
      "resorts",
      "contact",
      "meetingPoints",
      "cancellationPolicy",
    ]
  ) => {
    if (this.cancelTokens.provider) this.cancelTokens.provider.cancel();
    this.cancelTokens.provider = CancelToken.source();
    const response: AxiosResponse = await this.ampApi
      .get(
        "Admin API",
        `/providers/${providerId}`,
        Object.assign({}, this.adminApiOptions, {
          queryStringParameters: {
            include,
          },
        })
      )
      .catch(this.handleError);
    this.cancelTokens.provider = undefined;
    if (response.data) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  updateProvider = async (provider) => {
    const response: AxiosResponse = await this.ampApi
      .patch("Admin API", `/providers/${provider.id}`, Object.assign({}, this.adminApiOptions, { body: provider }))
      .catch(this.handleError);
    if (response.data) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  // / Products
  fetchProducts = async (
    providerId: number,
    options: { queryStringParameters: { include: string[]; providerId?: number } } = {
      queryStringParameters: {
        include: ["meetingPoints", "resorts", "activities", "levels", "tags", "includedItems", "notIncludedItems"],
      },
    }
  ) => {
    if (this.cancelTokens.products) this.cancelTokens.products.cancel();
    this.cancelTokens.products = CancelToken.source();
    options.queryStringParameters.providerId = providerId;
    const response: AxiosResponse = await this.ampApi
      .get("Admin API", `/products`, Object.assign({}, this.adminApiOptions, options))
      .catch(this.handleError);
    this.cancelTokens.products = undefined;
    if (response.data) return response.data.items;
    this.handleError({ ...new Error(response.statusText), response });
  };

  addProduct = async (product: Partial<BaseProduct>) => {
    const response = await this.ampApi
      .post("Admin API", `/products`, Object.assign({}, this.adminApiOptions, { body: product }))
      .catch(this.handleError);
    if (response.data) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  duplicateProduct = async (productId: BaseProduct["id"]) => {
    const response = await this.ampApi
      .post(
        "Admin API",
        `/products`,
        Object.assign({}, this.adminApiOptions, {
          body: {},
          queryStringParameters: {
            sourceId: productId,
          },
        })
      )
      .catch(this.handleError);
    if (response.data) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  republishProduct = async (product: Partial<BaseProduct>) => {
    const response = await this.ampApi
      .put("Admin API", `/products/${product.id}/republish`, this.adminApiOptions)
      .catch(this.handleError);
    if (response?.status === 202) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  fetchProductById = async (productId) => {
    const response: AxiosResponse = await this.ampApi
      .get(
        "Admin API",
        `/products/${productId}`,
        Object.assign({}, this.adminApiOptions, {
          queryStringParameters: {
            sourceId: productId,
            include: ["levels", "activities", "tags"],
          },
        })
      )
      .catch(this.handleError);
    if (response?.data) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  updateProduct = async (product) => {
    const response: AxiosResponse = await this.ampApi
      .patch("Admin API", `/products/${product.id}`, Object.assign({}, this.adminApiOptions, { body: product }))
      .catch(this.handleError);
    if (response.data) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  deleteProduct = async (productId: number) => {
    const response = await this.ampApi
      .del("Admin API", `/products/${productId}`, this.adminApiOptions)
      .catch(this.handleError);
    if (response.status === 204) return true;
    this.handleError({ ...new Error(response.statusText), response });
  };

  // product variants
  fetchProductVariants = async (productId: number) => {
    const response: AxiosResponse = await this.ampApi
      .get("Admin API", `/products/${productId}/variants`, Object.assign({}, this.adminApiOptions))
      .catch(this.handleError);
    if (response.data) return response.data.items;
    this.handleError({ ...new Error(response.statusText), response });
  };

  // 482 NOTE: temporary function until current instructor variant flow is deprecated
  fetchInstructorProductVariants = async (productId: number) => {
    const response: AxiosResponse = await this.ampApi
      .get(
        "Admin API",
        `/products/${productId}/variants`,
        Object.assign({ queryStringParameters: { isInstructor: true } }, this.adminApiOptions)
      )
      .catch(this.handleError);
    if (response.data) return response.data.items;
    this.handleError({ ...new Error(response.statusText), response });
  };

  addProductVariant = async (variant) => {
    const response = await this.ampApi
      .post(
        "Admin API",
        `/products/${variant.productId}/variants`,
        Object.assign({}, this.adminApiOptions, { body: variant })
      )
      .catch(this.handleError);
    if (response.data) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  copyProductVariants = async (productId, productVariants, overwrite = false) => {
    const response = await this.ampApi
      .patch(
        "Admin API",
        `/products/${productId}/variants`,
        Object.assign(
          { queryStringParameters: { overwrite, sourceId: productVariants[0].productId } },
          this.adminApiOptions,
          { body: productVariants }
        )
      )
      .catch(this.handleError);
    if (response.data) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  updateProductVariant = async (variant) => {
    const response = await this.ampApi
      .patch(
        "Admin API",
        `/products/${variant.productId}/variants/${variant.id}`,
        Object.assign({}, this.adminApiOptions, { body: variant })
      )
      .catch(this.handleError);
    if (response.data) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  deleteProductVariant = async (variant) => {
    const response = await this.ampApi
      .del("Admin API", `/products/${variant.productId}/variants/${variant.id}`, this.adminApiOptions)
      .catch(this.handleError);
    if (response.status === 204) return true;
    this.handleError({ ...new Error(response.statusText), response });
  };

  saveVariantCollection = async (productId: number, productVariants) => {
    const response = await this.ampApi
      .put(
        "Admin API",
        `/products/${productId}/variants-collection`,
        Object.assign({}, this.adminApiOptions, { body: productVariants })
      )
      .catch(this.handleError);
    if (response.status === 201) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  // price priceGroups
  createPriceGroups = async (productId: number, priceGroups) => {
    const response = await this.ampApi
      .put(
        "Admin API",
        `/providers/${productId}/price-group-collection`,
        Object.assign({}, this.adminApiOptions, { body: priceGroups })
      )
      .catch(this.handleError);
    if (response.status === 201) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  fetchPriceGroups = async (providerId: number) => {
    const response = await this.ampApi
      .get("Admin API", `/providers/${providerId}/price-groups`, this.adminApiOptions)
      .catch(this.handleError);
    if (response.status === 200) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  fetchProviderReviews = async (
    providerId: number,
    options: {
      queryStringParameters: {
        limit?: number;
        page?: number;
        sort?: "DESC" | "ASC";
      };
    } = {
      queryStringParameters: {
        limit: 10,
        page: 1,
        sort: "DESC",
      },
    }
  ) => {
    const response: AxiosResponse<{
      items: PublicReview[];
      count: number;
      metadata: { averageRating: number };
      pages: number;
    }> = await this.ampApi
      .get("Admin API", `/providers/${providerId}/reviews`, Object.assign({}, this.adminApiOptions, options))
      .catch(this.handleError);
    if (response.status === 200) return response.data;
    throw new Error(response.statusText);
  };

  updateProviderReview = async (review: PublicReview): Promise<PublicReview> => {
    const response: AxiosResponse = await this.ampApi
      .patch("Admin API", `/reviews/${review.id}`, Object.assign({}, this.adminApiOptions, { body: review }))
      .catch(this.handleError);
    if (response.data) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  fetchProviderLiveProducts = async (providerId, options = { queryStringParameters: { hasLiveInstances: true } }) => {
    const response: AxiosResponse<{ items: Pick<Product, "id" | "name">[] }> = await this.ampApi.get(
      "Admin API",
      `/providers/${providerId}/products`,
      Object.assign({}, this.adminApiOptions, options)
    );
    if (response.status === 200) return response.data.items;
    throw new Error(response.statusText);
  };

  // resorts
  fetchResorts = async <T = { items: MinimalBaseResort[] }>(
    options = {
      queryStringParameters: {
        active: true,
        include: ["id", "name", "coords"],
        sort: "name:asc",
      },
    }
  ) => {
    const response: AxiosResponse<T> = await this.ampApi
      .get("Admin API", `/resorts`, Object.assign({}, this.adminApiOptions, options))
      .catch(this.handleError);
    if (response.status === 200) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  fetchCountries = async (options = { queryStringParameters: { sort: "name:asc" } }) => {
    const response: AxiosResponse = await this.ampApi
      .get("Admin API", `/countries`, Object.assign({}, this.adminApiOptions, options))
      .catch(this.handleError);
    if (response.status === 200) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };
  // activities
  fetchActivities = async (options = { queryStringParameters: { sort: "name:asc" } }) => {
    const response: AxiosResponse = await this.ampApi
      .get("Admin API", `/activities`, Object.assign({}, this.adminApiOptions, options))
      .catch(this.handleError);
    if (response.status === 200) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  // levels
  fetchLevels = async (options = { queryStringParameters: { sort: "name:asc" } }) => {
    const response: AxiosResponse = await this.ampApi
      .get("Admin API", `/levels`, Object.assign({}, this.adminApiOptions, options))
      .catch(this.handleError);
    if (response.status === 200) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  // tags
  fetchTags = async (options = { queryStringParameters: { sort: "id:asc" } }) => {
    const response: AxiosResponse = await this.ampApi
      .get("Admin API", `/tags`, Object.assign({}, this.adminApiOptions, options))
      .catch(this.handleError);
    if (response.status === 200) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  // includedItems
  fetchIncludedItems = async (options = { queryStringParameters: { sort: "id:asc" } }) => {
    const response: AxiosResponse = await this.ampApi
      .get("Admin API", `/included-items`, Object.assign({}, this.adminApiOptions, options))
      .catch(this.handleError);
    if (response.status === 200) return response.data.items;
    this.handleError({ ...new Error(response.statusText), response });
  };

  // languages
  fetchLanguages = async (options = { queryStringParameters: { sort: ["id:asc"] } }) => {
    const response: AxiosResponse = await this.ampApi
      .get("Admin API", `/languages`, Object.assign({}, this.adminApiOptions, options))
      .catch(this.handleError);
    if (response.status === 200) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  // qualifications
  fetchQualifications = async (options = { queryStringParameters: { sort: "name:asc" } }) => {
    const response: AxiosResponse = await this.ampApi
      .get("Admin API", `/qualifications`, Object.assign({}, this.adminApiOptions, options))
      .catch(this.handleError);
    if (response.status === 200) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  getMeetingPoints = async (providerId: number): Promise<BaseMeetingPoint[]> => {
    const response: AxiosResponse = await this.ampApi
      .get("Admin API", `/providers/${providerId}/meeting-points`, { ...this.adminApiOptions })
      .catch(this.handleError);
    if (response.status === 200) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  createMeetingPoint = async (meetingPoint: BaseMeetingPoint): Promise<BaseMeetingPoint> => {
    const response: AxiosResponse = await this.ampApi
      .post(
        "Admin API",
        `/providers/${meetingPoint.providerId}/meeting-points`,
        Object.assign({}, this.adminApiOptions, { body: meetingPoint })
      )
      .catch(this.handleError);
    if (response.status === 201) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  updateMeetingPoint = async (meetingPoint: BaseMeetingPoint): Promise<BaseMeetingPoint> => {
    const response: AxiosResponse = await this.ampApi
      .patch(
        "Admin API",
        `/providers/${meetingPoint.providerId}/meeting-points/${meetingPoint.id}`,
        Object.assign({}, this.adminApiOptions, { body: meetingPoint })
      )
      .catch(this.handleError);
    if (response.data) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  // generate OAuth
  getOAuthRedirect = async (providerId: number): Promise<string> => {
    try {
      const response: AxiosResponse = await this.ampApi
        .get("Admin API", `/providers/${providerId}/calendar`, this.adminApiOptions)
        .catch(this.handleError);
      if (response && response.status >= 200 && response.status < 300) return response.data;
      this.handleError({ ...new Error(response.statusText), response });
    } catch (error) {
      console.log({ error });
      this.handleError({ ...new Error(error), response: { status: 500 } as AxiosResponse });
    }
  };

  googleWebhookSetup = async (params: URLSearchParams): Promise<string> => {
    try {
      const response: AxiosResponse = await this.ampApi
        .get("Admin API", `/providers/webhook-setup?${params.toString()}`, this.adminApiOptions)
        .catch(this.handleError);
      if (response && response.status >= 200 && response.status < 300) return response.data;
      this.handleError({ ...new Error(response.statusText), response });
    } catch (error) {
      console.log({ error });
      this.handleError({ ...new Error(error), response: { status: 500 } as AxiosResponse });
    }
  };

  removeGoogleWebhook = async (providerId: number): Promise<string> => {
    try {
      const response: AxiosResponse = await this.ampApi
        .del("Admin API", `/providers/webhook-setup`, {
          ...this.adminApiOptions,
          queryStringParameters: { providerId },
        })
        .catch(this.handleError);
      if (response && response.status >= 200 && response.status < 300) return response.data;
      this.handleError({ ...new Error(response.statusText), response });
    } catch (error) {
      console.log({ error });
      this.handleError({ ...new Error(error), response: { status: 500 } as AxiosResponse });
    }
  };

  deleteMeetingPoint = async (meetingPoint: BaseMeetingPoint): Promise<boolean> => {
    const response: AxiosResponse = await this.ampApi
      .del("Admin API", `/providers/${meetingPoint.providerId}/meeting-points/${meetingPoint.id}`, this.adminApiOptions)
      .catch(this.handleError);
    if (response.status === 204) return true;
    this.handleError({ ...new Error(response.statusText), response });
  };

  // meeting points
  createMeetingPoints = async (providerId: number, meetingPoints) => {
    const response: AxiosResponse = await this.ampApi
      .put(
        "Admin API",
        `/providers/${providerId}/meeting-point-collection`,
        Object.assign({}, this.adminApiOptions, { body: meetingPoints })
      )
      .catch(this.handleError);
    if (response.status === 201) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  // meeting points
  createSeasonDates = async (providerId: number, seasonDates) => {
    const response: AxiosResponse = await this.ampApi
      .put(
        "Admin API",
        `/providers/${providerId}/season-date-collection`,
        Object.assign({}, this.adminApiOptions, { body: seasonDates })
      )
      .catch(this.handleError);
    if (response.status === 201) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };
  // //////////////////////
  createAvailabilities = async (productId: number, availabilities: Availability[]) => {
    const response: AxiosResponse = await this.ampApi
      .put(
        "Admin API",
        `/products/${productId}/availabilities-collection`,
        Object.assign({}, this.adminApiOptions, { body: availabilities })
      )
      .catch(this.handleError);
    if (response.status === 201) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };
  // / Availabilities
  fetchAvailabilities = async (productId: number): Promise<Availability[]> => {
    if (this.cancelTokens.productAvailabilities) this.cancelTokens.productAvailabilities.cancel();
    this.cancelTokens.productAvailabilities = CancelToken.source();
    const response: AxiosResponse<{ items: Availability[] }> = await this.ampApi
      .get("Admin API", `/products/${productId}/availabilities`, this.adminApiOptions)
      .catch(this.handleError);
    this.cancelTokens.productAvailabilities = undefined;
    if (response.data) return response.data.items;
    this.handleError({ ...new Error(response.statusText), response });
  };

  fetchProviderAvailabilities = async (providerId: number): Promise<Availability[]> => {
    if (this.cancelTokens.providerAvailabilities) this.cancelTokens.providerAvailabilities.cancel();
    this.cancelTokens.providerAvailabilities = CancelToken.source();
    const response: AxiosResponse<{ items: Availability[] }> = await this.ampApi
      .get("Admin API", `/v2/providers/${providerId}/availabilities`, this.adminApiOptions)
      .catch(this.handleError);
    this.cancelTokens.providerAvailabilities = undefined;
    if (response.data) return response.data.items;
    this.handleError({ ...new Error(response.statusText), response });
  };
  // //////////////////////

  deleteAvailability = async (availability: Availability, productId: number) => {
    const response: AxiosResponse = await this.ampApi
      .del("Admin API", `/products/${productId}/availabilities/${availability.id}`, this.adminApiOptions)
      .catch(this.handleError);
    if (response.status === 204) return true;
    this.handleError({ ...new Error(response.statusText), response });
  };

  addAvailability = async (availability: Partial<Availability>, productId: number) => {
    const response: AxiosResponse = await this.ampApi
      .post(
        "Admin API",
        `/products/${productId}/availabilities`,
        Object.assign({}, this.adminApiOptions, { body: availability })
      )
      .catch(this.handleError);
    if (response.status === 201) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  updateAvailability = async (availability: Availability, productId: number) => {
    const response: AxiosResponse = await this.ampApi
      .patch(
        "Admin API",
        `/products/${productId}/availabilities/${availability.id}`,
        Object.assign({}, this.adminApiOptions, { body: availability })
      )
      .catch(this.handleError);
    if (response.status === 200) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  fetchCurrencies = async (currencyCode?: Currency["code"]): Promise<Currency[]> => {
    const response = await this.bookingsAPI.get<{ items: Currency[] }, { message: string }>(
      `/currencies?currencyCode=${currencyCode || "EUR"}`
    );
    if (response.ok === true) {
      if (response.status === 200) return response.data.items;
      throw new Error(response.originalError);
    }
    throw new Error(response.data.message);
  };

  fetchCancellationPolicies = async (): Promise<CancellationPolicy[]> => {
    const response: AxiosResponse<{ items: CancellationPolicy[] }> = await this.ampApi.get(
      "Admin API",
      `/cancellation-policies`,
      this.adminApiOptions
    );
    if (response.status === 200) return response.data.items;
    this.handleError({ ...new Error(response.statusText), response });
  };

  fetchImagesForProduct = async (productId: number): Promise<Pick<BaseProduct, "id" | "images">> => {
    const response: AxiosResponse<BaseProduct> = await this.ampApi
      .get("Admin API", `/products/${productId}/images`, this.adminApiOptions)
      .catch(this.handleError);
    if (response.status === 200) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  preUploadProductImages = async (
    productId: number,
    images: Omit<IFile, "id" | "uploader">[]
  ): Promise<Pick<BaseProduct, "id" | "images">> => {
    const response: AxiosResponse<BaseProduct> = await this.ampApi
      .post("Admin API", `/products/${productId}/images`, Object.assign({}, this.adminApiOptions, { body: images }))
      .catch(this.handleError);
    if (response.status === 200) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  cleanupImageEntityAfterDeleteInS3 = async (imageId: string) => {
    const response: AxiosResponse = await this.ampApi
      .del("Admin API", `/images/${imageId}`, this.adminApiOptions)
      .catch(this.handleError);
    if (response.status === 204) return true;
    this.handleError({ ...new Error(response.statusText), response });
  };

  convertCurrencies = async (from: string, to: string, value: number): Promise<number> => {
    const response = await this.bookingsAPI.get<number, { message: string }>(`/currencies/${from}/${to}/${value}`);

    if (response.ok === true) {
      if (response.status === 200) return response.data;
      throw new Error(response.originalError);
    }
    throw new Error(response.data.message);
  };

  createAvailabilityV2 = async (productId: number, availability: Partial<Availability>): Promise<Availability> => {
    const response: AxiosResponse = await this.ampApi
      .post(
        "Admin API",
        `/products/${productId}/availabilities-v2`,
        Object.assign({}, this.adminApiOptions, { body: availability })
      )
      .catch(this.handleError);
    if (response.status === 201) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  getProductAvailabilitiesV2 = async (productId: number): Promise<Availability[]> => {
    try {
      const response: AxiosResponse = await this.ampApi
        .get("Admin API", `/products/${productId}/availabilities-v2`, Object.assign({}, this.adminApiOptions))
        .catch(this.handleError);
      if (response.status === 200) return response.data;
      this.handleError({ ...new Error(response.statusText), response });
    } catch (err) {
      console.log(err);
    }
  };

  getAvailabilityV2 = async (productId: number, availabilityId: number): Promise<Availability> => {
    const response: AxiosResponse = await this.ampApi
      .get(
        "Admin API",
        `/products/${productId}/availabilities-v2/${availabilityId}`,
        Object.assign({}, this.adminApiOptions)
      )
      .catch(this.handleError);
    if (response.status === 200) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  updateAvailabilityV2 = async (productId: number, availability: Partial<Availability>): Promise<Availability> => {
    const response: AxiosResponse = await this.ampApi
      .patch(
        "Admin API",
        `/products/${productId}/availabilities-v2/${availability.id}`,
        Object.assign({}, this.adminApiOptions, { body: availability })
      )
      .catch(this.handleError);
    if (response.status === 200) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  deleteAvailabilityV2 = async (productId: number, availabilityId: number): Promise<Availability> => {
    const response: AxiosResponse = await this.ampApi
      .del(
        "Admin API",
        `/products/${productId}/availabilities-v2/${availabilityId}`,
        Object.assign({}, this.adminApiOptions)
      )
      .catch(this.handleError);
    if (response.status === 204) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  bulkCreateAvailabilitiesV2 = async (
    productId: number,
    availabilities: Partial<Availability>[]
  ): Promise<Availability[]> => {
    const response: AxiosResponse = await this.ampApi
      .post(
        "Admin API",
        `/products/${productId}/availabilities-v2-collection`,
        Object.assign({}, this.adminApiOptions, { body: availabilities })
      )
      .catch(this.handleError);
    if (response.status === 201) return response.data;
    this.handleError({ ...new Error(response.statusText), response });
  };

  translate = async (body: {
    text: string;
    from: SupportedLanguageCodes;
    to: SupportedLanguageCodes;
  }): Promise<{ text: string; detected }> => {
    const response: AxiosResponse = await this.ampApi.post(
      "Admin API",
      `/v2/translate`,
      Object.assign({}, this.adminApiOptions, { body })
    );
    return response.data.text;
  };

  generateDescription = async (
    body: {
      product?: Product;
      baseProduct?: BaseProduct;
      keywords?: string[];
      extraInfo?: string;
      include?: string[];
      providerId?: number;
    },
    type = "product"
  ) => {
    const response: AxiosResponse = await this.ampApi.post(
      "Admin API",
      "/v2/generate-description",
      Object.assign({}, this.adminApiOptions, {
        body,
        queryStringParameters: {
          type,
        },
      })
    );
    return response.data;
  };

  fetchBlocks = async (providerId: number): Promise<Block[]> => {
    try {
      const response: AxiosResponse = await this.ampApi
        .get("Admin API", `/providers/${providerId}/blocks`, Object.assign({}, this.adminApiOptions))
        .catch(this.handleError);
      if (response.status === 200) return response.data.items;
      this.handleError({ ...new Error(response.statusText), response });
    } catch (err) {
      console.log(err);
    }
  };

  createBlock = async (block: Block): Promise<Block> => {
    try {
      const response: AxiosResponse = await this.ampApi
        .post(
          "Admin API",
          `/providers/${block.providerId}/blocks`,
          Object.assign({}, this.adminApiOptions, { body: block })
        )
        .catch(this.handleError);
      if (response.status === 201) return response.data;
      this.handleError({ ...new Error(response.statusText), response });
    } catch (err) {
      console.log(err);
    }
  };

  deleteBlock = async (providerId: number, blockId: number): Promise<boolean> => {
    try {
      const response: AxiosResponse = await this.ampApi
        .del("Admin API", `/providers/${providerId}/blocks/${blockId}`, Object.assign({}, this.adminApiOptions))
        .catch(this.handleError);
      if (response.status === 204) return true;
      this.handleError({ ...new Error(response.statusText), response });
    } catch (err) {
      console.log(err);
    }
  };
}

type MinimalBaseResort = Pick<BaseResort, "id" | "name" | "coords">;

export type { MinimalBaseResort };

export default new API();
