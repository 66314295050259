import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Grid, IconButton, MenuItem, TextField } from "@mui/material";
import { Participant, BaseActivity } from "@skibro/types";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const ParticipantSelector: React.FC<{
  participant: Participant;
  index: number;
  activity: BaseActivity;
  control: Control<any, any>;
  fieldStub?: string;
  onDelete: (index: number) => void;
  onChange: (index: number, participant: Participant) => void;
}> = ({ participant, index, control, fieldStub = "participants", onDelete, onChange, activity }) => {
  const { t } = useTranslation();

  return (
    <Box key={index}>
      <Grid container direction="row" spacing={1} key={index}>
        <Grid item xs={3}>
          <Controller
            name={`${fieldStub}.${index}.firstName`}
            control={control}
            defaultValue={participant.firstName}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("First Name")}
                fullWidth
                onChange={(e) => field.onChange(e)}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name={`${fieldStub}.${index}.lastName`}
            control={control}
            defaultValue={participant.lastName}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("Last Name")}
                fullWidth
                onChange={(e) => field.onChange(e)}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name={`${fieldStub}.${index}.age`}
            control={control}
            defaultValue={participant.age}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("Age")}
                fullWidth
                onChange={(e) => field.onChange(e)}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item xs={3}>
          <Controller
            name={`${fieldStub}.${index}.reservationLevels.[0].baseLevel`}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                select
                value={field.value?.id}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                label={t("Level")}
                onChange={(e) => {
                  const level = activity.baseLevels.find((l) => l.id === parseInt(e.target.value));
                  field.onChange(level);
                }}
              >
                {activity?.baseLevels?.map((l, i) => (
                  <MenuItem key={`level-${i}`} value={l.id}>
                    {`${l.order}. ${l.name}`}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={1}>
          <IconButton onClick={() => onDelete(index)} size="large">
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};
