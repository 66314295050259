import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Card, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import { formatISO, isAfter, isSameDay } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import isEqual from "lodash.isequal";

import { AmendmentForm } from "./AmendmentForm";
import { useProducts } from "../../Context/ProductsContext";
import { useReservations } from "../../Context/ReservationsContext";
import { Amendment, BookingStatus, Reservation } from "@skibro/types";

interface Props {
  reservation: Reservation;
  closeDialog: () => void;
}

export const CreateAmendment: React.FC<Props> = ({ reservation, closeDialog }) => {
  const { reservationMutation } = useReservations();
  const { convertProductValueToCustomerValue } = useProducts();
  const { t } = useTranslation();

  const [comparisonReservation, setComparisonReservation] = useState<Reservation>();
  const [amendedReservation, setAmendedReservation] = useState<Reservation>();

  useEffect(() => {
    if (reservation.status === "AMENDING") {
      const lastReservation = createComparisonReservationFromRecord();
      setComparisonReservation(lastReservation);
      setAmendedReservation(lastReservation);
    } else {
      setComparisonReservation(reservation);
      setAmendedReservation(reservation);
    }
  }, [reservation]);

  const createComparisonReservationFromRecord = (): Reservation => {
    const latestOfferedAmendment = reservation.amendmentsOffered.sort((a, b) =>
      isAfter(new Date(a.offeredAt), new Date(b.offeredAt)) ? -1 : 1
    )[0];
    const extractedFromValues = Object.entries(latestOfferedAmendment)
      .filter(([key]) =>
        [
          "daysTimes",
          "productValue",
          "customerValue",
          "activity",
          "startDate",
          "endDate",
          "participants",
          "meetingPoint",
        ].includes(key)
      )
      .reduce((acc, [key, { from }]) => ({ ...acc, [key]: from }), {});

    const lastBaseValueReservation = { ...reservation, ...extractedFromValues };

    return lastBaseValueReservation;
  };

  const triggerBookingUpdate = async (amendedReservation: Reservation, amendmentReason: string): Promise<void> => {
    const amendments = await getAmendedDifferences(amendedReservation, amendmentReason);

    let updateReservationPayload = {
      ...amendedReservation,
      amendmentsOffered: updateAmendmentRecords(amendments),
      status: "AMENDING" as BookingStatus,
    };

    if (amendments.customerValue) {
      updateReservationPayload = {
        ...updateReservationPayload,
        customerValue: amendments.customerValue.to,
      };
    }

    await reservationMutation.mutate({ reservation: updateReservationPayload });

    closeDialog();
  };

  const getAmendedDifferences = async (
    amendedReservation: Reservation,
    amendmentReason: string
  ): Promise<Partial<Amendment>> => {
    const amendments: Partial<Amendment> = {
      reason: amendmentReason,
    };

    if (!isEqual(amendedReservation.participants, comparisonReservation.participants)) {
      amendments.participants = {
        from: comparisonReservation.participants,
        to: amendedReservation.participants,
      };
    }

    if (amendedReservation.productValue !== comparisonReservation.productValue) {
      const updatedCustomerValue = await getCustomerValue(amendedReservation);

      amendments.productValue = {
        from: comparisonReservation.productValue,
        to: amendedReservation.productValue,
      };

      amendments.customerValue = {
        from: comparisonReservation.customerValue,
        to: updatedCustomerValue,
      };
    }

    if (amendedReservation.activity.id !== comparisonReservation.activity.id) {
      amendments.activity = {
        from: comparisonReservation.activity as any,
        to: amendedReservation.activity as any,
      };
    }

    if (!isEqual(comparisonReservation.daysTimes, amendedReservation.daysTimes)) {
      amendments.daysTimes = {
        from: comparisonReservation.daysTimes,
        to: amendedReservation.daysTimes,
      };

      if (!isSameDay(new Date(comparisonReservation.startDate), new Date(amendedReservation.startDate))) {
        amendments.startDate = {
          from: comparisonReservation.startDate,
          to: amendedReservation.startDate,
        };
      }

      if (!isSameDay(new Date(comparisonReservation.endDate), new Date(amendedReservation.endDate))) {
        amendments.endDate = {
          from: comparisonReservation.endDate,
          to: amendedReservation.endDate,
        };
      }
    }
    if (comparisonReservation.meetingPoint.description !== amendedReservation.meetingPoint.description) {
      amendments.meetingPoint = {
        description: {
          from: comparisonReservation.meetingPoint.description,
          to: amendedReservation.meetingPoint.description,
        },
      };
    }
    return amendments;
  };

  const getCustomerValue = async (reservation: Reservation): Promise<number> => {
    const productValue = reservation.bookingFeeRate
      ? reservation.productValue + reservation.productValue * reservation.bookingFeeRate
      : reservation.productValue;

    const convertedValue = await convertProductValueToCustomerValue(
      reservation.productCurrency,
      reservation.customerCurrency,
      productValue
    );

    return convertedValue;
  };

  const updateAmendmentRecords = (amendments: Partial<Amendment>): Amendment[] => {
    const updatedAmendmentRecord = {
      ...amendments,
      offeredAt: formatISO(Date.now()),
    } as Amendment;

    if (reservation.status === "AMENDING") {
      return [updatedAmendmentRecord, ...reservation.amendmentsOffered.slice(1)];
    }

    return [updatedAmendmentRecord, ...reservation.amendmentsOffered];
  };

  return (
    <Card sx={{ overflowY: "scroll" }}>
      <CardHeader
        title={<Typography variant="h3">{t<string>("Amend Booking")}</Typography>}
        subheader={`${reservation.booking.uniqueBookingReference} - ${reservation.id} - ${reservation.booking.partyDetails.fullName}`}
        action={
          <IconButton onClick={closeDialog}>
            <CloseRoundedIcon />
          </IconButton>
        }
      />
      <CardContent>
        {amendedReservation && comparisonReservation && (
          <AmendmentForm reservation={amendedReservation} closeDialog={closeDialog} submit={triggerBookingUpdate} />
        )}
      </CardContent>
    </Card>
  );
};
