import { Alert, Grid, Hidden, Link, SxProps, Typography } from "@mui/material";
import { Amendment, Participant, Reservation } from "@skibro/types";
import { differenceInYears } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";

import { DisplayDaysTimes } from "./DisplayDaysTimesV2";
import { OfferedChangeCompared } from "./OfferedChangeCompared";

interface Props {
  partyDetails: {
    email: string;
    lastName: string;
    firstName: string;
    countryCode: string;
    phoneNumber: string;
  };
  status: string;
  reservation: Reservation;
  amendmentsOffered: Amendment;
  showEmail: boolean;
}

const DisplayDetails: React.FC<Props> = ({ partyDetails, status, reservation, amendmentsOffered, showEmail }) => {
  const { t } = useTranslation();

  const isAmendmentOffered = (fieldName): boolean => {
    return (
      amendmentsOffered && Object.keys(amendmentsOffered).length && Object.keys(amendmentsOffered).includes(fieldName)
    );
  };

  const createParticipantAgeString = (participant: Participant): string => {
    let age;

    if (participant.age) {
      age = participant.age;
    }
    if (!participant.age && participant.dob) {
      age = differenceInYears(new Date(), new Date(participant.dob));
    }

    if (age > 17) return `${age} (${t("Adult")})`;
    if (age > 12) return `${age} (${t("Teen")})`;
    return `${age} (${t("Kid")})`;
  };

  const activity = reservation.activity?.name;
  const bookingFee =
    ((reservation.productValue / (1 + parseFloat(`${reservation.bookingFeeRate}`))) * reservation.bookingFeeRate) / 100;

  return (
    <Grid direction="row" alignContent="flex-start" container>
      {amendmentsOffered && Object.keys(amendmentsOffered).length && (
        <Alert severity="info" sx={styles.distanceBit}>
          {status === "CONFIRMED" || status === "COMPLETED"
            ? t("Changes highlighted below have been accepted by the customer")
            : t("Changes highlighted below have been offered to customer")}
        </Alert>
      )}

      {partyDetails?.phoneNumber && (
        <Grid direction="row" alignItems="center" container>
          <Grid item xs={12} md={2}>
            <Typography variant="h5" color="textSecondary" component="p" sx={styles.moreDetailName}>
              {t("booking.customerContact")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={10} sx={styles.bookingDetailsSingle}>
            <Link
              variant="body1"
              component="a"
              color="primary"
              href={`tel:${partyDetails?.phoneNumber}`}
              sx={styles.moreDetailValue}
            >
              {`(${partyDetails?.countryCode}) ${partyDetails?.phoneNumber}`}
            </Link>
          </Grid>
        </Grid>
      )}

      {partyDetails?.email && showEmail && (
        <Grid direction="row" alignItems="center" container>
          <Grid item xs={12} md={2}>
            <Typography variant="h5" color="textSecondary" component="p" sx={styles.moreDetailName}>
              {t("booking.customerEmail")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={10} sx={styles.bookingDetailsSingle}>
            <Link
              variant="body1"
              component="a"
              color="primary"
              href={`mailto:${partyDetails?.email}`}
              sx={styles.moreDetailValue}
            >
              {partyDetails?.email}
            </Link>
          </Grid>
        </Grid>
      )}

      <Grid container alignItems="center">
        <Grid item xs={12} md={2}>
          <Typography variant="h5" color="textSecondary" component="p" sx={styles.moreDetailName}>
            {t("Product Name")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={10} sx={styles.bookingDetailsSingle}>
          <Typography variant="body1" sx={styles.currencyPrefix} display="inline">
            {reservation?.product.name}
          </Typography>
        </Grid>
      </Grid>

      {reservation.source !== "GOOGLE" && (
        <>
          <Grid container alignItems="center">
            <Grid item xs={12} md={2}>
              <Typography variant="h5" color="textSecondary" component="p" sx={styles.moreDetailName}>
                {t("booking.lessonPrice")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={10} sx={styles.bookingDetailsSingle}>
              {isAmendmentOffered("productValue") ? (
                <OfferedChangeCompared
                  blockStyleMimic={true}
                  from={reservation?.amendmentsOffered[0]?.productValue?.from / 100}
                  to={reservation?.amendmentsOffered[0]?.productValue?.to / 100 + " " + reservation.productCurrency}
                  inlineOrBlock="inline"
                />
              ) : (
                <Typography variant="body1" sx={styles.currencyPrefix} display="inline">
                  {`${reservation.productValue / 100} ${reservation.productCurrency}`}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item xs={12} md={2}>
              <Typography variant="h5" color="textSecondary" component="p" sx={styles.moreDetailName}>
                {t("booking.Booking Fee")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={10} sx={styles.bookingDetailsSingle}>
              <Typography variant="body1" sx={styles.currencyPrefix} display="inline">
                {`${bookingFee} ${reservation.productCurrency}`}
              </Typography>
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item xs={12} md={2}>
              <Typography variant="h5" color="textSecondary" component="p" sx={styles.moreDetailName}>
                {t("Activity")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={10} sx={styles.bookingDetailsSingle}>
              {isAmendmentOffered("activity") ? (
                <OfferedChangeCompared
                  blockStyleMimic={true}
                  from={reservation?.amendmentsOffered[0]?.activity?.from.name}
                  to={reservation?.amendmentsOffered[0]?.activity?.to.name}
                  inlineOrBlock="inline"
                />
              ) : (
                <Typography variant="body1" component="p" sx={styles.moreDetailValue}>
                  {activity}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item xs={12} md={2}>
              <Typography variant="h5" color="textSecondary" component="p" sx={styles.moreDetailName}>
                {t("search.language")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={10} sx={styles.bookingDetailsSingle}>
              <Typography variant="body1" component="p" sx={styles.moreDetailValue}>
                {reservation.language || ""}
              </Typography>
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item xs={12} md={2}>
              <Typography variant="h5" color="textSecondary" component="p" sx={styles.moreDetailName}>
                {t("Meeting point")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={10} sx={styles.bookingDetailsSingle}>
              {isAmendmentOffered("meetingPoint") ? (
                <OfferedChangeCompared
                  blockStyleMimic={true}
                  from={amendmentsOffered.meetingPoint.description?.from}
                  to={amendmentsOffered.meetingPoint.description?.to}
                  inlineOrBlock="inline"
                />
              ) : (
                <Typography variant="body1" component="p" sx={styles.moreDetailValue}>
                  {reservation?.meetingPoint?.description}
                </Typography>
              )}
            </Grid>
          </Grid>
        </>
      )}

      <Grid item xs={12} sx={{ mb: 2 }}>
        <DisplayDaysTimes reservation={reservation} isAmendmentOffered={isAmendmentOffered} />
      </Grid>

      {reservation.participants?.length > 0 && (
        <Grid direction="row" alignContent="flex-start" container>
          <Grid item xs={12} md={2}>
            <Typography variant="h5" color="textSecondary" component="p" sx={styles.moreDetailName}>
              {t("bookingSummary.participants")}
            </Typography>
          </Grid>
          <Grid item container xs={12} md={10} sx={styles.bookingDetailsSingle}>
            <Hidden only="xs">
              <Grid item xs={5} sm={2}>
                <Typography variant="h6" color="textSecondary" component="p" sx={styles.moreDetailNameLeft}>
                  {t("booking.name")}
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2}>
                <Typography variant="h6" color="textSecondary" component="p" sx={styles.moreDetailNameLeft}>
                  {t("common.age")}
                </Typography>
              </Grid>
              {reservation.participants[0].reservationLevels[0].baseLevel && (
                <Grid item xs={6} sm={4}>
                  <Typography variant="h6" color="textSecondary" component="p" sx={styles.moreDetailNameLeft}>
                    {t("common.level")}
                  </Typography>
                </Grid>
              )}
              {reservation.participants[0].medal && (
                <Grid item xs={6} sm={4}>
                  <Typography variant="h6" color="textSecondary" component="p" sx={styles.moreDetailNameLeft}>
                    {t("Medal")}
                  </Typography>
                </Grid>
              )}
            </Hidden>
          </Grid>

          <Grid item xs={12} md={2}></Grid>

          <Grid item xs={12} md={10}>
            {reservation.participants?.map((participant, idx) => {
              return (
                <Grid container key={`participant-${idx}`} sx={styles.bookingDetailsSingle}>
                  <Hidden smUp>
                    <Grid item xs={3}>
                      <Typography variant="h6" color="textSecondary" component="p" sx={styles.moreDetailNameLeft}>
                        {t("booking.name")}
                      </Typography>
                    </Grid>
                  </Hidden>
                  <Grid item xs={8} sm={2}>
                    <Typography variant="body1" component="p" sx={styles.moreDetailValue}>
                      {participant.firstName} {participant.lastName}
                    </Typography>
                  </Grid>
                  <Hidden smUp>
                    <Grid item xs={3}>
                      <Typography variant="h6" color="textSecondary" component="p" sx={styles.moreDetailNameLeft}>
                        {t("common.age")}
                      </Typography>
                    </Grid>
                  </Hidden>
                  <Grid item xs={8} sm={2}>
                    <Typography variant="body1" component="p" sx={styles.moreDetailValue}>
                      {createParticipantAgeString(participant)}
                    </Typography>
                  </Grid>

                  {participant.reservationLevels[0].baseLevel && (
                    <>
                      <Hidden smUp>
                        <Grid item xs={3}>
                          <Typography variant="h6" color="textSecondary" component="p" sx={styles.moreDetailNameLeft}>
                            {t("common.level")}
                          </Typography>
                        </Grid>
                      </Hidden>
                      <Grid item xs={8} sm={4}>
                        <Typography variant="body1" component="p" sx={styles.moreDetailValue}>
                          {participant.reservationLevels[0].baseLevel.name}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {participant.medal && (
                    <>
                      <Hidden smUp>
                        <Grid item xs={3}>
                          <Typography variant="h6" color="textSecondary" component="p" sx={styles.moreDetailNameLeft}>
                            {t("Medal")}
                          </Typography>
                        </Grid>
                      </Hidden>
                      <Grid item xs={8} sm={4}>
                        <Typography variant="body1" component="p" sx={styles.moreDetailValue}>
                          {participant.medal}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  {idx < reservation.participants.length - 1 && (
                    <Hidden smUp>
                      <Grid item xs={12}>
                        <hr />
                      </Grid>
                    </Hidden>
                  )}
                </Grid>
              );
            })}
          </Grid>

          {reservation.customerMessage && (
            <Grid container item alignItems="center">
              <Grid item xs={12} md={2}>
                <Typography variant="h5" color="textSecondary" component="p" sx={styles.moreDetailName}>
                  {t("Customer message")}
                </Typography>
              </Grid>

              <Grid item xs={12} md={10}>
                <Typography variant="body1" sx={styles.bookingDetailsSingle}>
                  {reservation.customerMessage}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

const styles: { [x: string]: SxProps } = {
  bookingDetailsSingleTel: {
    pl: {
      xs: 0,
      md: 2.5,
    },
    my: 0.5,
    mx: 0,
  },
  bookingDetailsSingle: {
    pl: {
      xs: 0,
      md: 2.5,
    },
    mb: {
      xs: 2,
      md: 0,
    },
  },
  moreDetailName: {
    my: 0.5,
    mx: 0,
    textAlign: { xs: "left", lg: "right" },
    textDecoration: {
      xs: "underline",
      md: "none",
    },
  },
  moreDetailValue: {
    textAlign: "left",
    m: 0.5,
  },
  distanceBit: {
    my: 2,
    mx: 1,
  },
  currencyPrefix: {
    pt: 0.5,
    mr: 0.5,
  },
  moreDetailNameLeft: {
    textAlign: "left",
    m: 0.5,
  },
};

export { DisplayDetails };
