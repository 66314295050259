import { Draggable } from "@hello-pangea/dnd";
import { IconButton, ListItem, ListItemIcon, ListItemText, TextField } from "@mui/material";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { useEffect, useRef, useState } from "react";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import Save from "@mui/icons-material/Save";

export type DraggableListItemProps = {
  item: { id: string; primary?: string; secondary?: string };
  index: number;
  onDelete?: (id: string) => void;
  onSave?: (id: string, text: string) => void;
};

const DraggableListItem = ({ item, index, onDelete, onSave }: DraggableListItemProps) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const textRef = useRef(null);

  useEffect(() => {
    setEditing(!!(item.primary === ""));
  }, []);

  return (
    <Draggable draggableId={item.id} index={index} key={item.id}>
      {(provided) => (
        <ListItem
          divider
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          secondaryAction={
            <>
              {editing ? (
                <IconButton
                  key={`iconSaveBtn-${item.id}${index}`}
                  onClick={() => {
                    if (textRef.current.value.length > 100) {
                      setIsError(true);
                    } else {
                      setEditing(false);
                      onSave(item.id, textRef.current.value);
                    }
                  }}
                >
                  <Save />
                </IconButton>
              ) : (
                <IconButton key={`iconEditBtn-${item.id}${index}`} onClick={() => setEditing(true)}>
                  <Edit />
                </IconButton>
              )}
              <IconButton key={`iconDelBtn-${item.id}${index}`} onClick={() => onDelete(item.id)}>
                <Delete />
              </IconButton>
            </>
          }
        >
          <ListItemIcon>
            <DragHandleIcon />
          </ListItemIcon>
          {editing ? (
            <TextField
              sx={{ pr: 10 }}
              fullWidth
              inputRef={textRef}
              variant="standard"
              defaultValue={item?.primary}
              helperText="100 characters max"
              error={isError}
            />
          ) : (
            <ListItemText primary={item?.primary} secondary={item?.secondary} />
          )}
        </ListItem>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
