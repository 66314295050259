import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LicenseInfo } from "@mui/x-license-pro";
import enLocale from "date-fns/locale/en-GB";
import frLocale from "date-fns/locale/fr";
import React from "react";
import { useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

import "./lib/i18n";

import { IntercomSetup } from "./Components/IntercomSetup";
import { SignupDialog } from "./Components/SignupDialog";
import { configureAmplify, setS3Config } from "./config/amplify";
import { config } from "./config/settings";
import { ContentProvider } from "./Context/ContentContext";
import { ProductsProvider } from "./Context/ProductsContext";
import { ProviderProvider } from "./Context/ProviderContext";
import { ReservationsProvider } from "./Context/ReservationsContext";
import { SideBarProvider } from "./Context/SideBarContext";
import { ChatProvider } from "./Context/useChat";
import { UserProvider } from "./Context/UserContext";
import { appStorage } from "./lib/storage";
import theme from "./lib/theme";
import AppContent from "./Views/AppContent";
import { authComponents } from "./Views/Authentication/Components";
import { WebSocketProvider } from "./Context/WebsocketContext";

const localeMap = {
  en: enLocale,
  fr: frLocale,
};

LicenseInfo.setLicenseKey(
  "678d210b7edd9c2805f885dbdc5542c7Tz03Mjc2MyxFPTE3MjM3MzY3MjUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

configureAmplify();
setS3Config(config[process.env.REACT_APP_STAGE || "develop"].S3BUCKETNAME, "public");

const queryClient = new QueryClient();

const App: React.FC = () => {
  const { i18n } = useTranslation();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Authenticator
            components={authComponents}
            loginMechanisms={["email"]}
            socialProviders={["google"]}
            className="amplify-authenticator-container"
          >
            {({ signOut, user }) => (
              <QueryClientProvider client={queryClient}>
                <UserProvider
                  cognitoUser={user}
                  signOut={() => {
                    appStorage.clear();
                    signOut();
                  }}
                >
                  <WebSocketProvider>
                    <ContentProvider>
                      <ProviderProvider>
                        <ProductsProvider>
                          <ReservationsProvider>
                            <ChatProvider>
                              <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[i18n.language]}>
                                <IntercomSetup />
                                <SideBarProvider>
                                  <AppContent />
                                </SideBarProvider>
                                <SignupDialog />
                              </LocalizationProvider>
                            </ChatProvider>
                          </ReservationsProvider>
                        </ProductsProvider>
                      </ProviderProvider>
                    </ContentProvider>
                  </WebSocketProvider>
                </UserProvider>
              </QueryClientProvider>
            )}
          </Authenticator>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
