export const statuses = {
  AMENDING: {
    displayAs: "Amendments sent - Awaiting confirmation",
    showRTBButtons: false,
    className: "amending",
  },
  CANCELLED_BY_ADMIN: {
    displayAs: "Cancelled",
    showRTBButtons: false,
    className: "cancelled",
  },
  CANCELLED: {
    displayAs: "Cancelled",
    showRTBButtons: false,
    className: "cancelled",
  },
  RTB_PAYMENT_REFUNDED: {
    displayAs: "Cancelled",
    showRTBButtons: false,
    className: "cancelled",
  },
  DECLINED: {
    displayAs: "Declined",
    showRTBButtons: false,
    className: "declined",
  },
  PENDING: {
    displayAs: "Confirmed",
    showRTBButtons: false,
    className: "confirmed",
  },
  CONFIRMED: {
    displayAs: "Confirmed",
    showRTBButtons: false,
    className: "confirmed",
  },
  COMPLETED: {
    displayAs: "Completed",
    showRTBButtons: false,
    className: "completed",
  },
  RTB_PAYMENT_HOLDING: {
    displayAs: "Awaiting confirmation",
    showRTBButtons: true,
    className: "awaiting",
  },
  RTB_PAYMENT_PENDING: {
    displayAs: "Awaiting confirmation",
    showRTBButtons: true,
    className: "awaiting",
  },
  AWAITING: {
    displayAs: "Awaiting confirmation",
    showRTBButtons: true,
    className: "awaiting",
  },
  AUTHENTICATING: {
    displayAs: "Payment failed",
    showRTBButtons: false,
    className: "authenticating",
  },
  ARCHIVED: {
    displayAs: "Payment failed",
    showRTBButtons: false,
    className: "authenticating",
  },
};

export const allowedActivities = [
  "Skiing",
  "Snowboarding",
  "Off-Piste Skiing",
  "Off-Piste Snowboarding",
  "Ski Touring",
  "Splitboarding",
  "Telemark",
  "Freestyle Skiing",
  "Freestyle Snowboarding",
  "Snowshoeing",
  "Igloo",
  "Alpine Horn",
  "Massage",
  "Wellness",
  "Cross Country Skiing",
];

export const defaultTimes = [
  {
    start: 540,
    duration: 120,
  },
  {
    start: 540,
    duration: 180,
  },
  {
    start: 540,
    duration: 240,
  },
  {
    start: 540,
    duration: 480,
  },
  {
    start: 600,
    duration: 180,
  },
  {
    start: 720,
    duration: 120,
  },
  {
    start: 780,
    duration: 180,
  },
  {
    start: 780,
    duration: 240,
  },
  {
    start: 840,
    duration: 120,
  },
  {
    start: 840,
    duration: 180,
  },
];
