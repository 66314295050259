import { Reservation } from "@skibro/types";
import { isAfter, isBefore, nextTuesday, parseISO, previousMonday } from "date-fns";

export interface Earnings {
  past: number;
  upcoming: number;
  average: number;
  next: number;
}

export const getReservationEarnings = (reservations: Reservation[]): Earnings => {
  const newEarnings: Earnings = { past: 0, upcoming: 0, average: 0, next: 0 } as any;

  reservations.forEach((reservation) => {
    if (reservation.status === "ARCHIVED") {
      return;
    }
    switch (reservation.status) {
      case "COMPLETED":
        newEarnings.past += reservation.productValue;
        if (isAfter(parseISO(`${reservation.endDate}`), previousMonday(new Date()))) {
          newEarnings.next += (1 - reservation.providerCommissionRate) * reservation.productValue;
        }
        break;
      case "CONFIRMED":
        if (
          isBefore(parseISO(`${reservation.endDate}`), nextTuesday(new Date())) &&
          isAfter(parseISO(`${reservation.endDate}`), previousMonday(new Date()))
        ) {
          newEarnings.next += (1 - reservation.providerCommissionRate) * reservation.productValue;
        }
        if (isAfter(parseISO(`${reservation.endDate}`), previousMonday(new Date()))) {
          newEarnings.upcoming += (1 - reservation.providerCommissionRate) * reservation.productValue;
        }
        break;
    }
    const paidEarnings = reservations.filter((r) => ["COMPLETED", "CONFIRMED"].includes(r.status));

    newEarnings.average = Math.round(
      ((1 - reservation.providerCommissionRate) * paidEarnings.reduce((acc, r) => acc + r.productValue, 0)) /
        paidEarnings.length
    );
  });
  return newEarnings;
};
