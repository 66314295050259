export const deepCopy = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

export const formatNumber = (participantDetails, customerDetails) => {
  if (
    participantDetails?.contactNumber &&
    (participantDetails.contactNumber.indexOf("+") === 0 || participantDetails.contactNumber.indexOf("00") === 0)
  )
    return participantDetails.contactNumber;

  if (participantDetails?.phoneNumber && participantDetails?.countryCode)
    return participantDetails.countryCode + participantDetails.phoneNumber;

  if (customerDetails?.phoneNumber) return customerDetails.phoneNumber;

  return "";
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
