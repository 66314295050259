/* eslint-disable no-prototype-builtins */
import { Card, Grid, Typography } from "@mui/material";
import { Reservation } from "@skibro/types";
import { t } from "i18next";
import { useEffect, useState } from "react";

interface Props {
  reservations: Reservation[];
}

const BookingValueCards: React.FC<Props> = ({ reservations }) => {
  const [pendingBookings, setPendingBookings] = useState<number>();
  const [upcomingBookings, setUpcomingBookings] = useState<number>();
  const [completedBookings, setCompletedBookings] = useState<number>();
  const [acceptanceRate, setAcceptanceRate] = useState<number>();

  useEffect(() => {
    if (!reservations?.length) {
      setAcceptanceRate(0);
      setPendingBookings(0);
      setUpcomingBookings(0);
      setCompletedBookings(0);
      return;
    }

    const statusCounts = getStatusCounts();

    const acceptanceRate = Math.round(
      (((statusCounts["COMPLETED"] || 0) + (statusCounts["CONFIRMED"] || 0)) / statusCounts["ALL"]) * 100
    );
    setAcceptanceRate(acceptanceRate);
    setPendingBookings(statusCounts["AWAITING"]);
    setUpcomingBookings(statusCounts["CONFIRMED"]);
    setCompletedBookings(statusCounts["COMPLETED"]);
  }, [reservations]);

  const getStatusCounts = (): { [x: string]: number } => {
    const statusCounts = reservations
      .filter((r) => r.status !== "ARCHIVED")
      .reduce(
        (acc, reservation) => ({
          ...acc,
          [reservation.status]: acc.hasOwnProperty(reservation.status) ? (acc[reservation.status] += 1) : 1,
          ALL: acc.hasOwnProperty("ALL") ? (acc["ALL"] += 1) : 1,
        }),
        {}
      );

    return statusCounts;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} md={3}>
        <ValueBox value={pendingBookings || 0} title={t<string>("Pending bookings")} />
      </Grid>
      <Grid item xs={6} md={3}>
        <ValueBox value={upcomingBookings || 0} title={t<string>("Upcoming bookings")} />
      </Grid>
      <Grid item xs={6} md={3}>
        <ValueBox value={completedBookings || 0} title={t<string>("Completed bookings")} />
      </Grid>
      <Grid item xs={6} md={3}>
        <ValueBox value={`${acceptanceRate || 0} %`} title={t<string>("Acceptance rate")} />
      </Grid>
    </Grid>
  );
};

interface ValueBoxProps {
  title: string;
  value: number | string;
}

const ValueBox: React.FC<ValueBoxProps> = ({ title, value }) => {
  return (
    <Card sx={valueBox}>
      <Typography variant="h1">{value}</Typography>
      <Typography variant="body2" sx={boxTitle}>
        {title}
      </Typography>
    </Card>
  );
};

const valueBox = {
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: (theme) => theme.spacing(1),
  display: "flex",
  height: 90,
};

const boxTitle = {
  fontWeight: 600,
  textAlign: "center",
};

export { BookingValueCards };
