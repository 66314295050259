import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link,
  OutlinedInput,
  Rating,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { truncateStringToLastWord } from "../../lib/utils";
import ReplyIcon from "@mui/icons-material/Reply";
import SendIcon from "@mui/icons-material/Send";
import CancelIcon from "@mui/icons-material/Cancel";
import BookingCard from "../../Views/BookingsV2/BookingCardV2";
import { useReservations } from "../../Context/ReservationsContext";
import { PublicReview, Reservation } from "@skibro/types";
import { CheckCircle, DoNotDisturbOn } from "@mui/icons-material";
import FlagIcon from "@mui/icons-material/Flag";
import api from "../../lib/api";
import LoadingButton from "@mui/lab/LoadingButton";
import { useProvider } from "../../Context/ProviderContext";
import { useWidth } from "../../lib/hooks";

interface ReviewCopyDictionary {
  automaticallyTranslated: string;
  hide: string;
  readMore: string;
  showOriginal: string;
  showTranslated: string;
}

interface Props {
  currentLocale?: string;
  index: number;
  localisedCopy?: ReviewCopyDictionary;
  review: PublicReview;
  reviewDateString: string;
}

const defaultCopy = {
  automaticallyTranslated: "Automatically translated",
  hide: "Hide",
  readMore: "Read more",
  showOriginal: "Show original",
  showTranslated: "Show translated",
};

export const ReviewDisplay: React.FC<Props> = ({
  currentLocale = "en",
  index,
  localisedCopy = defaultCopy,
  review,
  reviewDateString,
}) => {
  const [showFull, setShowFull] = useState<boolean>(false);
  const [showOriginal, setShowOriginal] = useState<boolean>(false);
  const [reviewText, setReviewText] = useState<string | null>(review.text?.[currentLocale] || null);
  const [isReplying, setIsReplying] = useState<boolean>(false);
  const [readyToSend, setReadyToSend] = useState<boolean>(false);
  const [responseText, setResponseText] = useState<string>();
  const [reservationDialogToggle, setReservationDialogToggle] = useState<boolean>(false);
  const [reservation, setReservation] = useState<Reservation>();
  const [localReview, setLocalReview] = useState<PublicReview>(review);
  const [loading, setLoading] = useState<boolean>(false);
  const [isReporting, setIsReporting] = useState<boolean>(false);
  const [confirmReport, setConfirmReport] = useState<boolean>(false);
  const [reportReason, setReportReason] = useState<string>();
  const toggleShowFull = () => setShowFull(!showFull);
  const toggleShowOriginal = () => setShowOriginal(!showOriginal);

  const { reservationMutation, allReservations } = useReservations();
  const { provider } = useProvider();
  const width = useWidth();

  const handleReply = async () => {
    setLoading(true);
    const updatedReview = await api.updateProviderReview({
      ...review,
      active: true,
      response: { [provider.contact.language || "en"]: responseText },
    });
    setLoading(false);
    setLocalReview(updatedReview);
    setReadyToSend(false);
    setIsReplying(false);
  };

  useEffect(() => {
    setReservation(allReservations.find((res) => res.id === localReview.reservationId));
  }, [allReservations]);

  useEffect(() => {
    if (localReview.text) {
      localReview.text = typeof localReview.text === "string" ? JSON.parse(localReview.text) : localReview.text;
      const text = localReview.text?.[showOriginal ? localReview.originalLanguage : currentLocale];
      const splitText = text?.split("(Original)") || [];
      if (splitText.length > 1) {
        setReviewText(splitText[1]);
      } else if (text) {
        setReviewText(text);
      } else {
        setReviewText(null);
      }
      // TODO: following code is WIP to handle reviews which seamlessly switch between languages mid-flow
      // else {
      //   const textOverlap = findStringOverlap(review.text[i18n.language], review.text[review.originalLanguage]);
      //   // console.log({ textOverlap });
      //   if (textOverlap.length > 0 && textOverlap.length !== text.length) {
      //     const splitTextOnOverlap = text.split(textOverlap);
      //     // console.log({ splitTextOnOverlap });
      //     setReviewText(splitTextOnOverlap.length > 1 ? splitTextOnOverlap[1] : textOverlap);
      //   } else {
      //     // console.log({ text });
      //     setReviewText(text);
      //   }
      // }
    }
  }, [localReview.text, showOriginal]);

  const handleReport = async () => {
    const updatedReview = await api.updateProviderReview({
      ...review,
      active: false,
      reported: true,
      reportedReason: reportReason,
    });
    setLocalReview(updatedReview);
    setConfirmReport(false);
    setIsReporting(false);
  };

  return (
    <>
      <Grid
        item
        container
        xs={12}
        direction={width === "xs" ? "column" : "row"}
        alignItems={width === "xs" ? "flex-start" : "center"}
      >
        <Grid item xs={8}>
          <Link
            variant="h4"
            underline={localReview.reservationId ? "hover" : "none"}
            component={localReview.reservationId ? "button" : "p"}
            onClick={() => setReservationDialogToggle(!!localReview.reservationId)}
          >
            {localReview.reviewer}
            {localReview.reservationId ? " - " + localReview.reservationId : ""}
          </Link>
        </Grid>

        <Grid item container justifyContent={width === "xs" ? "flex-start" : "flex-end"} direction="row" xs={4}>
          <Typography mr={1} variant="subtitle2">
            {reviewDateString}
          </Typography>
          {localReview.active ? (
            <Tooltip title="Review active">
              <CheckCircle htmlColor="green" />
            </Tooltip>
          ) : (
            <Tooltip title="Review inactive">
              <DoNotDisturbOn htmlColor="red" />
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Rating value={localReview.rating} readOnly size="small" />
      </Grid>

      {reviewText && (
        <Grid item xs={12} mb={1}>
          {reviewText.length > 160 && !showFull ? (
            <>
              <Typography variant="body2" display="inline">
                {truncateStringToLastWord(reviewText, 160)}
              </Typography>
              ...&nbsp;
              <Typography
                variant="body2"
                color="primary"
                display="inline"
                onClick={toggleShowFull}
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                {localisedCopy.readMore}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="body2" display="inline">
                {reviewText}
              </Typography>
              {showFull && (
                <Button
                  onClick={toggleShowFull}
                  sx={(theme) => ({
                    display: "flex",
                    color: theme.palette.primary.main,
                    marginLeft: "auto",
                    fontSize: "0.7rem",
                  })}
                >
                  {localisedCopy.hide}
                </Button>
              )}
            </>
          )}

          {(reviewText || localReview.title) && localReview.originalLanguage !== currentLocale && (
            <Grid item xs={12}>
              <Typography variant="body2" fontStyle="italic" display="inline-block">
                {localisedCopy.automaticallyTranslated}
              </Typography>
              &nbsp;
              <Typography
                display="inline-block"
                variant="body2"
                color="secondary"
                fontStyle="italic"
                onClick={toggleShowOriginal}
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                ({!showOriginal ? localisedCopy.showOriginal : localisedCopy.showTranslated})
              </Typography>
            </Grid>
          )}
        </Grid>
      )}

      <Stack direction="row" spacing={2}>
        {!localReview.response ? (
          isReplying ? (
            <Grid item container>
              <OutlinedInput
                fullWidth
                autoFocus={isReplying}
                sx={{ mt: 1 }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setResponseText(event.target.value);
                }}
                startAdornment={
                  <IconButton onClick={() => setIsReplying(false)}>
                    <CancelIcon />
                  </IconButton>
                }
                endAdornment={
                  <IconButton onClick={() => setReadyToSend(true)}>
                    <SendIcon />
                  </IconButton>
                }
              />
            </Grid>
          ) : (
            !isReporting &&
            !localReview.reported && (
              <Button startIcon={<ReplyIcon />} variant="outlined" color="primary" onClick={() => setIsReplying(true)}>
                Reply
              </Button>
            )
          )
        ) : (
          !isReporting && (
            <Stack spacing={1} useFlexGap flexWrap="wrap">
              <Typography variant="caption">
                <strong>Your reply:</strong> {localReview.response[currentLocale]}
              </Typography>
            </Stack>
          )
        )}

        {!localReview.reported ? (
          isReporting ? (
            <Grid item container>
              <OutlinedInput
                fullWidth
                autoFocus={isReporting}
                placeholder="Reason for reporting"
                sx={{ mt: 1 }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setReportReason(event.target.value);
                }}
                startAdornment={
                  <IconButton onClick={() => setIsReporting(false)}>
                    <CancelIcon />
                  </IconButton>
                }
                endAdornment={
                  <IconButton onClick={() => setConfirmReport(true)}>
                    <SendIcon />
                  </IconButton>
                }
              />
            </Grid>
          ) : (
            !isReplying &&
            !localReview.response && (
              <Button startIcon={<FlagIcon />} variant="outlined" color="primary" onClick={() => setIsReporting(true)}>
                Report
              </Button>
            )
          )
        ) : (
          <Stack spacing={1}>
            <Alert severity="error">
              <Typography variant="caption">
                <strong>Reported review. Not shown publicly</strong>
              </Typography>
            </Alert>
          </Stack>
        )}
      </Stack>

      <Divider sx={{ my: 2 }} />
      <Dialog open={readyToSend}>
        <DialogTitle>Confirm your reply</DialogTitle>
        <DialogContent sx={{ pb: 0 }}>
          <DialogContentText variant="caption">"{responseText}"</DialogContentText>
          <DialogContentText color="secondary" my={2}>
            By clicking "Agree" you <strong>confirm</strong> that you understand this reply will be sent to your
            customer as well as being shown publicly on your Skibro profile, this will also automatically set the review
            to<strong> active</strong>
          </DialogContentText>
          <DialogContentText color="secondary">Your response will be auto-translated.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setReadyToSend(false)}>Cancel</Button>
          <LoadingButton autoFocus loading={loading} onClick={() => handleReply()}>
            Agree
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={reservationDialogToggle}
        onClose={() => setReservationDialogToggle(false)}
        maxWidth={"lg"}
        scroll={"body"}
      >
        <BookingCard reservation={reservation} reservationMutation={reservationMutation} isClickable={false} />
      </Dialog>

      <Dialog open={confirmReport}>
        <DialogTitle>Are you sure you want to report</DialogTitle>
        <DialogContent sx={{ pb: 0 }}>
          <DialogContentText color="secondary" my={2}>
            By clicking "Agree" you are reporting this review as unfair and not reflective of your services. This will
            be moderated by one of our team. Please note that reporting a review based on its negative feedback is not
            enough. Make sure you give a reason to why this review is unfair
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmReport(false)}>Cancel</Button>
          <Button autoFocus onClick={() => handleReport()}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
