import { BaseProduct, BaseProductVariant, BaseProvider, File as IFile } from "@skibro/types";
import React, { createContext, useContext, useEffect, useState } from "react";

import { useProvider } from "../Context/ProviderContext";
import API from "../lib/api";
import { deepCopy } from "../lib/helpers";
import { appStorage } from "../lib/storage";
import { useAdminWebSocket } from "./WebsocketContext";

interface ProductVariantDictionary {
  [productId: BaseProduct["id"]]: BaseProductVariant[];
}

interface ProductsContextInterface {
  products: { [k: BaseProvider["id"]]: BaseProduct[] };
  productVariants: ProductVariantDictionary;
  error: boolean;
  errorMsg: string;
  loading: boolean;
  loadProducts: (providerId) => void;
  loadProductById: (productId: number) => void;
  deleteProduct: (productId: number) => void;
  addProduct: (product: Partial<BaseProduct>) => any;
  updateProduct: (product) => void;
  loadProductVariants: (productId?) => void;
  addProductVariant: (productVariant) => void;
  updateProductVariant: (productVariant) => void;
  deleteProductVariant: (productVariant) => void;
  saveVariantCollection: (productId, productVariants) => void;
  fetchImagesForProduct: (productId: number, providerId: number) => void;
  duplicateProduct: (product: BaseProduct) => void;
  preUploadProductImages: (
    productId: number,
    images: Omit<IFile, "id" | "uploader">[]
  ) => Promise<Pick<BaseProduct, "id" | "images">>;
  cleanupImageEntityAfterDeleteInS3: (imageId: string) => Promise<boolean>;
  convertProductValueToCustomerValue: (from: string, to: string, value: number) => any;
  publishProduct: (product: BaseProduct) => void;
  productsPublishing: BaseProduct["id"][];
  loadInstructorProductVariants: (productId: number) => void;
  copyProductVariants: (productId: number, productVariants: BaseProductVariant[], overwrite?: boolean) => void;
}

const ProductsContext = createContext<ProductsContextInterface>(null);

const useProviderProducts = () => {
  const [products, setProducts] = useState<{ [k: BaseProvider["id"]]: BaseProduct[] }>({});
  const [productVariants, setProductVariants] = useState<ProductVariantDictionary>({});
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [productsLoading, setProductsLoading] = useState(false);
  const [variantsLoading, setVariantsLoading] = useState(false);
  const [productsPublishing, setProductsPublishing] = useState([]);
  const { providerId } = useProvider();
  const { clientData, messageType } = useAdminWebSocket();

  useEffect(() => {
    if (messageType === "instances") {
      const { productId } = clientData;
      const newProductsPublishing = productsPublishing.filter((i) => i !== parseInt(productId, 10));
      setProductsPublishing(newProductsPublishing);
      appStorage.setItem("productsPublishing", { data: newProductsPublishing, date: new Date().getTime() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData, messageType]);

  const loadProducts = async (providerId: number) => {
    try {
      setError(false);
      setProductsLoading(true);
      let productsHolder: any = deepCopy(products);
      const savedProducts: any = await appStorage.getItem("storedProducts");
      if (savedProducts) {
        productsHolder = savedProducts.data;
      }
      const providerProducts: any = await API.fetchProducts(providerId);
      productsHolder[providerId] = providerProducts.map((providerProduct) => {
        providerProduct.activities = providerProduct.activities.map((activity) => {
          activity.levels = providerProduct.levels;
          return activity;
        });
        return providerProduct;
      });
      setProducts(productsHolder);
      await appStorage.setItem("storedProducts", {
        storageDate: new Date(),
        data: productsHolder,
      });
      setProductsLoading(false);
    } catch (error) {
      await appStorage.removeItem("storedProducts");
      setError(true);
      setErrorMsg(error.message);
      setProductsLoading(false);
    }
  };

  useEffect(() => {
    if (providerId && !productsLoading) {
      loadProducts(providerId);
      appStorage.getItem<{ date: number; data: BaseProduct["id"][] }>("productsPublishing").then((p) => {
        if (p && new Date().getTime() + 60000 - p.date < 0) {
          setProductsPublishing(p.data);
        } else {
          setProductsPublishing([]);
          appStorage.setItem("productsPublishing", { data: [], date: new Date().getTime() });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerId]);

  const duplicateProduct = async (product: BaseProduct): Promise<void> => {
    try {
      setError(false);
      setProductsLoading(true);
      const duplicatedProduct = await API.duplicateProduct(product.id);

      const updatedProducts = {
        ...products,
        [providerId]: [duplicatedProduct, ...products[providerId]],
      };

      setProducts(updatedProducts);
    } catch (error) {
      setError(true);
      setErrorMsg(error.message);
    }
    setProductsLoading(false);
  };

  const loadProductVariants = async (productId?: number) => {
    try {
      setError(false);
      setVariantsLoading(true);
      const providerProductVariants = await API.fetchProductVariants(productId);
      setProductVariants({
        ...productVariants,
        [productId]: providerProductVariants,
      });
    } catch (error) {
      setError(true);
      setErrorMsg(error.message);
    }
    setVariantsLoading(false);
  };

  // 482 NOTE: loadInstructorProductVariants is a temp function until current instructor variant flow is deprecated
  const loadInstructorProductVariants = async (productId) => {
    try {
      setError(false);
      setVariantsLoading(true);
      const providerProductVariants = await API.fetchInstructorProductVariants(productId);
      setProductVariants({
        ...productVariants,
        [productId]: providerProductVariants,
      });
    } catch (error) {
      setError(true);
      setErrorMsg(error.message);
    }
    setVariantsLoading(false);
  };

  const addProductVariant = async (productVariant) => {
    try {
      setError(false);
      setVariantsLoading(true);
      const newProductVariant = await API.addProductVariant(productVariant);
      const updatedVariants = { ...productVariants };
      const variants = [...updatedVariants[newProductVariant.productId], newProductVariant];
      updatedVariants[newProductVariant.productId] = variants;
      setProductVariants(updatedVariants);
    } catch (error) {
      setError(true);
      setErrorMsg(error.message);
    }
    setVariantsLoading(false);
  };

  const updateProductVariant = async (productVariant) => {
    try {
      setError(false);
      setVariantsLoading(true);
      const productId = productVariant.productId;
      const updatedVariant = await API.updateProductVariant(productVariant);

      const updatedVariants = {
        ...productVariants,
        [productId]: productVariants[productId].map((pV) => (pV.id === updatedVariant.id ? updatedVariant : pV)),
      };
      setProductVariants(updatedVariants);
    } catch (error) {
      setError(true);
      setErrorMsg(error.message);
    }
    setVariantsLoading(false);
  };

  const deleteProductVariant = async (productVariant) => {
    try {
      setVariantsLoading(true);
      setError(false);
      const productId = productVariant.productId;
      const updatedVariants = { ...productVariants };
      await API.deleteProductVariant(productVariant);
      const variants = updatedVariants[productId].filter((pv) => pv.id !== productVariant.id);
      updatedVariants[productId] = variants;
      setProductVariants(updatedVariants);
    } catch (error) {
      setError(true);
      setErrorMsg(error.message);
    }
    setVariantsLoading(false);
  };

  const saveVariantCollection = async (productId: number, variants) => {
    try {
      setVariantsLoading(true);
      setError(false);

      const newProductVariants = await API.saveVariantCollection(productId, variants);
      const updatedProductVariants = { ...productVariants };
      updatedProductVariants[productId] = newProductVariants;

      setProductVariants(updatedProductVariants);
    } catch (error) {
      setError(true);
      setErrorMsg(error.message);
    }
    setVariantsLoading(false);
  };

  const loadProductById = async (productId: number) => {
    try {
      setError(false);
      setProductsLoading(true);
      const product: BaseProduct = await API.fetchProductById(productId);
      const allProducts = deepCopy(products);
      const id = product["providerId"];
      if (!allProducts[id]) allProducts[id] = [];
      const index = allProducts[id]?.findIndex((i) => i.id === product.id);
      if (index >= 0) {
        allProducts[id][index] = Object.assign({}, allProducts[id][index], product);
      } else {
        allProducts[id].push(product);
      }
      setProducts(Object.assign(allProducts));
      await appStorage.setItem("storedProducts", {
        storageDate: new Date(),
        data: allProducts,
      });
    } catch (error) {
      console.error(error);
      await appStorage.removeItem("storedProducts");
      setError(true);
      setErrorMsg(error.message);
    }
    setProductsLoading(false);
  };

  const addProduct = async (product: BaseProduct): Promise<BaseProduct> => {
    try {
      setError(false);
      setProductsLoading(true);
      const newProduct = await API.addProduct(product);

      const updatedProducts = {
        ...products,
        [providerId]: [newProduct, ...products[providerId]],
      };

      setProducts(updatedProducts);

      return newProduct;
    } catch (error) {
      setError(true);
      setErrorMsg(error.message);
    }
    setProductsLoading(false);
  };

  const updateProduct = async (product) => {
    try {
      setError(false);
      setProductsLoading(true);
      const savedProduct = await API.updateProduct(product);
      const allProducts = deepCopy(products);
      const id = product.providerId;

      const index = allProducts[id].findIndex((i) => i.id === savedProduct.id);
      if (index >= 0) {
        allProducts[id][index] = Object.assign({}, allProducts[id][index], savedProduct);
      } else {
        allProducts[id].push(savedProduct);
      }
      setProducts(Object.assign(allProducts));
      await appStorage.setItem("storedProducts", {
        storageDate: new Date(),
        data: allProducts,
      });
    } catch (error) {
      await appStorage.removeItem("storedProducts");
      setError(true);
      setErrorMsg(error.message);
    }
    setProductsLoading(false);
  };

  const copyProductVariants = async (productId: number, productVariants: BaseProductVariant[], overwrite?: boolean) => {
    try {
      setError(false);
      setProductsLoading(true);

      await API.copyProductVariants(productId, productVariants, overwrite);
    } catch (e) {
      setError(true);
      setProductsLoading(false);
      setErrorMsg(e.message);
    }
    setProductsLoading(false);
  };

  const deleteProduct = async (productId) => {
    try {
      setProductsLoading(true);
      setError(false);
      const allProducts = deepCopy(products);
      await API.deleteProduct(productId);
      allProducts[providerId] = allProducts[providerId].filter((i) => i.id !== productId);
      setProducts(allProducts);
    } catch (error) {
      setError(true);
      setErrorMsg(error.message);
    }
    setProductsLoading(false);
  };

  const publishProduct = async (product): Promise<void> => {
    setProductsPublishing([...productsPublishing, product.id]);
    await API.republishProduct(product);
    appStorage.setItem("productsPublishing", { data: [...productsPublishing, product.id], date: new Date().getTime() });
  };

  const fetchImagesForProduct = async (productId: number, providerId: number) => {
    try {
      setError(false);
      setProductsLoading(true);
      const newProductWithImages = await API.fetchImagesForProduct(productId);
      const allProducts = deepCopy(products);
      if (!allProducts[providerId]) allProducts[providerId] = [];
      const index = allProducts[providerId]?.findIndex((i) => i.id === newProductWithImages.id);
      if (index >= 0) {
        allProducts[providerId][index] = Object.assign({}, allProducts[providerId][index], {
          images: newProductWithImages.images,
        });
      }
      setProducts(Object.assign(allProducts));
      await appStorage.setItem("storedProducts", {
        storageDate: new Date(),
        data: allProducts,
      });
      setProductsLoading(false);
    } catch (error) {
      console.error(error);
      await appStorage.removeItem("storedProducts");
      setError(true);
      setErrorMsg(error.message);
    }
  };

  const preUploadProductImages = async (
    productId: number,
    images: Omit<IFile, "id" | "uploader">[]
  ): Promise<Pick<BaseProduct, "id" | "images">> => {
    try {
      setError(false);
      const newProductWithImages = await API.preUploadProductImages(productId, images);
      return newProductWithImages;
    } catch (error) {
      setError(true);
      setErrorMsg(error.message);
    }
  };

  const cleanupImageEntityAfterDeleteInS3 = async (imageId: string): Promise<boolean> => {
    try {
      setError(false);
      const imageDeleted = await API.cleanupImageEntityAfterDeleteInS3(imageId);
      return imageDeleted;
    } catch (error) {
      setError(true);
      setErrorMsg(error.message);
    }
  };

  const convertProductValueToCustomerValue = async (from: string, to: string, value: number): Promise<number> => {
    try {
      const convertedValue = await API.convertCurrencies(from, to, value);
      return convertedValue;
    } catch (error) {
      setError(true);
      setErrorMsg(error.message);
    }
  };

  return {
    products,
    productVariants,
    error,
    errorMsg,
    loading: variantsLoading || productsLoading,
    loadProducts,
    loadProductById,
    addProduct,
    updateProduct,
    deleteProduct,
    loadProductVariants,
    addProductVariant,
    updateProductVariant,
    deleteProductVariant,
    saveVariantCollection,
    fetchImagesForProduct,
    preUploadProductImages,
    cleanupImageEntityAfterDeleteInS3,
    convertProductValueToCustomerValue,
    publishProduct,
    duplicateProduct,
    productsPublishing,
    loadInstructorProductVariants,
    copyProductVariants,
  };
};

// hook
export const useProducts = () => {
  return useContext(ProductsContext);
};

export const ProductsProvider = ({ children }) => {
  const products = useProviderProducts();
  return <ProductsContext.Provider value={products}>{children}</ProductsContext.Provider>;
};
