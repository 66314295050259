import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "../resources/translations/en.json";
import fr from "../resources/translations/fr.json";

const resources = {
  en,
  fr,
};

// eslint-disable-next-line import/no-named-as-default-member
i18n
  // .use(Backend)
  .use(initReactI18next)

  .init({
    // MULTILANG remove line bellow to go live with multilang
    lng: "en",
    resources,
    supportedLngs: ["en", "fr"],
    fallbackLng: ["en", "fr"],
    react: {
      useSuspense: true,
    },
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag", "path", "subdomain"],

      // keys or params to lookup language from
      lookupQuerystring: "lng",
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ["localStorage", "cookie"],
      excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)
      // optional expire and domain for set cookie
      cookieMinutes: 10,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
