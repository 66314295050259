import { Availability } from "@skibro/types";
import React, { createContext, useContext, useState } from "react";

import API from "../lib/api";

interface ProductAvailabilities {
  [productId: string]: Availability[];
}

interface AvailabilityContextType {
  availabilities: ProductAvailabilities;
  loadProductAvailabilities: (productId: number) => Promise<void>;
  loadProviderAvailabilities: (providerId: number) => Promise<void>;
  deleteAvailability: (availability: Availability, providerId: number) => Promise<void>;
  updateAvailability: (availability: Availability, providerId: number) => Promise<void>;
  addAvailability: (availability: Availability, providerId: number) => Promise<void>;
  loading: boolean;
  error: boolean;
  errorMsg: string;
  clearError: () => void;
}

const AvailabilityContext = createContext<AvailabilityContextType>({
  availabilities: {} as ProductAvailabilities,
  loadProductAvailabilities: async () => undefined,
  loadProviderAvailabilities: async () => undefined,
  deleteAvailability: async () => undefined,
  updateAvailability: async () => undefined,
  addAvailability: async () => undefined,
  loading: true,
  error: false,
  errorMsg: "",
  clearError: () => undefined,
});

const useProviderAvailability = () => {
  const [availabilities, setAvailabilities] = useState<ProductAvailabilities>({});

  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const loadProductAvailabilities = async (productId: number) => {
    try {
      const availabilitiesFound = await API.fetchAvailabilities(productId);
      setAvailabilities({
        ...availabilities,
        [productId]: availabilitiesFound,
      });
      setLoading(false);
    } catch (error) {
      console.error("loadProductAvailabilities ", error);
      setError(true);
      setErrorMsg(error.message);
    }
  };

  const loadProviderAvailabilities = async (providerId: number) => {
    try {
      const availabilitiesFound = await API.fetchProviderAvailabilities(providerId);
      const availabilitiesHolder: ProductAvailabilities = {};
      availabilitiesFound.forEach((availability) => {
        if (availabilitiesHolder[availability.productId]) {
          availabilitiesHolder[availability.productId].push(availability);
        } else {
          availabilitiesHolder[availability.productId] = [availability];
        }
      });

      setAvailabilities(availabilitiesHolder);
      setLoading(false);
    } catch (error) {
      console.error("loadProviderAvailabilities ", error);
      setError(true);
      setErrorMsg(error.message);
    }
  };

  const addAvailability = async (availability: Availability, productId: number) => {
    setLoading(true);
    try {
      setError(false);
      setErrorMsg("");
      if (!availability.startDate || !availability.endDate) throw Error("Please select correct dates!");
      await API.addAvailability(availability, productId);
      const newAvailabilities = await API.fetchAvailabilities(productId);
      setAvailabilities({
        ...availabilities,
        [productId]: newAvailabilities,
      });
    } catch (error) {
      console.error("addAvailability error: ", error);
      setError(true);
      setErrorMsg(error.message);
    }
    setLoading(false);
  };

  const deleteAvailability = async (availability, productId) => {
    setLoading(true);
    try {
      setError(false);
      setErrorMsg("");
      await API.deleteAvailability(availability, productId);
      const updatedAvailabilities = [...availabilities[productId]];
      const index = updatedAvailabilities.findIndex((i) => i.id === availability.id);
      updatedAvailabilities.splice(index, 1);
      setAvailabilities({ ...availabilities, [productId]: updatedAvailabilities });
    } catch (error) {
      console.error("updateAvailabilities error: ", error);
      setError(true);
      setErrorMsg(error.message);
    }
    setLoading(false);
  };

  const updateAvailability = async (availability, productId) => {
    setLoading(true);
    try {
      setError(false);
      setErrorMsg("");
      await API.updateAvailability(availability, productId);
      await loadProductAvailabilities(productId);
    } catch (error) {
      console.error("updateAvailability error: ", error);
      setError(true);
      setErrorMsg(error.message);
    }
    setLoading(false);
  };

  const clearError = () => {
    setError(false);
  };

  return {
    availabilities,
    error,
    errorMsg,
    loading,
    loadProductAvailabilities,
    loadProviderAvailabilities,
    deleteAvailability,
    updateAvailability,
    addAvailability,
    clearError,
  };
};

export type { ProductAvailabilities };

// hook
export const useAvailability = () => {
  return useContext(AvailabilityContext);
};

// provider
export const AvailabilityProvider = ({ children }) => {
  const avail = useProviderAvailability();
  return <AvailabilityContext.Provider value={avail}>{children}</AvailabilityContext.Provider>;
};
