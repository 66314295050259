import chroma from "chroma-js";
import React, { useEffect, useState } from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

interface LineChartData {
  name: string;
  [x: string]: string | number;
}

interface Props {
  data: LineChartData[];
}

const SimpleLineChart: React.FC<Props> = ({ data }) => {
  const [dataKeys, setDataKeys] = useState<string[]>();
  const colors = chroma.scale("Spectral").colors(data?.length);

  useEffect(() => {
    if (!data) return;

    const keys = [...new Set(data.flatMap((item) => Object.keys(item).filter((key) => key !== "name")))];
    setDataKeys(keys);
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis scale="linear" />
        <Tooltip />
        <Legend />
        {dataKeys?.map((key, index) => (
          <Line key={key} type="monotone" dataKey={key} stroke={colors[index]} />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export type { LineChartData };
export { SimpleLineChart };
