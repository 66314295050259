import { Location } from "history";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ConfirmationDialog } from "./ConfirmationDialog";

import { usePrompt } from "../hooks/usePrompt";

interface Props {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
}

const RouteChangeGuard = ({ when, navigate, shouldBlockNavigation }: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const { t } = useTranslation();
  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation && navigate) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation]);

  usePrompt(handleBlockedNavigation, when);

  // Your own alert/dialog/modal component
  return (
    <ConfirmationDialog
      isOpen={modalVisible}
      dialogTitleText={t("Close without saving?")}
      questionText={t("You’ll lose your changes if you don’t save first.")}
      cancelText={t("Keep editing")}
      confirmText={t("Leave without saving")}
      onClose={closeModal}
      confirmAction={handleConfirmNavigationClick}
    />
  );
};
export default RouteChangeGuard;
