import { Grid, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { publicUrl } from "../../config/settings";

const UserHint = () => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="center">
      <Grid
        item
        xs={10}
        sx={{
          textAlign: "center",
        }}
      >
        <Typography variant="body1">{t("Schools & Instructors, log in/register here.")}</Typography>
        <Typography variant="body1">
          {t("Customers wanting to book ski lessons and winter activities, please go to: ")}
          <Link href={publicUrl}>www.skibro.com</Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default UserHint;
