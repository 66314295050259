import { Box, Grid, Link, Typography } from "@mui/material";

import UserHint from "./UserHint";
import Greeting from "./Greeting";

import SkibroLogo from "../../Components/SkibroLogo";

export const authComponents = {
  Header() {
    return (
      <Box
        sx={{
          display: "block",
          position: "relative",
          width: "400px",
          margin: "36px auto",
          textAlign: "center",
        }}
      >
        <SkibroLogo />
        <UserHint />
      </Box>
    );
  },
  Footer() {
    return (
      <Box
        sx={{
          display: "block",
          position: "relative",
          width: "400px",
          margin: "36px auto",
        }}
      >
        <Greeting />
      </Box>
    );
  },
};
