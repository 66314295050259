import { Checkbox, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Option {
  name: string;
  id: number;
}

interface OptionWithUnknownProps extends Option {
  [k: string]: unknown;
}

interface Props {
  options: (Option | OptionWithUnknownProps)[];
  onUpdate: (newSelected, name?) => void;
  label?: string;
  classes?: any;
  selected?: any[];
  name?: string;
  disabledOptions?: any[];
  renderText: string;
  helperText?: string;
}

export const CheckboxComboBoxBasic: React.FC<Props> = ({
  options,
  onUpdate,
  label,
  name,
  disabledOptions,
  renderText,
  selected = [],
  helperText,
}) => {
  const { t } = useTranslation();
  const [localSelected, setLocalSelected] = useState<number[]>(selected?.map((s) => s.id) || []);

  useEffect(() => {
    if (selected.length) {
      const selectedIds = selected.map((s) => s.id).filter((s) => !disabledOptions?.some((o) => o.id === s.id));
      setLocalSelected(selectedIds);
    }
  }, [selected, disabledOptions]);

  return (
    <>
      {options?.length > 0 && (
        <TextField
          variant="outlined"
          fullWidth
          select
          SelectProps={{
            multiple: true,
            renderValue: (values) => <div>{t(renderText, { count: (values as number[]).length })}</div>,
          }}
          name={name}
          onChange={(event) => {
            const selectedIds = event.target.value as any;
            setLocalSelected(selectedIds);
            onUpdate(
              options.filter((o) => selectedIds.includes(o.id)),
              name
            );
          }}
          label={label}
          placeholder={` `}
          value={localSelected}
          helperText={helperText}
        >
          {options.map((option) => {
            const disabled = !!disabledOptions?.find((disabledOption) => option.id === disabledOption.id);
            return (
              <MenuItem value={option.id || null} key={`option_${option.id}`} disabled={disabled}>
                <Checkbox checked={!disabled && localSelected.includes(option.id)} value={option.id} />
                {option.name}
              </MenuItem>
            );
          })}
        </TextField>
      )}
    </>
  );
};
