import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { Reservation } from "@skibro/types";
import { addDays, areIntervalsOverlapping, format, previousSunday } from "date-fns";
import { t } from "i18next";
import { useEffect, useState } from "react";

import { LineChartData, SimpleLineChart } from "../../Components/Charts/LineChart";

interface Props {
  reservations: Reservation[];
}

export const UpcomingActivity: React.FC<Props> = ({ reservations }) => {
  const [reservationForcastGraphData, setReservationForcastGraphData] = useState<LineChartData[]>();

  useEffect(() => {
    if (!reservations?.length) return;

    const graphObjects = createWeeklyReservationCounts();

    setReservationForcastGraphData(graphObjects);
  }, [reservations]);

  const createWeeklyReservationCounts = (): LineChartData[] => {
    const weekRanges = getWeekRanges(8);
    const weeklyReservationResortCounts = weekRanges.map((range) => getResortReservationCounts(range));
    const allResortNames = [
      ...new Set(
        weeklyReservationResortCounts.flatMap((countObj) => Object.keys(countObj).filter((key) => key !== "name"))
      ),
    ];
    const defaultResortCountObject = allResortNames.reduce(
      (acc: { [resortName: string]: number }, resortName: string) => ({ ...acc, [resortName]: 0 }),
      {}
    );

    return weeklyReservationResortCounts.map((item) => ({ ...defaultResortCountObject, ...item }));
  };

  const getResortReservationCounts = (range: { startDate: Date; endDate: Date }): LineChartData => {
    const overlappingReservations = reservations.filter(
      (r) =>
        areIntervalsOverlapping(
          { start: range.startDate, end: range.endDate },
          { start: new Date(r.startDate), end: new Date(r.endDate) }
        ) && r.status === "CONFIRMED"
    );

    const resortReservationCounts = overlappingReservations.reduce(
      (acc, r) => ({
        ...acc,
        // eslint-disable-next-line no-prototype-builtins
        [r?.resort?.name]: acc.hasOwnProperty(r?.resort?.name) ? (acc[r?.resort?.name] += 1) : 1,
      }),
      {}
    );

    return {
      name: `${format(range.startDate, "MMM dd")} - ${format(range.endDate, "MMM dd")}`,
      ...resortReservationCounts,
    };
  };

  const getWeekRanges = (weeks: number): { startDate: Date; endDate: Date }[] => {
    const startDate = previousSunday(new Date());
    const dates = [];

    for (let i = 0; i < weeks; i++) {
      if (!dates.length) {
        dates.push({ startDate, endDate: addDays(startDate, 6) });
      }
      const sundayStartDate = addDays(dates[dates.length - 1].endDate, 1);
      dates.push({ startDate: sundayStartDate, endDate: addDays(sundayStartDate, 6) });
    }

    return dates;
  };

  return (
    <Card sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader
        title={<Typography variant="h4">{t<string>("Upcoming activity")}</Typography>}
        subheader={t<string>("Bookings taking place per week")}
      />

      <CardContent sx={{ height: "250px" }}>
        {reservationForcastGraphData && <SimpleLineChart data={reservationForcastGraphData} />}
      </CardContent>
    </Card>
  );
};
