import React, { createContext, useContext, useState } from "react";
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";
import settings from "../config/settings";

import { useUser } from "./UserContext";

interface WebsocketContextInterface {
  message: string;
  clientData: { [xany: string]: any };
  messageType: null | "instances";
}

const WebsocketContext = createContext<WebsocketContextInterface>({
  message: "",
  clientData: {},
  messageType: null,
});

const useWebSocketProvider = () => {
  const { cognitoUser } = useUser();
  const [message, setMessage] = React.useState("");
  const [messageType, setMessageType] = React.useState<null | "instances">(null);
  const [clientData, setClientData] = useState<{ [xany: string]: any }>({});
  useWebSocket(settings.wsUrl, {
    queryParams: {
      userId: cognitoUser.username,
    },
    onOpen: () => {
      setMessage("WebSocket connection established.");
    },
    onMessage: (event) => {
      try {
        console.log("WebSocket message received:", JSON.parse(event.data)?.message);
        const messageData = JSON.parse(event.data);
        setMessage(messageData.message);
        setClientData(messageData.clientData);
        setMessageType(messageData.type);
      } catch (error) {
        console.log(error);
        setMessage("Error communicating with server. Please refresh the page.");
      }
    },
    onError: (event) => {
      console.log("WebSocket error message received:", event);
      setMessage(JSON.stringify(event));
    },
  });

  return {
    message,
    clientData,
    messageType,
  };
};

// hook
export const useAdminWebSocket = () => {
  return useContext(WebsocketContext);
};

export const WebSocketProvider = ({ children }) => {
  const products = useWebSocketProvider();
  return <WebsocketContext.Provider value={products}>{children}</WebsocketContext.Provider>;
};
