import { Grid, Hidden, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { OfferedDayTimeCompared } from "./OfferedDayTimeCompared";

interface Props {
  reservation: any;
  isAmendmentOffered: any;
}

const DisplayDaysTimes: React.FC<Props> = ({ reservation, isAmendmentOffered }) => {
  const { t } = useTranslation();
  const [daysTimesAmended, setDaysTimesAmended] = useState<any>({});

  useEffect(() => {
    if (isAmendmentOffered && isAmendmentOffered(`daysTimes`)) {
      const holder = {};
      reservation.amendmentsOffered[0].daysTimes.from.forEach((dt) => {
        holder[dt.date] = {
          from: {
            date: dt.date,
            duration: dt.duration,
            start: dt.start,
          },
        };
      });
      reservation.amendmentsOffered[0].daysTimes.to.forEach((dt) => {
        holder[dt.date] = {
          ...holder[dt.date],
          to: {
            date: dt.date,
            duration: dt.duration,
            start: dt.start,
          },
        };
      });
      setDaysTimesAmended(holder);
    }
  }, [isAmendmentOffered, reservation.amendmentsOffered]);

  return (
    <Grid direction="row" alignContent="flex-start" container>
      <Grid item xs={12} md={2}>
        <Typography variant="h5" color="textSecondary" component="p" sx={styles.moreDetailName}>
          {t("Lesson Dates")}
        </Typography>
      </Grid>
      {isAmendmentOffered(`daysTimes`) ? (
        <>
          <Grid item xs={12} md={10}>
            <Grid item container xs={12} md={10} sx={styles.bookingDetailsSingle}>
              <Grid item xs={6} sm={4}>
                <Typography variant="h6" color="textSecondary" component="p" sx={styles.moreDetailNameLeft}>
                  {t("Date")}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="h6" color="textSecondary" component="p" sx={styles.moreDetailNameLeft}>
                  {t("From")}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography variant="h6" color="textSecondary" component="p" sx={styles.moreDetailNameLeft}>
                  {t("To")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={10}>
            {Object.keys(daysTimesAmended).map((dayTime, idx) => (
              <OfferedDayTimeCompared
                key={`day-time-compared-${idx}`}
                dayTime={dayTime}
                change={daysTimesAmended[dayTime]}
              />
            ))}
          </Grid>
        </>
      ) : (
        <>
          <Hidden only={"xs"}>
            <Grid item xs={12} md={10}>
              <Grid item container xs={12} md={10} sx={styles.bookingDetailsSingle}>
                <Grid item xs={6} sm={4}>
                  <Typography variant="h6" color="textSecondary" component="p" sx={styles.moreDetailNameLeft}>
                    {t("Date")}
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={3}>
                  <Typography variant="h6" color="textSecondary" component="p" sx={styles.moreDetailNameLeft}>
                    {t("From")}
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={3}>
                  <Typography variant="h6" color="textSecondary" component="p" sx={styles.moreDetailNameLeft}>
                    {t("To")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>

          {reservation.daysTimes.map((dayTime, idx) => (
            <Grid container item xs={12} md={10} key={`dayTime-${idx}`} sx={styles.bookingDetailsValues}>
              <Grid item xs={12} md={2}></Grid>
              <Grid container item xs={12} md={10}>
                <Hidden smUp>
                  <Grid item xs={4}>
                    <Typography variant="h6" color="textSecondary" component="p" sx={styles.moreDetailNameLeft}>
                      {t("Date")}
                    </Typography>
                  </Grid>
                </Hidden>
                <Grid item xs={8} sm={4}>
                  <Typography variant="body1" component="p" sx={styles.moreDetailValue}>
                    {format(parseISO(dayTime.date), "dd/MM/yyyy")}
                  </Typography>
                </Grid>
                <Hidden smUp>
                  <Grid item xs={4}>
                    <Typography variant="h6" color="textSecondary" component="p" sx={styles.moreDetailNameLeft}>
                      {t("From")}
                    </Typography>
                  </Grid>
                </Hidden>
                <Grid item xs={8} sm={3}>
                  <Typography variant="body1" component="p" sx={styles.moreDetailValue}>
                    {`${Math.floor(dayTime.start / 60)}:${
                      dayTime.start % 60 < 10 ? "0" + (dayTime.start % 60) : dayTime.start % 60
                    }`}
                  </Typography>
                </Grid>
                <Hidden smUp>
                  <Grid item xs={4} sm={2}>
                    <Typography variant="h6" color="textSecondary" component="p" sx={styles.moreDetailNameLeft}>
                      {t("To")}
                    </Typography>
                  </Grid>
                </Hidden>
                <Grid item xs={8} sm={3}>
                  <Typography variant="body1" component="p" sx={styles.moreDetailValue}>
                    {`${Math.floor((dayTime.start + dayTime.duration) / 60)}:${
                      (dayTime.start + dayTime.duration) % 60 < 10
                        ? "0" + ((dayTime.start + dayTime.duration) % 60)
                        : (dayTime.start + dayTime.duration) % 60
                    }`}
                  </Typography>
                </Grid>
                {idx < reservation.daysTimes.length && (
                  <Grid item xs={12}>
                    <hr />
                  </Grid>
                )}
              </Grid>
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
};

const styles: { [x: string]: SxProps } = {
  bookingDetailsSingle: {
    pl: {
      xs: 0,
      md: 2.5,
    },
  },
  bookingDetailsValues: {
    ml: {
      xs: 0,
      md: 4,
    },
    pl: {
      xs: 0,
      md: 2.5,
    },
  },
  moreDetailName: {
    textDecoration: {
      xs: "underline",
      md: "none",
    },
    my: 0.5,
    mx: 0,
    textAlign: { xs: "left", lg: "right" },
  },
  moreDetailNameLeft: {
    textAlign: "left",
    m: 0.5,
  },
  moreDetailValue: {
    textAlign: "left",
    m: 0.5,
  },
};

export { DisplayDaysTimes };
