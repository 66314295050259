import { createContext, ReactNode, useContext, useState } from "react";

const SideBarContext = createContext({
  isOpen: false,
  toggleSideBar: () => null,
});

export const useSideBar = (): {
  isOpen: boolean;
  toggleSideBar: () => void;
} => {
  return useContext(SideBarContext);
};

export const SideBarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleSideBar = () => setIsOpen(!isOpen);

  return <SideBarContext.Provider value={{ isOpen, toggleSideBar }}>{children}</SideBarContext.Provider>;
};
