import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Dialog,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { Reservation } from "@skibro/types";
import { format, nextTuesday } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseMutationResult } from "react-query";

import { BookingCardButtons } from "./BookingCardButtons";
import { BookingDetail } from "./BookingDetailV2";
import { CreateAmendment } from "./CreateAmendment";
import { DisplayDetails } from "./DisplayDetailsV2";

import { CircularLoader } from "../../Components/CircularLoader";
import { statuses } from "../../config/constants";
import { useProvider } from "../../Context/ProviderContext";
import { reservationIndicatorColors } from "../../lib/theme";

interface BookingCardProps {
  reservation: Reservation;
  reservationMutation: UseMutationResult;
  isClickable: boolean;
  isExpanded?: boolean;
}

const BookingCard: React.FC<BookingCardProps> = ({ reservation, reservationMutation, isExpanded, isClickable }) => {
  const {
    id,
    resort,
    startDate,
    endDate,
    status,
    booking,
    createdAt,
    productValue,
    productCurrency,
    providerCommissionRate,
    participants,
    product,
    activity,
  } = reservation;

  const { t } = useTranslation();
  const { provider, providerId } = useProvider();

  const [expanded, setExpanded] = useState<boolean>(isExpanded || false);
  const [amending, setAmending] = useState<boolean>(false);
  const [customer, setCustomer] = useState<any>(null);
  const [showRTBButtons, setShowRTBButtons] = useState<boolean>(false);

  const numColumns = reservation.source === "GOOGLE" ? 3 : 2;

  useEffect(() => {
    if (booking) {
      setCustomer(
        booking?.partyDetails?.fullName ?? `${booking?.partyDetails?.firstName} ${booking?.partyDetails?.lastName}`
      );
    }
  }, [booking]);

  useEffect(() => {
    if (statuses[reservation?.status]?.showRTBButtons) {
      setShowRTBButtons(true);
    } else {
      setShowRTBButtons(false);
    }
  }, [reservation]);

  const handleExpandClick = (): void => {
    setExpanded(!expanded);
  };

  const instructorFee = `${((productValue - productValue * Number(`${providerCommissionRate}`)) / 100).toFixed(
    2
  )} ${productCurrency}`;

  return (
    <Card sx={expanded ? styles.cardSelected : null}>
      <CardHeader
        title={
          <Grid container spacing={1} alignItems="center" sx={{ flexWrap: "nowrap" }}>
            <Grid item>
              <Box sx={{ ...styles.cardHeader, ...indicator, ...statusIndicators[reservation.status] }} />
            </Grid>

            <Grid item>
              <Typography variant="h5">{`${t(`${statuses[status]?.displayAs}`)} - ${
                reservation.source === "GOOGLE" ? "Google (External Reservation)" : customer
              }`}</Typography>
            </Grid>
          </Grid>
        }
      ></CardHeader>

      <CardContent sx={styles.cardContent}>
        <Grid container item xs={12} lg={11} spacing={3} p={1}>
          <Grid item container xs={6} md={12} sx={{ flexDirection: { xs: "column", md: "row" } }} spacing={3}>
            <Grid item md={numColumns}>
              {isClickable ? (
                <Link href={`/${providerId}/bookings/${id}`}>
                  <BookingDetail
                    label={t("account.bookingId")}
                    value={`${reservation.source === "GOOGLE" ? "Google" : booking?.uniqueBookingReference} - ${id}`}
                  />
                </Link>
              ) : (
                <BookingDetail
                  label={t("account.bookingId")}
                  value={`${reservation.source === "GOOGLE" ? "Google" : booking?.uniqueBookingReference} - ${id}`}
                />
              )}
            </Grid>

            {reservation.source !== "GOOGLE" && (
              <Grid item md={numColumns}>
                <BookingDetail label={t("search.resort")} value={resort?.name} />
              </Grid>
            )}

            {reservation.source !== "GOOGLE" && (
              <Grid item md={numColumns}>
                <BookingDetail label={t("search.activity")} value={activity?.name} />
              </Grid>
            )}

            <Grid item md={numColumns}>
              <BookingDetail label={t("datePicker.startDate")} value={format(new Date(startDate), "dd/MM/yyyy")} />
            </Grid>

            {reservation.source !== "GOOGLE" && (
              <Grid item md={numColumns}>
                <BookingDetail
                  label={t("booking.lessonPrice")}
                  value={`${(productValue / 100).toFixed(2)} ${productCurrency}`}
                />
              </Grid>
            )}

            <Grid item md={numColumns}>
              <BookingDetail label={t("booking.bookingCreated")} value={format(new Date(createdAt), "dd/MM/yyyy")} />
            </Grid>
          </Grid>

          <Grid item container xs={6} md={12} sx={{ flexDirection: { xs: "column", md: "row" } }} spacing={3}>
            <Grid item md={numColumns}>
              <BookingDetail
                label={t("booking.customerName")}
                value={reservation.source === "GOOGLE" ? "External" : customer}
              />
            </Grid>

            {reservation.source !== "GOOGLE" && (
              <Grid item md={numColumns}>
                <BookingDetail label={t("privateBookingForm.steps.partySize")} value={`${participants?.length}`} />
              </Grid>
            )}

            {reservation.source !== "GOOGLE" && (
              <Grid item md={numColumns}>
                <BookingDetail
                  label={t("common.lessonType")}
                  value={product?.type ? `${product.type.charAt(0).toUpperCase()}${product.type.slice(1)}` : ""}
                />
              </Grid>
            )}

            <Grid item md={numColumns}>
              <BookingDetail label={t("datePicker.endDate")} value={format(new Date(endDate), "dd/MM/yyyy")} />
            </Grid>

            {reservation.source !== "GOOGLE" && (
              <Grid item md={numColumns}>
                <BookingDetail label={t("booking.yourEarnings")} value={instructorFee} />
              </Grid>
            )}

            {reservation.source !== "GOOGLE" && (
              <Grid item md={numColumns}>
                <BookingDetail
                  label={t("booking.paymentDate")}
                  value={format(nextTuesday(new Date(endDate)), "dd/MM/yyyy")}
                />
              </Grid>
            )}
          </Grid>

          <Grid container item direction="column" xs={12} spacing={3}>
            {reservation.declineSummary?.reason && (
              <BookingDetail label={t("booking.Decline reason")} value={`${reservation.declineSummary?.reason}`} />
            )}

            {reservation.declineSummary?.details && (
              <BookingDetail label={t("booking.Decline details")} value={`${reservation.declineSummary?.details}`} />
            )}
          </Grid>

          {reservation.booking?.partyDetails?.accommodation && (
            <Grid item xs={12}>
              <BookingDetail label={t("Accommodation")} value={`${reservation.booking?.partyDetails.accommodation}`} />
            </Grid>
          )}
        </Grid>
      </CardContent>

      <CardActions disableSpacing>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={9}>
            <BookingCardButtons
              reservation={reservation}
              reservationMutation={reservationMutation}
              handleAmendClick={() => setAmending(!amending)}
              isAmending={amending}
              showAcceptDecline={showRTBButtons && !reservationMutation.isLoading}
              chatEnabled={provider?.messengerActive && reservation.source !== "GOOGLE"}
            />
            {showRTBButtons && reservationMutation.isLoading && <CircularLoader visible={true} />}
          </Grid>

          <Grid onClick={handleExpandClick} item container xs={12} md={3} alignItems="center" justifyContent="flex-end">
            <Grid item>
              <Typography variant="body2" color="textSecondary" component="p" sx={styles.moreDetails}>
                {t("rtb.moreDetails")}
              </Typography>
            </Grid>

            <Grid item>
              <IconButton
                sx={{ ...styles.expand, transform: expanded ? "rotate(180deg)" : "none" }}
                aria-expanded={expanded}
                aria-label={t("rtb.moreDetails")}
                size="large"
              >
                <ExpandMoreIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </CardActions>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <>
            <DisplayDetails
              amendmentsOffered={reservation?.amendmentsOffered[0]}
              partyDetails={booking?.partyDetails}
              reservation={reservation}
              status={reservation.status}
              showEmail={provider?.metadata?.showCustomerEmail}
            />

            <Grid direction="row" alignContent="flex-start" container>
              <Grid item xs={12} md={numColumns}>
                <Typography variant="h5" color="textSecondary" component="p" sx={styles.moreDetailName}>
                  {t("nav.partner")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={10} sx={styles.bookingDetailsSingle}>
                <Typography variant="body1" color="textSecondary" component="p" sx={styles.moreDetailValue}>
                  {booking?.user?.userType === "partner" ? booking?.user?.name : "-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} md="auto">
              <CardActions disableSpacing>
                <Grid container justifyContent="space-between">
                  <Grid item xs={12} md={9}>
                    <BookingCardButtons
                      reservation={reservation}
                      reservationMutation={reservationMutation}
                      handleAmendClick={null}
                      isAmending={false}
                      showCancelling={true}
                      showAcceptDecline={false}
                      chatEnabled={false}
                    />
                    {showRTBButtons && reservationMutation.isLoading && <CircularLoader visible={true} />}
                  </Grid>
                </Grid>
              </CardActions>
            </Grid>
          </>
        </CardContent>
      </Collapse>

      {amending && reservation && (
        <Dialog open={amending} onClose={() => setAmending(false)}>
          <CreateAmendment reservation={reservation} closeDialog={() => setAmending(false)} />
        </Dialog>
      )}
    </Card>
  );
};

const styles: { [x: string]: SxProps } = {
  amendmentsForm: {
    mt: 2.5,
  },
  cardHeader: {
    width: "100%",
    height: (theme: Theme) => theme.spacing(5),
    backgroundColor: "#dedede",
  },
  cardSelected: {
    boxShadow: "1px 1px 5px #888888",
  },
  cardContent: {
    p: 2,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: (theme: Theme) =>
      theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
  },
  placeHolder: {
    pr: 2.5,
  },
  bookingDetailsSingle: {
    pl: 2.5,
  },
  moreDetailName: {
    textDecoration: {
      xs: "underline",
      md: "none",
    },
    textAlign: { xs: "left", lg: "right" },
    my: 0.5,
    mx: 0,
  },
  moreDetailValue: {
    textAlign: "left",
    fontSize: (theme: Theme) => theme.spacing(2),
    m: 0.5,
  },
  moreDetails: {
    textAlign: "right",
    width: "100%",
  },
};

const indicator = {
  height: 16,
  width: 16,
  borderRadius: "20px",
  border: "2px solid #c1c1c1",
};

const statusIndicators = {
  AUTHENTICATING: {
    backgroundColor: reservationIndicatorColors.authenticating,
  },

  AWAITING: {
    backgroundColor: reservationIndicatorColors.awaiting,
  },

  AMENDING: {
    backgroundColor: reservationIndicatorColors.amending,
  },

  CANCELLED: {
    backgroundColor: reservationIndicatorColors.cancelled,
  },

  DECLINED: {
    backgroundColor: reservationIndicatorColors.declined,
  },

  COMPLETED: {
    backgroundColor: reservationIndicatorColors.completed,
  },

  CONFIRMED: {
    backgroundColor: reservationIndicatorColors.confirmed,
  },
};

export default BookingCard;
