import { Card, CardContent, CardHeader, CircularProgress, Grid, Rating, Stack, Typography } from "@mui/material";
import { PublicReview } from "@skibro/types";
import { useTranslation } from "react-i18next";

import { ProviderReviewDetails } from "../../Context/ProviderContext";
import { localeFormat } from "../../lib/date-fns";
import { ReviewDisplay } from "../../Components/FrontendUtils/ReviewDisplay";

interface Props {
  isWidget?: boolean;
  review: PublicReview;
  reviewsSummary?: ProviderReviewDetails;
}

export const ReviewSummary: React.FC<Props> = ({ isWidget = false, review, reviewsSummary }) => {
  const { i18n, t } = useTranslation();

  const format = localeFormat(i18n.language);

  const localisedReviewUICopy = {
    automaticallyTranslated: t("reviews.automaticallyTranslated"),
    hide: t("reviews.hide"),
    readMore: t("reviews.readMore"),
    showOriginal: t("reviews.showOriginal"),
    showTranslated: t("reviews.showTranslated"),
  };

  return (
    <Card>
      {isWidget && <CardHeader title={<Typography variant="h4">{t("reviews.reviews", { count: 2 })}</Typography>} />}

      <CardContent sx={{ width: 1, minHeight: 240 }}>
        <Grid item xs={12}>
          {(!reviewsSummary || !reviewsSummary.isSummary) && (
            <Stack mt={6} mb={10} alignItems="center">
              <CircularProgress />
            </Stack>
          )}
          {reviewsSummary && reviewsSummary.isSummary && (
            <>
              <Typography variant="h5" fontWeight={"bold"} mb={0.5}>
                {t("reviews.overallRating")}
              </Typography>
              <Grid container item xs={12}>
                <Grid item xs={isWidget ? 6 : 12} display="flex" alignItems="end" mb={isWidget ? 0 : 1}>
                  <Rating
                    value={reviewsSummary.metadata.averageRating}
                    readOnly
                    precision={0.1}
                    sx={{ mr: isWidget ? 0 : 1 }}
                  />
                  {!isWidget && !!reviewsSummary.metadata.averageRating && (
                    <Typography
                      variant="h6"
                      display="inline"
                      sx={{
                        fontWeight: "bold",
                        mr: 4,
                      }}
                    >
                      ({reviewsSummary.metadata.averageRating})
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={isWidget ? 6 : 12} display="flex" alignItems="end">
                  <Typography
                    variant="h6"
                    display="inline"
                    sx={{
                      fontWeight: 400,
                    }}
                  >
                    {isWidget ? "(" : ""}
                    {t("reviews.reviewsWithCount", { count: reviewsSummary.count })}
                    {isWidget ? ")" : ""}
                  </Typography>
                </Grid>
              </Grid>
              {!!review && (
                <>
                  <Typography variant="h5" fontWeight={"bold"} mt={2} mb={0.5}>
                    {t("reviews.latestReview")}
                  </Typography>
                  <ReviewDisplay
                    index={0}
                    localisedCopy={localisedReviewUICopy}
                    review={review}
                    reviewDateString={format(new Date(review.date), "MMM yyyy")}
                  />
                </>
              )}
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
