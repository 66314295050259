import { Autocomplete, Avatar, Box, Grid, TextField, Tooltip, Typography } from "@mui/material";
import { BaseProvider } from "@skibro/types";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useProvider } from "../Context/ProviderContext";
import { useUser } from "../Context/UserContext";
import { useWidth } from "../lib/hooks";

const ProviderPicker: React.FC<{ showAvatar?: boolean }> = (props) => {
  const { provider, chooseProvider, providerId } = useProvider();
  const { account, accountType } = useUser();
  const [providerImg, setProviderImg] = useState<string>("");
  const width = useWidth();
  const navigate = useNavigate();

  useEffect(() => {
    if (provider && accountType) {
      setProviderImg(accountType === "school" ? provider?.media?.logo : provider?.media?.portrait);
    }
  }, [provider, accountType]);

  if (!account) return null;

  return (
    <Grid container direction="column" alignItems="center">
      {!props.showAvatar && (
        <Grid item>
          <Typography display="inline" variant="h4">
            {provider?.name}
          </Typography>
        </Grid>
      )}

      {props.showAvatar &&
        (account.providers?.length > 1 ? null : (
          <Grid item>
            <Avatar sx={styles.avatar} src={providerImg}></Avatar>
          </Grid>
        ))}

      {account.providers?.length > 1 ? (
        <Grid item>
          <Autocomplete
            fullWidth
            sx={styles.picker}
            id="provider-selector"
            options={account.providers}
            getOptionLabel={(provider: BaseProvider) => provider.name}
            onChange={async (event, newValue: BaseProvider) => {
              if (newValue?.id && newValue.id?.toString() !== providerId?.toString()) {
                chooseProvider(newValue.id);
                navigate(`/${newValue.id}/home`);
              }
            }}
            isOptionEqualToValue={(opt, val) => opt.id === val.id}
            autoComplete
            size="small"
            style={{ width: ["xs", "sm"].includes(width) ? 180 : 200 }}
            renderInput={(params) => {
              return (
                <TextField fullWidth {...params} sx={styles.pickerInput} label="Choose Provider" variant="outlined" />
              );
            }}
            renderOption={(props, option, _state, ownerState) => {
              const { key, ...optionProps } = props;
              return (
                <Box key={option.slug ?? key} component="li" {...optionProps}>
                  {ownerState.getOptionLabel(option)}
                </Box>
              );
            }}
          />
          {provider?.userLogins?.length > 0 ? (
            <Grid container pt={1} display="grid" justifyContent={"center"}>
              <Tooltip title={provider?.userLogins?.[0]?.name}>
                <Typography display="inline" variant="body2">
                  {`Last Login: ${format(new Date(provider?.userLogins?.[0]?.lastLogin), "HH:mm, dd MMM yyyy")}`}
                </Typography>
              </Tooltip>
            </Grid>
          ) : null}
        </Grid>
      ) : null}
    </Grid>
  );
};

ProviderPicker.defaultProps = {
  showAvatar: true,
};

const styles = {
  avatar: {
    m: { sm: [1, "auto", 0, 1], xs: 1 },
  },
  picker: {
    pt: { xs: 1, md: 2 },
  },
  pickerInput: {
    backgroundColor: "white",
  },
};

export { ProviderPicker };
