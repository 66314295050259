import { useEffect } from "react";

import { useUser } from "../Context/UserContext";

export function IntercomSetup() {
  const { user } = useUser();
  const w: any = window;

  // TODO: Seems `contact` can't ever exists on the user object. So perhaps we need to add it? Or change where this code expects it to exist?

  useEffect(() => {
    if (w.intercomSettings) {
      w.intercomSettings.name = user?.name;
      // @ts-expect-error Not sure where `contact` is meant to come from here?
      w.intercomSettings.email = user?.email || user?.contact?.bookingsEmail || user?.contact?.financialsEmail;
      w.intercomSettings.created_at = new Date(user?.createdAt).getTime();
    } else {
      w.intercomSettings = {
        name: user?.name,
        // @ts-expect-error Not sure where `contact` is meant to come from here?
        email: user?.email || user?.contact?.bookingsEmail || user?.contact?.financialsEmail,
        created_at: new Date(user?.createdAt).getTime(),
      };
    }
  }, [user?.name, user, w]);

  return null;
}
