import { CircularProgress, Grid } from "@mui/material";
import React from "react";

export const CircularLoader: React.FC<{ visible: boolean; transparent?: boolean }> = (props) => {
  if (!props.visible) return null;
  return (
    <Grid
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        zIndex: 11,
        background: props.transparent ? "transparent" : "#FFFFFF99",
      }}
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      container
    >
      <Grid item>
        <CircularProgress disableShrink />
      </Grid>
    </Grid>
  );
};
