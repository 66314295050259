import React, { createContext, useContext, useEffect, useState } from "react";
import { useMutation, UseMutationResult, useQuery, useQueryClient } from "react-query";

import { ProductAvailabilities } from "./AvailabilityContext";
import { useProducts } from "./ProductsContext";
import { useProvider } from "./ProviderContext";
import { Availability } from "@skibro/types";

import API from "../lib/api";

interface instructorAvailabilityContextInterface {
  productId: number;
  availabilities: ProductAvailabilities;
  availabilitiesLoading: boolean;
  singleInstructorAvailabilityMutation: UseMutationResult<
    any,
    unknown,
    {
      productId: number;
      availability: any;
      action: string;
    },
    void
  >;
  instructorAvailabilitiesMutation: UseMutationResult<
    any,
    unknown,
    {
      productId: number;
      availabilities: any;
      availabilitiesToDelete?: any;
    },
    void
  >;
}

const InstructorAvailabilityContext = createContext<instructorAvailabilityContextInterface>(
  {} as instructorAvailabilityContextInterface
);

const useInstructorAvailabilityProvider = () => {
  const queryClient = useQueryClient();

  const { providerId } = useProvider();
  const { products } = useProducts();
  const [productId, setProductId] = useState<number>();
  const [availabilities, setAvailabilities] = useState<ProductAvailabilities>({});

  useEffect(() => {
    const productId = products[providerId]?.[0]?.id;

    if (!productId) return;

    setProductId(productId);
  }, [providerId, products]);

  const { isLoading: availabilitiesLoading } = useQuery(
    ["productAvailabilities", productId],
    () => API.getProductAvailabilitiesV2(productId),
    {
      enabled: !!productId,
      onSuccess: (data: Availability[]) => {
        setAvailabilities({ ...availabilities, [productId]: data });
      },
    }
  );

  const singleInstructorAvailabilityMutation: UseMutationResult<
    any,
    unknown,
    {
      productId: number;
      availability: any;
      action: string;
    },
    void
  > = useMutation(
    ({ productId, availability, action }: { productId: number; availability: any; action: "delete" | "update" }) => {
      switch (action) {
        case "delete":
          return API.deleteAvailabilityV2(productId, availability.id);
        case "update":
          return API.updateAvailabilityV2(productId, availability);
      }
    },
    {
      onSettled: async () => {
        await queryClient.invalidateQueries(["productAvailabilities"]);
      },
    }
  );

  const instructorAvailabilitiesMutation: UseMutationResult<
    any,
    unknown,
    {
      productId: number;
      availabilities: any;
      availabilitiesToDelete?: any;
    },
    void
  > = useMutation(
    ({
      productId,
      availabilities,
      availabilitiesToDelete: _availabilitiesToDelete,
    }: {
      productId: number;
      availabilities: any;
      availabilitiesToDelete?: any;
    }) => API.bulkCreateAvailabilitiesV2(productId, availabilities),
    {
      onSettled: async (_data, _error, _variables) => {
        await queryClient.invalidateQueries(["productAvailabilities"]);
      },
      onMutate: async ({ availabilitiesToDelete }) => {
        if (availabilitiesToDelete?.length > 0) {
          await Promise.all(
            availabilitiesToDelete?.map((availability) => API.deleteAvailabilityV2(productId, availability.id))
          );
        }
      },
    }
  );

  return {
    productId,
    availabilities,
    availabilitiesLoading:
      availabilitiesLoading ||
      singleInstructorAvailabilityMutation.isLoading ||
      instructorAvailabilitiesMutation.isLoading,
    singleInstructorAvailabilityMutation,
    instructorAvailabilitiesMutation,
  };
};

export const useInstructorAvailability = () => {
  return useContext(InstructorAvailabilityContext);
};

export const InstructorAvailabilityProvider = ({ children }) => {
  const avail = useInstructorAvailabilityProvider();
  return <InstructorAvailabilityContext.Provider value={avail}>{children}</InstructorAvailabilityContext.Provider>;
};
