import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Autocomplete, Button, FormControl, Grid, IconButton, Stack, TextField } from "@mui/material";
import { BaseResort } from "@skibro/types";
import Close from "@mui/icons-material/Close";
import { Draggable, Map, Marker, Point, ZoomControl } from "pigeon-maps";
import EditLocation from "@mui/icons-material/EditLocation";
import { isMobile } from "react-device-detect";

interface Props {
  meetingPoint?: {
    id?: number;
    lat?: number;
    long?: number;
    description: string;
    resort?: {
      id: number;
      name: string;
      slug: string;
    };
  };
  resorts: BaseResort[];
  setMeetingPoint: (meetingPoint) => void;
  isAmendment: boolean;
}

export const MeetingPointSelector: React.FC<Props> = ({ meetingPoint, setMeetingPoint, resorts, isAmendment }) => {
  const { t } = useTranslation();
  const [mapCenter, setMapCenter] = useState<Point>([
    meetingPoint?.lat ? Number(meetingPoint.lat) : null,
    meetingPoint?.long ? Number(meetingPoint.long) : null,
  ]);
  const [zoom, setZoom] = useState<number>(16);
  const [showMap, setShowMap] = useState<boolean>(meetingPoint?.lat && meetingPoint?.long ? true : false);
  const [descriptionRequired, setDescriptionRequired] = useState<boolean>(!isAmendment);

  function onChangeField({ target: { name, value } }): void {
    setDescriptionRequired(name === "description" ? false : true);
    setMeetingPoint({
      ...meetingPoint,
      [name]: value,
    });
  }

  useEffect(() => {
    setShowMap(meetingPoint?.lat && meetingPoint?.long ? true : false);
    setMapCenter([meetingPoint?.lat, meetingPoint?.long]);
  }, [meetingPoint?.lat, meetingPoint?.long]);

  useEffect(() => {
    if (!meetingPoint.lat && !meetingPoint.long && meetingPoint.resort) {
      const baseResort = resorts.find((resort) => resort.id === meetingPoint.resort.id);
      setMeetingPoint({
        ...meetingPoint,
        resort: baseResort,
        long: baseResort.coords?.coordinates[0],
        lat: baseResort.coords?.coordinates[1],
      });
    }
  }, [meetingPoint?.resort]);

  const onChangeResort = (resort: BaseResort): void => {
    setDescriptionRequired(true);
    setMeetingPoint({
      ...meetingPoint,
      resort,
      long: resort.coords?.coordinates[0],
      lat: resort.coords?.coordinates[1],
    });
  };

  const onMarkerMove = ([lat, long]: [number, number]): void => {
    setDescriptionRequired(true);
    setMeetingPoint({
      ...meetingPoint,
      lat,
      long,
    });
  };

  const mapTiler = (x, y, z): string => `https://c.tile.openstreetmap.fr/osmfr/${z}/${x}/${y}.png`;

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <FormControl fullWidth>
          {isMobile ? (
            <TextField
              value={meetingPoint?.resort?.id || ""}
              fullWidth
              error={!meetingPoint?.resort}
              name="resort"
              label={t("Resort")}
              type="text"
              select
              SelectProps={{
                native: true,
              }}
              onChange={(e) => {
                const resort = resorts.find((r) => r.id === Number(e.target.value));
                onChangeResort(resort);
              }}
            >
              <option value=""></option>
              {resorts.map((resort) => (
                <option key={resort.id} value={resort.id}>
                  {resort.name}
                </option>
              ))}
            </TextField>
          ) : (
            <Autocomplete<Partial<BaseResort>>
              options={resorts}
              getOptionLabel={(option: BaseResort) => option?.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              value={meetingPoint?.resort || ({} as BaseResort)}
              autoSelect
              onChange={(_, resort: BaseResort) => {
                if (!resort) return;
                onChangeResort(resort);
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    value={meetingPoint?.resort?.name || ""}
                    fullWidth
                    error={!meetingPoint?.resort}
                    name="resort"
                    label={t("Resort")}
                    type="text"
                  />
                );
              }}
            />
          )}
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl fullWidth>
          <TextField
            error={(!meetingPoint?.description && !isAmendment) || (isAmendment && descriptionRequired)}
            onChange={onChangeField}
            name="description"
            value={meetingPoint?.description || ""}
            label={t("Description")}
            type="text"
            fullWidth
            multiline
            rows={4}
          />
        </FormControl>
      </Grid>
      {showMap ? (
        <>
          <Grid item>
            <Stack height={300} minHeight={200} direction="row" position={"relative"}>
              <IconButton
                size="small"
                color="default"
                onClick={() => {
                  setMeetingPoint({
                    ...meetingPoint,
                    lat: null,
                    long: null,
                  });
                  setShowMap(false);
                }}
                sx={(theme) => ({
                  position: "absolute",
                  mr: 1,
                  mt: 1,
                  right: 0,
                  top: 0,
                  zIndex: 1,
                  background: theme.palette.common.white,
                  boxShadow:
                    "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
                })}
              >
                <Close />
              </IconButton>
              <Map
                defaultCenter={mapCenter}
                center={mapCenter}
                defaultZoom={zoom}
                provider={mapTiler}
                onBoundsChanged={({ zoom }) => {
                  setZoom(zoom);
                }}
                metaWheelZoom={false}
                attribution={
                  <span>
                    &copy; OpenStreetMap France | &copy;{" "}
                    <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors
                  </span>
                }
              >
                <Draggable
                  offset={[10, 12]}
                  anchor={[meetingPoint?.lat, meetingPoint?.long]}
                  onDragEnd={(anchorPosition) => {
                    onMarkerMove(anchorPosition);
                  }}
                >
                  <Marker color="red" />
                </Draggable>
                <ZoomControl />
              </Map>
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                size="small"
                fullWidth
                margin="dense"
                inputMode="decimal"
                InputLabelProps={{
                  shrink: !!meetingPoint?.lat,
                }}
                onChange={(ev) => {
                  onChangeField({ target: { name: "lat", value: Number(ev.target.value) } });
                }}
                name="lat"
                value={meetingPoint?.lat || null}
                label={t("Latitude")}
                type="number"
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                size="small"
                fullWidth
                margin="dense"
                inputMode="decimal"
                InputLabelProps={{
                  shrink: !!meetingPoint?.long,
                }}
                onChange={(ev) => {
                  onChangeField({ target: { name: "long", value: Number(ev.target.value) } });
                }}
                name="long"
                value={meetingPoint?.long || null}
                label={t("Longitude")}
                type="number"
              />
            </FormControl>
          </Grid>
        </>
      ) : (
        <Grid item>
          <FormControl fullWidth>
            <Button
              variant="contained"
              onClick={() => setShowMap(true)}
              color="secondary"
              startIcon={<EditLocation color="inherit" />}
            >
              {t("Add Location")}
            </Button>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};
