import ChatBubbleOutlined from "@mui/icons-material/ChatBubbleOutlined";
import { Button } from "@mui/material";
import { Reservation } from "@skibro/types";
import React from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

export const ChatButton: React.FC<{
  reservation: Reservation;
}> = ({ reservation }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button
      onClick={async () => {
        navigate(`/${reservation.providerId}/inbox?reservationId=${reservation.id}`);
      }}
      startIcon={<ChatBubbleOutlined />}
      variant="outlined"
      color="primary"
      fullWidth
    >
      {t("Chat")}
    </Button>
  );
};
