import CancelIcon from "@mui/icons-material/CancelOutlined";
import ConfirmIcon from "@mui/icons-material/CheckOutlined";
import CameraIcon from "@mui/icons-material/PhotoCamera";
import {
  Avatar,
  Box,
  Button,
  FormLabel,
  Grid,
  Hidden,
  IconButton,
  MenuItem,
  Skeleton,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { BaseProduct, BaseProvider, Language, SupportedLanguageCodes } from "@skibro/types";
import { Editor } from "@tinymce/tinymce-react";
import { Storage } from "aws-amplify";
import set from "lodash.set";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { EditButtonBar } from "../../Components/EditButtonBar";
import { ProviderPicker } from "../../Components/ProviderPicker";
import envConfig from "../../config/settings";
import { useContent } from "../../Context/ContentContext";
import { useProducts } from "../../Context/ProductsContext";
import { useProvider } from "../../Context/ProviderContext";
import { useUser } from "../../Context/UserContext";
import { deepCopy } from "../../lib/helpers";
import { CheckboxComboBoxBasic } from "../Availability/CheckboxComboBasic";
import { DescriptionGenerator } from "../../Components/DescriptionGenerator";
import DraggableList from "../../Components/DraggableComponents/DraggableList";
import Add from "@mui/icons-material/Add";
import { reorder } from "../../lib/utils";

interface DetailsProps {
  provider: BaseProvider;
  product: BaseProduct;
  accountType: string;
}

const Details: React.FC<DetailsProps> = ({ provider, accountType, product }) => {
  const { t } = useTranslation();
  const { languages, qualifications } = useContent();
  const { updateProvider } = useProvider();
  const { updateProduct } = useProducts();

  const [contactLanguageOptions, setContactLanguageOptions] = useState<Language[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<SupportedLanguageCodes>(provider?.contact.language ?? "en");

  const [localProvider, setLocalProvider] = useState<BaseProvider>();
  const [localProduct, setLocalProduct] = useState<BaseProduct>();
  const [edited, setEdited] = useState<boolean>(false);

  const [learningOutcomes, setLearningOutcomes] = useState([]);

  useEffect(() => {
    setContactLanguageOptions(languages.filter((l) => ["en", "fr", "it", "nl", "de"].includes(l.code)));
  }, [languages]);

  useEffect(() => {
    if (provider) {
      setLocalProvider(provider);
      setSelectedLanguage(provider.contact.language ?? "en");
    }
    if (product) setLocalProduct(product);
  }, [provider, product]);

  useEffect(() => {
    if (localProduct?.metadata?.learningOutcomes && learningOutcomes.length === 0) {
      setLearningOutcomes(
        localProduct?.metadata?.learningOutcomes.map((lO, index) => {
          return { id: `learningOutcome-${index}`, primary: lO };
        })
      );
    }
  }, [localProduct]);

  const setProviderValueByProp = (values: unknown, prop: string): void => {
    const newProvider = { ...localProvider };
    set(newProvider, prop, values ?? undefined);
    setEdited(true);
    setLocalProvider(newProvider);
  };

  const save = async (): Promise<void> => {
    if (edited) {
      await updateProvider(localProvider);
      await updateProduct(localProduct);
      setEdited(false);
    }
  };

  const onDragEnd = ({ destination, source }: any) => {
    if (!destination) return;

    const newLearningOutcomes = reorder(learningOutcomes, source.index, destination.index);
    setLearningOutcomes(newLearningOutcomes);
    updateLearningOutcomes(newLearningOutcomes.map((lO) => lO.primary));
  };

  const updateLearningOutcomes = (updatedLearningOutcomes: string[]) => {
    setLocalProduct({
      ...localProduct,
      metadata: {
        ...localProduct.metadata,
        learningOutcomes: selectedLanguage === "en" ? updatedLearningOutcomes : localProduct.metadata?.learningOutcomes,
        [selectedLanguage]: {
          ...localProduct.metadata[selectedLanguage],
          learningOutcomes:
            selectedLanguage !== "en"
              ? updatedLearningOutcomes
              : localProduct.metadata?.[selectedLanguage]?.learningOutcomes,
        },
      },
    });
    setEdited(true);
  };

  const updateDescription = (val: string, type: "description" | "lessonDescription") => {
    // fires on init so need to equality check to not dirty form immediately
    const span = document.createElement("span");
    span.innerHTML = val;
    if (
      (span.textContent || span.innerText).trim() !==
      (selectedLanguage === "en"
        ? localProvider.metadata?.[type]
        : localProvider.metadata?.[selectedLanguage]?.[type]
      )?.trim()
    ) {
      setEdited(true);
    }

    setLocalProvider({
      ...localProvider,
      metadata: {
        ...localProvider.metadata,
        [type]: selectedLanguage === "en" ? val : localProvider.metadata?.[type],
        [selectedLanguage]: {
          ...localProvider.metadata[selectedLanguage],
          [type]: selectedLanguage !== "en" ? val : localProvider.metadata?.[selectedLanguage]?.[type],
        },
      },
    });
  };

  return (
    <>
      {localProvider && (
        <Grid justifyContent="center" container spacing={2}>
          <Hidden mdUp>
            <Grid item xs={12}>
              <ProfileAvatar provider={localProvider} />
            </Grid>
          </Hidden>

          <Grid item xs={12}>
            <Typography sx={{ mb: 1 }} variant="h2">
              {t("Bio")}
            </Typography>
            <Editor
              value={
                (selectedLanguage === "en"
                  ? localProvider.metadata?.description
                  : localProvider.metadata?.[selectedLanguage]?.description) || ""
              }
              apiKey={envConfig.TINY_API_KEY}
              init={{
                branding: false,
                height: 300,
                menubar: false,
                plugins: ["lists"],
                toolbar:
                  "undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | removeformat | code fullscreen",
                valid_elements: "a,strong/b,div,br,p,em/i",
              }}
              onEditorChange={(value) => updateDescription(value, "description")}
            />
            {localProduct ? (
              <DescriptionGenerator
                productId={localProduct?.id}
                formData={localProduct}
                onGenerateDescription={(newDesc) => updateDescription(newDesc, "description")}
                type={provider.providerType.name.toLowerCase()}
                optionalIncludes={[
                  "oldDescription",
                  provider.providerType.name.toLowerCase() !== "school" && "reviews",
                ].filter((i) => i)}
              />
            ) : null}
          </Grid>

          <Grid item xs={12}>
            {languages?.length > 0 && (
              <CheckboxComboBoxBasic
                renderText="{{count}} languages selected"
                selected={localProvider?.languages}
                name="languages"
                label={t("Languages Offered")}
                options={languages}
                onUpdate={setProviderValueByProp}
                helperText={t(
                  "If you are able to communicate effectively and provide your service in multiple languages, you can select those above."
                )}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            {contactLanguageOptions?.length > 0 && (
              <TextField
                variant="outlined"
                fullWidth
                select
                label="Preferred language"
                helperText="Your preferred language will be used in correspondence between Skibro and yourself."
                onChange={(e) => {
                  const language = contactLanguageOptions.find((l) => l.code === e.target.value);

                  if (language) setProviderValueByProp(language.code, "contact.language");
                }}
                value={localProvider?.contact?.language || ""}
              >
                {contactLanguageOptions?.map((l) => (
                  <MenuItem key={l.id} value={l.code}>
                    {l.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Grid>

          {(accountType === "instructor" || accountType === "guide") && (
            <>
              <Grid item xs={12}>
                <CheckboxComboBoxBasic
                  renderText="{{count}} qualifications selected"
                  selected={localProvider?.qualifications}
                  name="qualifications"
                  label={t("Select Qualifications")}
                  options={qualifications}
                  onUpdate={setProviderValueByProp}
                />
              </Grid>
              <Grid container item xs={12} direction="column">
                <Typography sx={{ mb: 1 }} variant="h2">
                  {t("Learning Outcomes")}
                </Typography>
                {learningOutcomes?.length > 0 && (
                  <DraggableList
                    items={learningOutcomes}
                    onDragEnd={onDragEnd}
                    onDelete={(id) => {
                      const updated = learningOutcomes.filter((lO) => lO.id !== id);
                      setLearningOutcomes(updated);
                      updateLearningOutcomes(updated.map((lO) => lO.primary));
                    }}
                    onSave={(id, text) => {
                      const updated = learningOutcomes.map((lO) => {
                        if (lO.id === id) {
                          return { ...lO, primary: text };
                        }
                        return lO;
                      });
                      setLearningOutcomes(updated);
                      updateLearningOutcomes(updated.map((lO) => lO.primary));
                    }}
                  />
                )}
                <Grid item alignSelf="flex-end">
                  <Button
                    onClick={() =>
                      setLearningOutcomes([
                        ...learningOutcomes,
                        { id: `learningOutcome-${learningOutcomes.length}`, primary: "" },
                      ])
                    }
                    variant="outlined"
                    endIcon={<Add />}
                  >
                    {t("Add")}
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ mb: 1 }} variant="h2">
                  {t("Lesson Description")}
                </Typography>
                <Editor
                  value={
                    (selectedLanguage === "en"
                      ? localProvider.metadata?.lessonDescription
                      : localProvider.metadata[selectedLanguage]?.lessonDescription) || ""
                  }
                  apiKey={envConfig.TINY_API_KEY}
                  init={{
                    branding: false,
                    height: 300,
                    menubar: false,
                    plugins: ["lists"],
                    toolbar:
                      "undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | removeformat | code fullscreen",
                  }}
                  onEditorChange={(value) => updateDescription(value, "lessonDescription")}
                />
              </Grid>
            </>
          )}
        </Grid>
      )}

      <EditButtonBar
        edit={() => save()}
        cancel={() => {
          setEdited(false);
          setLocalProvider(deepCopy(provider));
          setLocalProduct(deepCopy(product));
        }}
        actionText={t("Save")}
        visible={edited}
      />
    </>
  );
};

const ProfileAvatar = ({ provider }: { provider?: BaseProvider }) => {
  const { updateProvider } = useProvider();
  const { accountType } = useUser();
  const [portrait, setPortrait] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [capturedImage, setCapturedImage] = useState<string | null>(null);

  useEffect(() => {
    setPortrait(accountType === "school" ? provider?.media?.logo : provider?.media?.portrait);
  }, [accountType, provider]);

  if (!provider)
    return (
      <FormLabel htmlFor="upload-profile-image" sx={styles.profileImage}>
        <Box sx={styles.buttonContainer}></Box>
        <Skeleton variant="circular">
          <Avatar sx={styles.portrait} />
        </Skeleton>
      </FormLabel>
    );
  const { name } = provider;
  const handleCancelFileSelection = (event) => {
    event.preventDefault();
    setFile(null);
    setCapturedImage(null);
  };

  const handleFileSelection = async (event) => {
    if (event.target.files && event.target.files[0]) {
      setCapturedImage(URL.createObjectURL(event.target.files[0]));
      setFile(event.target["files"][0]);
    } else {
      handleCancelFileSelection(event);
    }
  };

  const handleSaveFileSelection = async (event: SyntheticEvent) => {
    const filename = `media/providers/${provider.id}/${
      accountType === "school" ? "logo" : "portrait"
    }.${new Date().valueOf()}.${file.name.split(".").pop()}`;
    const savedFilename = await saveFile(filename, file, portrait);
    if (savedFilename) {
      const updatedProvider = deepCopy(provider);
      if (accountType === "school") {
        updatedProvider.media.logo = savedFilename;
      } else {
        updatedProvider.media.portrait = savedFilename;
        updatedProvider.media.landscape = savedFilename;
      }
      updateProvider(updatedProvider);
    }
    handleCancelFileSelection(event);
  };

  const saveFile = async (filename, file, oldImage?) => {
    try {
      const result: any = await Storage.put(filename, file);
      if (oldImage) await Storage.remove(oldImage);
      return `/${result.key}`;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  return (
    <Box sx={styles.profileImageContainer}>
      <input
        style={{ display: "none" }}
        type="file"
        accept="image/*"
        id="upload-profile-image"
        onChange={handleFileSelection}
        name="media.portrait"
        value=""
      />
      <FormLabel htmlFor="upload-profile-image" sx={styles.profileImage}>
        <Box sx={styles.buttonContainer}>
          {!capturedImage ? (
            <IconButton sx={styles.iconButton} component="span" size="large">
              <CameraIcon />
            </IconButton>
          ) : (
            <Grid container direction="row">
              <IconButton
                sx={{ ...styles.secondaryIconButton, right: 0, bottom: 0, position: "absolute" }}
                onClick={handleCancelFileSelection}
                name="media.portrait.cancel"
                size="large"
              >
                <CancelIcon />
              </IconButton>
              <IconButton
                sx={styles.secondaryIconButton}
                onClick={handleSaveFileSelection}
                name="media.portrait.save"
                size="large"
              >
                <ConfirmIcon />
              </IconButton>
            </Grid>
          )}
        </Box>
        <Avatar
          alt={name}
          src={
            capturedImage ||
            (portrait ? (portrait.includes("https") ? portrait : `${envConfig.BASE_URL}${portrait}`) : "")
          }
          sx={styles.portrait}
        />
      </FormLabel>
      <ProviderPicker showAvatar={false} />
    </Box>
  );
};

const styles: { [x: string]: SxProps } = {
  input: {
    display: "none",
  },
  profileImage: {
    position: "relative",
    width: (theme: Theme) => theme.spacing(15),
    display: "block",
    margin: "auto",
  },
  profileImageContainer: (theme: Theme) => ({
    position: "relative",
    width: (theme: Theme) => theme.spacing(20),
    display: "block",
    margin: "auto",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      m: 2,
      width: "100%",
    },
  }),
  buttonContainer: {
    position: "absolute",
    zIndex: 1,
    textAlign: "right",
    width: "100%",
  },
  portrait: {
    width: (theme: Theme) => theme.spacing(15),
    height: (theme: Theme) => theme.spacing(15),
    margin: (theme: Theme) => `${theme.spacing(2)} auto`,
    border: "2px solid #ededed",
  },
  iconButton: {
    left: 0,
    position: "absolute",
    backgroundColor: `#D1D1D155`, // used to be `${theme.palette.primary.dark}77` but that value isn't available anymore
    color: "#FFF",
    "&:hover": {
      backgroundColor: `#D1D1D177`,
    },
  },
  secondaryIconButton: {
    backgroundColor: (theme: Theme) => `${theme.palette.secondary.dark}77`,
    color: "#FFF",
    "&:hover": {
      backgroundColor: (theme: Theme) => `${theme.palette.secondary.dark}99`,
    },
  },
  cancel: {
    right: 0,
    bottom: 0,
    position: "absolute",
  },
};

export { ProfileAvatar };
export default Details;
