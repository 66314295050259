import {
  BaseActivity,
  CancellationPolicy,
  Currency,
  IncludedItem,
  Language,
  Level,
  Tag,
  YoutubeVideo,
} from "@skibro/types";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";

import API, { MinimalBaseResort } from "../lib/api";
import { appStorage } from "../lib/storage";

interface LessonType {
  name: string;
  slug: string;
}

interface MinimalBaseResortWithCountryName extends MinimalBaseResort {
  slug: string;
  country: {
    name: string;
  };
}

interface ContentContextType {
  resorts: MinimalBaseResortWithCountryName[];
  activities: BaseActivity[];
  levels: Level[];
  languages: Language[];
  qualifications: any[];
  loading: boolean;
  currencies: Currency[];
  lessonTypes: LessonType[];
  tags: Tag[];
  includedItems: IncludedItem[];
  tutorials: YoutubeVideo[];
  loadTutorials: () => void;
  cancellationPolicies: CancellationPolicy[];
}

const ContentContext = createContext<ContentContextType>({
  resorts: [],
  activities: [],
  levels: [],
  languages: [],
  qualifications: [],
  loading: true,
  currencies: [],
  lessonTypes: [],
  tags: [],
  includedItems: [],
  tutorials: [],
  loadTutorials: () => undefined,
  cancellationPolicies: [],
});

const useContentLoader = () => {
  const [resorts, setResorts] = useState<MinimalBaseResortWithCountryName[]>([]);
  const [activities, setActivities] = useState([]);
  const [levels, setLevels] = useState<Level[]>([
    { name: "First Timer", slug: "first-timer", id: 1 },
    { name: "Beginner", slug: "beginner", id: 2 },
    { name: "Advanced", slug: "advanced", id: 4 },
    { name: "Intermediate", slug: "intermediate", id: 3 },
  ]);
  const [tutorials, setTutorials] = useState<YoutubeVideo[] | null>();
  const [tags, setTags] = useState<Tag[]>();
  const [includedItems, setIncludedItems] = useState<IncludedItem[]>();

  const lessonTypes: LessonType[] = [
    { name: "Private", slug: "private" },
    { name: "Group", slug: "group" },
  ];

  const [languages, setLanguages] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const [currencies, setCurrencies] = useState<Currency[]>(null);
  const [cancellationPolicies, setCancellationPolicies] = useState<CancellationPolicy[]>(null);

  useEffect(() => {
    const loadContent = async () => {
      await Promise.all([
        loadResorts(),
        loadLanguages(),
        loadQualifications(),
        loadCurrencies(),
        loadCancellationPolicies(),
      ]);

      const [loadedLevels, loadedActivities] = await Promise.all([loadLevels(), loadActivities()]);

      if (loadedActivities.length) {
        const activitiesHolder = loadedActivities
          .filter((a) => a.active)
          .map((activity) => {
            activity.levels = loadedLevels.length ? loadedLevels : levels;
            return activity;
          });
        setActivities(activitiesHolder);
        await appStorage.setItem("storedActivities", {
          storageDate: new Date(),
          data: activitiesHolder,
        });
      }
    };

    (async () => {
      await loadContent();
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useQuery(
    "tags",
    async () => {
      const tags = await API.fetchTags();
      return tags.items;
    },
    {
      onSuccess: (data: Tag[]) => setTags(data),
      refetchOnWindowFocus: false,
    }
  );
  const { refetch: loadTutorials } = useQuery("tutorials", () => API.fetchTutorials(), {
    onSuccess: (data: YoutubeVideo[]) => setTutorials(data),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useQuery("includedItems", () => API.fetchIncludedItems(), {
    onSuccess: (data: IncludedItem[]) => setIncludedItems(data),
    refetchOnWindowFocus: false,
  });

  const loadResorts = async () => {
    try {
      const storedResorts: {
        storageDate: Date;
        data: any;
      } = await appStorage.getItem("storedResorts");
      if (storedResorts?.data) {
        setResorts(storedResorts.data);
      }
      const resorts = await API.fetchResorts<{ items: MinimalBaseResortWithCountryName[] }>({
        queryStringParameters: { active: true, include: ["id", "name", "slug", "country", "coords"], sort: "name:asc" },
      });
      const resortsWithCountryName = resorts.items.map((resort) => {
        return { ...resort, countryName: resort.country?.name };
      });
      setResorts(resortsWithCountryName);
      await appStorage.setItem("storedResorts", {
        storageDate: new Date(),
        data: resorts.items,
      });
    } catch (error) {
      await appStorage.removeItem("storedResorts");
      setError(true);
      setErrorMsg(error.message);
    }
  };

  const loadActivities = async () => {
    const storedActivities: {
      storageDate: Date;
      data: any;
    } = await appStorage.getItem("storedActivities");
    let activitiesHolder = [];
    if (storedActivities?.data) {
      activitiesHolder = storedActivities.data;
    }
    try {
      const activities = await API.fetchActivities();
      activitiesHolder = activities.items;
      setActivities(activitiesHolder);
      await appStorage.setItem("storedActivities", {
        storageDate: new Date(),
        data: activitiesHolder,
      });
      return activitiesHolder;
    } catch (error) {
      await appStorage.removeItem("storedActivities");
      setActivities([]);
      setError(true);
      setErrorMsg(error.message);
      return activitiesHolder;
    }
  };

  const loadLevels = async () => {
    const storedLevels: {
      storageDate: Date;
      data: any;
    } = await appStorage.getItem("storedLevels");
    if (storedLevels?.data) {
      setLevels(storedLevels.data);
    }
    try {
      const levels = await API.fetchLevels();
      setLevels(levels.items);
      await appStorage.setItem("storedLevels", {
        storageDate: new Date(),
        data: levels.items,
      });
      return levels.items;
    } catch (error) {
      await appStorage.removeItem("storedLevels");
      setError(true);
      setErrorMsg(error.message);
      return [];
    }
  };

  const loadLanguages = async () => {
    try {
      const storedLanguages: {
        storageDate: Date;
        data: any;
      } = await appStorage.getItem("storedLanguages");
      if (storedLanguages?.data) {
        setLanguages(storedLanguages.data);
      }
      const languages = await API.fetchLanguages();
      setLanguages(languages.items);
      await appStorage.setItem("storedLanguages", {
        storageDate: new Date(),
        data: languages.items,
      });
    } catch (error) {
      await appStorage.removeItem("storedLanguages");
      setError(true);
      setErrorMsg(error.message);
    }
  };

  const loadQualifications = async () => {
    try {
      const storedQualifications: {
        storageDate: Date;
        data: any;
      } = await appStorage.getItem("storedQualifications");
      if (storedQualifications?.data) {
        setQualifications(storedQualifications.data);
      }
      const qualifications = await API.fetchQualifications();
      setQualifications(qualifications.items);
      await appStorage.setItem("storedQualifications", {
        storageDate: new Date(),
        data: qualifications.items,
      });
    } catch (error) {
      await appStorage.removeItem("storedQualifications");
      setError(true);
      setErrorMsg(error.message);
    }
  };

  const loadCurrencies = async () => {
    try {
      const storedCurrencies: {
        storageDate: Date;
        data: Currency[];
      } = await appStorage.getItem("storedCurrencies");
      if (storedCurrencies?.data) {
        setCurrencies(storedCurrencies.data);
      }
      const currencies = await API.fetchCurrencies();
      setCurrencies(currencies);
      await appStorage.setItem("storedCurrencies", {
        storageDate: new Date(),
        data: currencies,
      });
    } catch (error) {
      setError(true);
      setErrorMsg(error.message);
    }
  };

  const loadCancellationPolicies = async () => {
    try {
      const storedCancellationPolicies: {
        storageDate: Date;
        data: CancellationPolicy[];
      } = await appStorage.getItem("storedCancellationPolicies");
      if (storedCancellationPolicies?.data) {
        setCancellationPolicies(storedCancellationPolicies.data);
      }
      const cancellationPolicies = await API.fetchCancellationPolicies();
      setCancellationPolicies(cancellationPolicies.sort((a, b) => a.metadata.order - b.metadata.order));
      await appStorage.setItem("storedCancellationPolicies", {
        storageDate: new Date(),
        data: cancellationPolicies,
      });
    } catch (error) {
      setError(true);
      setErrorMsg(error.message);
    }
  };

  return {
    resorts,
    activities,
    levels,
    languages,
    qualifications,
    error,
    errorMsg,
    loading,
    currencies,
    lessonTypes,
    tags,
    includedItems,
    tutorials,
    loadTutorials,
    cancellationPolicies,
  };
};

export type { MinimalBaseResortWithCountryName };

// hook
export const useContent = () => {
  return useContext(ContentContext);
};

// provider
export const ContentProvider = ({ children }) => {
  const content = useContentLoader();
  return <ContentContext.Provider value={content}>{children}</ContentContext.Provider>;
};
