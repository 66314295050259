import { Box, SxProps, Typography } from "@mui/material";
import React from "react";

const OfferedChangeCompared = ({ blockStyleMimic = null, from, to, inlineOrBlock }) => {
  return (
    <Box sx={blockStyleMimic ? styles.blockStyleMimic : blockStyleMimic}>
      <Typography
        variant="body1"
        align="left"
        color="textSecondary"
        component="p"
        sx={styles.changedDetailValueLineThrough}
        display={inlineOrBlock}
        gutterBottom={false}
      >
        {from}
      </Typography>
      <Typography
        variant="body1"
        align="left"
        component="p"
        sx={styles.changedDetailValue}
        display={inlineOrBlock}
        gutterBottom={false}
      >
        {to}
      </Typography>
    </Box>
  );
};

const styles: { [x: string]: SxProps } = {
  blockStyleMimic: {
    pt: 0.5,
  },
  changedDetailValueLineThrough: {
    m: 0.5,
    textDecoration: "line-through",
  },
  changedDetailValue: {
    m: 0.5,
    color: "primary.dark",
  },
};

export { OfferedChangeCompared };
