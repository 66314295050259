import { Grid, Typography } from "@mui/material";
import React from "react";

interface BookingDetailProps {
  label: string;
  value: string;
}
export const BookingDetail: React.FC<BookingDetailProps> = ({ label, value }) => {
  return (
    <Grid container direction="column" item spacing={1}>
      <Typography variant="h6" color="textSecondary" component="p">
        {label}
      </Typography>

      <Typography variant="body1" component="p">
        {value}
      </Typography>
    </Grid>
  );
};
