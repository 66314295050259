import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { BaseActivity, SupportedLanguageCodes } from "@skibro/types";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import * as yup from "yup";

import { allowedActivities } from "../config/constants";
import termsAndConditions from "../resources/TCs.json";
import { useContent } from "../Context/ContentContext";
import { useUser } from "../Context/UserContext";
import api from "../lib/api";
import { useWidth } from "../lib/hooks";
import { ArrowDropDown } from "@mui/icons-material";

export interface IFormInputs {
  firstName: string;
  lastName: string;
  accountType: "school" | "instructor" | "guide";
  schoolName?: string;
  language: SupportedLanguageCodes;
  activities: BaseActivity[];
  phoneNumber: string;
  countryCode: string;
  termsConditions: boolean;
}

export const SignupDialog: React.FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const { user, loading, setAccount, signOut } = useUser();
  const { activities } = useContent();
  const width = useWidth();
  const countryCodeRegExp = /^(\+?\d{1,3}|\d{1,4})$/;

  const [splitBtnOpen, setSplitBtnOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedLocale, setSelectedLocale] = React.useState("en");

  const queryClient = useQueryClient();
  const schema = yup
    .object()
    .shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      accountType: yup
        .string()
        .oneOf(["school", "instructor", "guide"])
        .required(t("Please choose from one of the options above")),
      countryCode: yup.string().matches(countryCodeRegExp, "Country code is not valid"),
      phoneNumber: yup.string().min(8).required("Phone number is not valid"),
      language: yup.string().oneOf(["en", "fr", "de", "it", "nl"]).required(),
      schoolName: yup.string().when("accountType", {
        is: "school",
        then: yup.string().required(t("School name is required")),
      }),
      activities: yup
        .array()
        .of(yup.object())
        .when("accountType", {
          is: "instructor" || "guide",
          then: yup.array().min(1).required(t("Choose your activities")),
        }),
      termsConditions: yup
        .boolean()
        .required(t("Please accept the terms and conditions"))
        .oneOf([true], t("Please accept the terms and conditions")),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const accountType = watch("accountType");
  const selectedActivities: BaseActivity[] = watch("activities", []);
  const language = watch("language");

  const userMutation: UseMutationResult<any, unknown, IFormInputs, void> = useMutation(
    (data: IFormInputs) => {
      return api.activateAccount(data, user.id);
    },
    {
      onMutate: async (_data: IFormInputs) => {
        await queryClient.cancelQueries(["user.accounts"]);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["user.accounts"]);
      },
      onSuccess: async (account) => {
        queryClient.setQueryData(["user.accounts"], account);
        setAccount(account);
        setOpen(false);
      },
    }
  );
  const onSubmit = (data: IFormInputs): void => {
    userMutation.mutate(data);
  };

  const handleSetActivities = (selectedActivityIds: BaseActivity["id"][]): void => {
    const matchedActivities = activities.filter((a) => selectedActivityIds.includes(a.id));

    setValue("activities", matchedActivities);
  };

  const handleMenuItemClick = (locale) => {
    setSelectedLocale(locale);
    setSplitBtnOpen(false);
  };

  const handleToggle = () => {
    setSplitBtnOpen((prevOpen) => !prevOpen);
  };

  const handleSplitBtnClose = () => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setSplitBtnOpen(false);
  };

  useEffect(() => {
    if (language) setSelectedLocale(language);
  }, [language]);

  useEffect(() => {
    if (!user?.accounts.length && !loading) setOpen(true);
  }, [loading, user]);

  return (
    <Dialog maxWidth="xs" fullScreen={width === "xs"} fullWidth open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t("Complete your sign up")}</DialogTitle>
        <DialogContent>
          <Grid spacing={2} container justifyContent={"center"}>
            <Grid item xs={12}>
              <DialogContentText>
                {t("Before you get started, we need a little bit of information about you:")}
              </DialogContentText>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{t("What should we call you?")}</FormLabel>
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="firstName"
                render={({ field, fieldState: { invalid } }) => (
                  <TextField {...field} inputRef={field.ref} error={invalid} label={t("First Name")} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="lastName"
                render={({ field, fieldState: { invalid } }) => (
                  <TextField {...field} inputRef={field.ref} error={invalid} label={t("Last Name")} />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="countryCode"
                render={({ field, fieldState: { invalid } }) => (
                  <TextField type={"tel"} {...field} inputRef={field.ref} error={invalid} label={t("Country Code")} />
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field, fieldState: { invalid } }) => (
                  <TextField
                    fullWidth
                    type={"tel"}
                    {...field}
                    inputRef={field.ref}
                    error={invalid}
                    label={t("Phone Number")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>What kind of activity provider are you?</FormLabel>
                <Controller
                  control={control}
                  name="accountType"
                  render={({ field }) => (
                    <RadioGroup {...field} row>
                      <FormControlLabel label={t("School")} value="school" control={<Radio />} />
                      <FormControlLabel label={t("Independent Instructor")} value="instructor" control={<Radio />} />
                      <FormControlLabel label={t("Guide")} value="guide" control={<Radio />} />
                    </RadioGroup>
                  )}
                />
                <FormHelperText>{errors.accountType?.message}</FormHelperText>
              </FormControl>
            </Grid>
            {accountType === "school" && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="schoolName"
                    render={({ field, fieldState: { invalid } }) => (
                      <TextField {...field} inputRef={field.ref} error={invalid} label={t("School Name")} />
                    )}
                  />
                </FormControl>
              </Grid>
            )}
            {["instructor", "guide"].includes(accountType) && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="activities"
                    render={({ field, fieldState: { invalid } }) => (
                      <TextField
                        select
                        fullWidth
                        placeholder={t("Activities Offered")}
                        label={t("Activities Offered")}
                        {...field}
                        value={field.value?.map((a) => a.id) || []}
                        inputRef={field.ref}
                        onChange={(e) => handleSetActivities(e.target.value as any)}
                        error={invalid}
                        SelectProps={{
                          multiple: true,
                          renderValue: (_selected) => (
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                              {selectedActivities?.map((activity) => (
                                <Chip key={activity.id} label={activity.name} />
                              ))}
                            </Box>
                          ),
                        }}
                      >
                        {activities
                          ?.filter((a) => allowedActivities.includes(a.name))
                          .map((activity) => (
                            <MenuItem key={activity.id} value={activity.id}>
                              {activity.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="language"
                  render={({ field, fieldState: { invalid } }) => (
                    <TextField
                      select
                      fullWidth
                      placeholder={t("Preferred Language")}
                      label={t("Preferred Language")}
                      {...field}
                      inputRef={field.ref}
                      error={invalid}
                    >
                      <MenuItem value="en">{t("English")}</MenuItem>
                      <MenuItem value="fr">{t("French")}</MenuItem>
                      <MenuItem value="it">{t("Italian")}</MenuItem>
                      <MenuItem value="de">{t("German")}</MenuItem>
                      <MenuItem value="nl">{t("Dutch")}</MenuItem>
                    </TextField>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>{t("By checking the box below you accept our terms and conditions")}</FormLabel>
                <ButtonGroup variant="text" ref={anchorRef} sx={{ pt: 1 }}>
                  <Button
                    target="_blank"
                    rel="noopener"
                    href={termsAndConditions[selectedLocale]?.link ?? termsAndConditions["en"].link}
                  >
                    {termsAndConditions[selectedLocale]?.text ?? termsAndConditions["en"].text}
                  </Button>
                  <Button size="small" onClick={handleToggle}>
                    <ArrowDropDown />
                  </Button>
                </ButtonGroup>
                <Popper
                  sx={{
                    zIndex: 9999,
                  }}
                  open={splitBtnOpen}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleSplitBtnClose}>
                          <MenuList autoFocusItem>
                            {Object.keys(termsAndConditions).map((tC) => (
                              <MenuItem
                                key={tC}
                                disabled={termsAndConditions[tC].link === ""}
                                selected={tC === selectedLocale}
                                onClick={() => handleMenuItemClick(tC)}
                              >
                                {termsAndConditions[tC].text}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                <Controller
                  control={control}
                  name="termsConditions"
                  render={({ field }) => (
                    <>
                      <Stack direction="row" alignItems="center" pl={1}>
                        <FormLabel>{t("I accept the terms and conditions")}</FormLabel>
                        <Checkbox {...field} color="primary" />
                      </Stack>
                      <FormHelperText error>{errors.termsConditions?.message}</FormHelperText>
                    </>
                  )}
                ></Controller>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => signOut()}>{t("Exit")}</Button>
          <Button variant="contained" type="submit">
            {t("Submit")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
