import CancelIcon from "@mui/icons-material/CancelOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import SaveIcon from "@mui/icons-material/SaveOutlined";
import { Button, Grid, Paper, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";

const EditButtonBar: React.FC<any> = (props: {
  cancel: () => void;
  edit: () => void;
  visible: boolean;
  actionText: "Save" | "Edit";
  position?: "fixed" | "relative";
}) => {
  const { cancel, edit, visible, actionText, position } = props;
  const { t } = useTranslation();
  return visible ? (
    <Paper elevation={1} sx={position === "relative" ? styles.buttonHolderRelative : styles.buttonHolderFixed}>
      <Grid alignContent="center" container spacing={2} justifyContent="center">
        <Grid item xs={5} sm={4} md={6}>
          <Button startIcon={<CancelIcon />} fullWidth variant="outlined" color="primary" onClick={cancel}>
            {t("Cancel")}
          </Button>
        </Grid>
        <Grid item xs={5} sm={4} md={6}>
          <Button
            startIcon={actionText === "Edit" ? <EditIcon /> : <SaveIcon />}
            fullWidth
            variant="contained"
            color="primary"
            onClick={edit}
          >
            {t(actionText)}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  ) : null;
};
EditButtonBar.defaultProps = {
  visible: false,
  actionText: "Edit",
};

const styles: { [x: string]: SxProps } = {
  buttonHolderFixed: {
    display: "flex",
    position: "fixed",
    bottom: 0,
    zIndex: (theme: Theme) => ({
      xs: 1310,
      md: theme.zIndex.drawer + 1,
    }),
    background: "#fff",
    height: (theme: Theme) => ({
      xs: theme.spacing(7),
      md: theme.spacing(6),
    }),
    minWidth: (theme: Theme) => ({
      xs: theme.spacing(40),
      md: theme.spacing(32),
    }),
    p: 1,
    right: (theme: Theme) => ({ md: theme.spacing(12) }),
    top: (theme: Theme) => ({ md: theme.spacing(1) }),
    width: { xs: "100%", md: "auto" },
    left: { xs: 0, md: "auto" },
    textAlign: "right",
  },
  buttonHolderRelative: {
    minWidth: (theme: Theme) => ({
      xs: theme.spacing(40),
      md: theme.spacing(32),
    }),
    display: "flex",
    position: "relative",
    bottom: 0,
    zIndex: (theme: Theme) => ({
      xs: 1310,
      md: theme.zIndex.drawer + 1,
    }),
    right: 0,
    background: "#fff",

    height: (theme: Theme) => ({
      xs: theme.spacing(7),
      md: theme.spacing(6),
    }),
    p: { md: 1 },
    width: { xs: "100%", md: "auto" },
    left: "auto",
    textAlign: "right",
  },
};

export { EditButtonBar };
