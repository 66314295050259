import { Availability } from "@skibro/types";
import { eachDayOfInterval, format, setHours, setMinutes, startOfDay, startOfWeek, endOfWeek } from "date-fns";
import { de, enGB as en, fr } from "date-fns/esm/locale";

export const locales = {
  en,
  fr,
  de,
};

export const localeFormat =
  (i18nextLanguage = "en") =>
  (date: Date, formatString: string, options = {}) =>
    format(date, formatString, { locale: locales[i18nextLanguage], ...options });

export const convertMinutesPastMidNightToDate = (minutes: number): Date => {
  const time = setMinutes(setHours(startOfDay(new Date()), minutes / 60), minutes % 60);

  return time;
};

export const daysOfWeek = eachDayOfInterval({
  start: startOfWeek(new Date()),
  end: endOfWeek(new Date()),
});
export const getDaysOptions = (language) =>
  daysOfWeek.map((day) => ({
    value: day.getDay() as 0 | 1 | 2 | 3 | 4 | 5 | 6,
    name: format(day, "EEE", { locale: locales[language] }),
  }));
