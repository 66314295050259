import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Container, IconButton, Slide, Stack, Toolbar, Typography, useScrollTrigger } from "@mui/material";
import { useNavigate } from "react-router";

import { useSideBar } from "../Context/SideBarContext";
import { skiBroColors } from "../lib/theme";

interface Props {
  title?: string;
  routeBackPath?: string;
  routeState?: Record<string, unknown>;
  children?: any;
  headerAction?: any;
}

export const PageHeader: React.FC<Props> = ({ title, routeBackPath, children, headerAction, routeState }) => {
  const navigate = useNavigate();
  const { toggleSideBar } = useSideBar();

  const isScrollingDown = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!isScrollingDown}>
      <AppBar
        position="sticky"
        elevation={2}
        sx={{
          mb: 3,
          background: skiBroColors.snow[0],
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={3}
              sx={{ width: "100%" }}
              alignItems="center"
            >
              <Stack direction="row" spacing={3} alignItems="center">
                {routeBackPath && (
                  <IconButton
                    onClick={() => {
                      navigate(routeBackPath, { state: { ...routeState } });
                    }}
                    edge="start"
                    sx={{ mr: 1 }}
                  >
                    <ArrowBackRoundedIcon />
                  </IconButton>
                )}

                {!routeBackPath && (
                  <IconButton
                    sx={{ display: { md: "none", sm: "inline-flex" } }}
                    onClick={() => toggleSideBar()}
                    edge="start"
                  >
                    <MenuIcon />
                  </IconButton>
                )}

                {title && (
                  <Typography variant="h1" sx={{ ml: "0 !important" }}>
                    {title}
                  </Typography>
                )}
              </Stack>

              {headerAction}
            </Stack>
          </Toolbar>

          <Box>{children}</Box>
        </Container>
      </AppBar>
    </Slide>
  );
};
