import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { Reservation } from "@skibro/types";
import { t } from "i18next";
import { useEffect, useState } from "react";

import { PieChartData, SimplePieChart } from "../../Components/Charts/PieChart";
import { reservationIndicatorColors } from "../../lib/theme";

interface Props {
  reservations: Reservation[];
}

export const ReservationStatusOverview: React.FC<Props> = ({ reservations }) => {
  const [statusGraphData, setStatusGraphData] = useState<PieChartData[]>();

  useEffect(() => {
    const pieData = createPieChartData();

    setStatusGraphData(pieData);
  }, [reservations]);

  const createPieChartData = (): PieChartData[] => {
    const statusCounts: {
      [x: string]: number;
    } = reservations
      .filter((r) => r.status !== "ARCHIVED")
      .reduce(
        (acc, reservation) => ({
          ...acc,
          // eslint-disable-next-line no-prototype-builtins
          [reservation.status]: acc.hasOwnProperty(reservation.status) ? (acc[reservation.status] += 1) : 1,
        }),
        {}
      );

    const pieChartObjects = Object.entries(statusCounts).map(([statusKey, count]) => ({
      name: statusKey.toLowerCase(),
      value: count,
      color: reservationIndicatorColors[statusKey.toLowerCase()],
    }));

    return pieChartObjects;
  };

  return (
    <Card sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader title={<Typography variant="h4">{t<string>("Overview")}</Typography>} subheader="Bookings" />

      <CardContent sx={{ height: "250px" }}>{statusGraphData && <SimplePieChart data={statusGraphData} />}</CardContent>
    </Card>
  );
};
