import { Reservation } from "@skibro/types";
import { isWithinInterval, startOfDay } from "date-fns";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useMutation, UseMutationResult, useQuery, useQueryClient } from "react-query";

import { useProvider } from "./ProviderContext";

import { statuses } from "../config/constants";
import api from "../lib/api";
import { useDebounce } from "../lib/hooks";

interface FilterParams {
  searchTerm: string;
  from: Date | null;
  to: Date | null;
  dateType: string | null;
}

interface ReservationsContextInterface {
  filteredReservations: Reservation[];
  allReservations: Reservation[];
  setFilterParams: React.Dispatch<React.SetStateAction<FilterParams>>;
  filterParams: FilterParams;
  reservationMutation: UseMutationResult<
    any,
    unknown,
    {
      reservation: Reservation;
      action?: "confirm" | "decline" | "cancel" | null;
    },
    void
  >;
  isLoading: boolean;
  isFetching: boolean;
  pendingReservations: number;
}

const ReservationsContext = createContext<ReservationsContextInterface>({} as ReservationsContextInterface);

const useReservationsProvider = () => {
  const [filterParams, setFilterParams] = useState<FilterParams>({
    searchTerm: "",
    from: null,
    to: null,
    dateType: null,
  });

  const [filteredReservations, setFilteredReservations] = useState<Reservation[]>([]);
  const [allReservations, setAllReservations] = useState<Reservation[]>([]);
  const [pendingReservations, setPendingReservations] = useState<number>(0);
  const { providerId } = useProvider();
  const debouncedFilters = useDebounce(filterParams, 500);
  const queryClient = useQueryClient();

  const { isLoading, isFetching } = useQuery(
    ["reservations", providerId],
    () => api.fetchProviderReservations(providerId),
    {
      enabled: false,
      onSuccess: (data: Reservation[]) => {
        data.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());
        data.forEach((reservation, index) => {
          if (statuses[`${reservation.status}`].displayAs === "Awaiting confirmation") {
            data.splice(index, 1);
            data.unshift(reservation);
          }
        });
        setAllReservations(data);
        setPendingReservations(data.filter((r) => r.status === "AWAITING").length);
      },
    }
  );

  useEffect(() => {
    (async () => {
      if (providerId) {
        await queryClient.prefetchQuery(["reservations", providerId]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerId]);

  useEffect(() => {
    const { from, to, searchTerm, dateType } = debouncedFilters;
    if (!from && !to && !searchTerm && !dateType) {
      setFilteredReservations(allReservations);
      return;
    }
    const filteredReservations = allReservations?.filter((reservation) => {
      const comparisonDate = dateType === "created" ? reservation.createdAt : reservation.startDate;
      const searchValues = `${reservation?.booking?.uniqueBookingReference}|${reservation.id}|${reservation.source}|${
        reservation.resort?.name
      }|${reservation?.booking?.user?.name}|${reservation?.booking?.partyDetails?.phoneNumber}|${
        reservation?.booking?.partyDetails?.firstName
      } ${reservation?.booking?.partyDetails?.lastName}|${reservation?.participants
        ?.map((p) => `${p.firstName} ${p.lastName}`)
        .join("|")}`.toUpperCase();
      if (
        searchTerm &&
        from &&
        to &&
        searchValues.includes(searchTerm.toUpperCase()) &&
        isWithinInterval(startOfDay(new Date(comparisonDate)), {
          start: from,
          end: to,
        })
      ) {
        return true;
      }
      if (searchTerm && (!from || !to) && searchValues.includes(searchTerm.toUpperCase())) {
        return true;
      }
      if (
        !searchTerm &&
        from &&
        to &&
        isWithinInterval(startOfDay(new Date(comparisonDate)), {
          start: from,
          end: to,
        })
      ) {
        return true;
      }
      return false;
    });
    setFilteredReservations(filteredReservations);
  }, [debouncedFilters, allReservations]);

  const reservationMutation: UseMutationResult<
    any,
    unknown,
    {
      reservation: Reservation;
      action?: "confirm" | "decline" | "cancel" | null;
      reason?: string | null;
    },
    void
  > = useMutation({
    mutationFn: async ({
      reservation,
      action,
      reason,
    }: {
      reservation: Reservation;
      action?: "confirm" | "decline" | "cancel" | null;
      reason?: string | null;
    }) => {
      let response;
      if (action === "cancel") {
        response = await api.cancelReservation(reservation.id, reason);
      } else if (action === "confirm" || action === "decline") {
        response = await api.commitReservationAction(reservation.id, action);
      } else response = await api.updateReservation(reservation);
      return response;
    },
    onMutate: async ({ reservation: _newReservation }: { reservation: Reservation }) => {
      await queryClient.cancelQueries(["reservations", providerId]);
    },
    onSettled: () => {
      queryClient.invalidateQueries(["reservations", providerId]);
    },
    onSuccess: async (mutatedReservationResponse) => {
      const { item: mutatedReservation } = mutatedReservationResponse;
      queryClient.setQueryData(["reservations", providerId], (oldReservations: Reservation[]) => {
        const updatedReservations = oldReservations.map((reservation) => {
          if (reservation.id === mutatedReservation.id) return mutatedReservation;
          return reservation;
        });
        return updatedReservations;
      });
    },
  });

  return {
    filteredReservations,
    allReservations,
    setFilterParams,
    filterParams,
    reservationMutation,
    isLoading,
    isFetching,
    pendingReservations,
  };
};

// hook
export const useReservations = () => {
  return useContext(ReservationsContext);
};

export const ReservationsProvider = ({ children }) => {
  const values = useReservationsProvider();
  return <ReservationsContext.Provider value={values}>{children}</ReservationsContext.Provider>;
};
