import { Availability, BaseProductVariant, ProductVariantDayTime } from "@skibro/types";
import chroma from "chroma-js";
import { addDays, addMinutes, format, startOfDay, subDays } from "date-fns";
import isWithinInterval from "date-fns/isWithinInterval";
import parseISO from "date-fns/parseISO";

import theme from "./theme";

const separateOverlappedAvailabilities = (
  overlappedAvailabilities: Availability[],
  selectedStartDate: string,
  selectedEndDate: string
): [Availability[], Availability[]] => {
  const onlyEndWithinInterval = overlappedAvailabilities.filter((availability) => {
    const startOverlap = checkDateOverlap(availability.startDate, selectedStartDate, selectedEndDate);
    const endOverlap = checkDateOverlap(availability.endDate, selectedStartDate, selectedEndDate);
    return endOverlap && !startOverlap;
  });
  const onlyStartWithinInterval = overlappedAvailabilities.filter((availability) => {
    const startOverlap = checkDateOverlap(availability.startDate, selectedStartDate, selectedEndDate);
    const endOverlap = checkDateOverlap(availability.endDate, selectedStartDate, selectedEndDate);
    return !endOverlap && startOverlap;
  });
  const intervalWithinAvailability = overlappedAvailabilities.filter((availability) => {
    const startOverlap = checkDateOverlap(availability.startDate, selectedStartDate, selectedEndDate);
    const endOverlap = checkDateOverlap(availability.endDate, selectedStartDate, selectedEndDate);
    return !endOverlap && !startOverlap;
  });
  const availabilitiesToDelete = overlappedAvailabilities.filter((availability) => {
    const startOverlap = checkDateOverlap(availability.startDate, selectedStartDate, selectedEndDate);
    const endOverlap = checkDateOverlap(availability.endDate, selectedStartDate, selectedEndDate);
    return endOverlap && startOverlap;
  });

  const splitAvailabilities = [];

  splitAvailabilities.push(
    ...onlyEndWithinInterval.map((availability) => {
      return {
        ...availability,
        endDate: format(subDays(parseISO(selectedStartDate), 1), "yyyy-MM-dd"),
      };
    })
  );

  splitAvailabilities.push(
    ...onlyStartWithinInterval.map((availability) => {
      return {
        ...availability,
        startDate: format(addDays(parseISO(selectedEndDate), 1), "yyyy-MM-dd"),
      };
    })
  );

  splitAvailabilities.push(
    ...intervalWithinAvailability.flatMap((availability) => {
      return [
        {
          ...availability,
          endDate: format(subDays(parseISO(selectedStartDate), 1), "yyyy-MM-dd"),
        },
        {
          ...availability,
          id: undefined,
          startDate: format(addDays(parseISO(selectedEndDate), 1), "yyyy-MM-dd"),
        },
      ];
    })
  );

  return [splitAvailabilities, availabilitiesToDelete];
};

const checkDateOverlap = (dateToCheck: Date, selectedStartDate: string, selectedEndDate: string): boolean => {
  const start = new Date(selectedStartDate);
  const end = new Date(selectedEndDate);
  return isWithinInterval(new Date(dateToCheck), {
    start,
    end,
  });
};

// colours
const getContrastYIQ = (hc: string): boolean => {
  const [r, g, b] = [1, 3, 5].map((p) => parseInt(hc.substring(p, p + 2), 16));
  return (r * 299 + g * 587 + b * 114) / 1000 >= 160;
};

const getTextColorForBackground = (color: string): "black" | "white" => {
  if (color === theme.palette.secondary.light) return "black";
  const hex = chroma(color).hex();
  return getContrastYIQ(hex) ? "black" : "white";
};

const createVariantTitleFromTimeProps = (start: number, duration: number): string => {
  const startDay = startOfDay(new Date());
  const end = start + duration;
  return `${format(addMinutes(startDay, start), "HH:mm")} - ${format(addMinutes(startDay, end), "HH:mm")}`;
};

const createProductVariants = (
  instructorAvailability,
  product
): Pick<BaseProductVariant, "name" | "productId" | "daysTimes" | "pricing">[] => {
  return instructorAvailability.variants.map((v) => {
    const daysTimes = instructorAvailability.activeDays.map((dayInt) => {
      return {
        day: dayInt as ProductVariantDayTime["day"],
        start: v.start,
        duration: v.duration,
        isStart: true,
      };
    });

    const variant = {
      name: createVariantTitleFromTimeProps(v.start, v.duration),
      productId: product.id,
      daysTimes,
      pricing: [
        {
          level: 0,
          prices: v.prices,
        },
      ],
    };

    return variant;
  });
};
const findStringOverlap = (a: string, b: string): string => {
  if (b.length === 0) {
    return "";
  }

  if (a.endsWith(b)) {
    return b;
  }

  if (a.indexOf(b) >= 0) {
    return b;
  }

  return findStringOverlap(a, b.substring(0, b.length - 1));
};

const truncateStringToLastWord = (txt: string, n: number): string => {
  if (txt.length <= n) {
    return txt;
  }
  const truncated = txt.substring(0, n);
  if (txt.charAt(n) === " ") {
    return truncated.slice(0, -1);
  }
  return truncated.substring(0, truncated.lastIndexOf(" "));
};

// instructor variant sort
const sortVariantsByTimeAsc = (variants) => {
  return variants.sort((a, b) => {
    if (a.daysTimes[0].start === b.daysTimes[0].start) {
      return a.daysTimes[0].duration > b.daysTimes[0].duration ? 1 : -1;
    }

    return a.daysTimes[0].start > b.daysTimes[0].start ? 1 : -1;
  });
};

const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export {
  sortVariantsByTimeAsc,
  checkDateOverlap,
  createProductVariants,
  createVariantTitleFromTimeProps,
  getTextColorForBackground,
  separateOverlappedAvailabilities,
  findStringOverlap,
  truncateStringToLastWord,
  reorder,
};
