export const MAP_TILER_KEY = "Pwgq33M6E6AIVuUdGJsy";

export const config = {
  local: {
    ADMIN_API: "http://localhost:4003",
    API_KEY: "d41d8cd98f00b204e9800998ecf8427e",
    IDENTITY_POOL_ID: "eu-west-1:85eb978b-98fe-434a-9c9a-3772cbc8e986",
    S3BUCKETNAME: "content-develop.skibro.net",
    PUBLIC_URL: "https://develop.skibro.net",
    BASE_URL: "https://provider-develop.skibro.net",
    BOOKINGS_API_URL: "http://localhost:4004",
    USER_POOL_ID: "eu-west-1_CKnW127JV",
    APP_CLIENT_ID: "jejq9jq19c9kdrjd2q6sb5q77",
    chatAppId: "t05NK4s5",
    TINY_API_KEY: "lqcv5blbno1tqxr0y12417jw2iepugj9t550aoxo9zmypsgi",
    userPoolDomain: "providers-staging.auth.eu-west-1.amazoncognito.com",
    wpRestUrl: "https://content.skibro.net/wp-json/wp/v2",
    testUrl: "http://localhost:3005",
    wsUrl: "ws://localhost:3001",
  },
  develop: {
    ADMIN_API: "https://admin-api-develop.skibro.net",
    API_KEY: "4tFQP0TTQ02QrllDPXwyuazBst89iqryaO5986ha",
    IDENTITY_POOL_ID: "eu-west-1:954cb1cf-6766-4436-b3fe-4a5117a9d315",
    S3BUCKETNAME: "content-develop.skibro.net",
    PUBLIC_URL: "https://develop.skibro.net",
    BASE_URL: "https://provider-develop.skibro.net",
    USER_POOL_ID: "eu-west-1_yJJH8V9xn",
    APP_CLIENT_ID: "7e7civej7lgjnjv3oim1jd7o66",
    BOOKINGS_API_URL: "https://booking-api-develop.skibro.net",
    chatAppId: "t05NK4s5",
    TINY_API_KEY: "lqcv5blbno1tqxr0y12417jw2iepugj9t550aoxo9zmypsgi",
    userPoolDomain: "providers-develop.auth.eu-west-1.amazoncognito.com",
    wpRestUrl: "https://content.skibro.net/wp-json/wp/v2",
    testUrl: "https://guest:aufhg307qfg283y92801dhow88s93@provider-develop.skibro.net",
    wsUrl: "wss://ws-admin-develop.skibro.net",
  },
  staging: {
    ADMIN_API: "https://admin-api-staging.skibro.net",
    API_KEY: "nbFHeiAxf8aJixjitBYJG3nOu75lTvCz8bBAvBD9",
    IDENTITY_POOL_ID: "eu-west-1:85eb978b-98fe-434a-9c9a-3772cbc8e986",
    S3BUCKETNAME: "content-staging.skibro.net",
    PUBLIC_URL: "https://staging.skibro.net",
    BASE_URL: "https://provider-staging.skibro.net",
    USER_POOL_ID: "eu-west-1_CKnW127JV",
    APP_CLIENT_ID: "jejq9jq19c9kdrjd2q6sb5q77",
    BOOKINGS_API_URL: "https://booking-api-staging.skibro.net",
    chatAppId: "t05NK4s5",
    TINY_API_KEY: "lqcv5blbno1tqxr0y12417jw2iepugj9t550aoxo9zmypsgi",
    userPoolDomain: "providers-staging.auth.eu-west-1.amazoncognito.com",
    wpRestUrl: "https://content.skibro.net/wp-json/wp/v2",
    testUrl: "https://guest:aufhg307qfg283y92801dhow88s93@provider-staging.skibro.net",
    wsUrl: "wss://ws-admin-staging.skibro.net",
  },
  production: {
    ADMIN_API: "https://admin-api.skibro.net",
    API_KEY: "6gs4ZFAwUd5Qoe1H8GnLb9aED2itA4nagnOciYk6",
    IDENTITY_POOL_ID: "eu-west-1:51a6e890-10e4-48c1-b43c-568b6888ab17",
    S3BUCKETNAME: "content.skibro.net",
    PUBLIC_URL: "https://www.skibro.com",
    BASE_URL: "https://provider.skibro.net",
    USER_POOL_ID: "eu-west-1_mh5roAIzp",
    APP_CLIENT_ID: "rrir09grojv7dpe64mvinoio8",
    BOOKINGS_API_URL: "https://booking-api.skibro.net",
    chatAppId: "IueWYc8P",
    TINY_API_KEY: "lqcv5blbno1tqxr0y12417jw2iepugj9t550aoxo9zmypsgi",
    userPoolDomain: "providers.auth.eu-west-1.amazoncognito.com",
    wpRestUrl: "https://content.skibro.net/wp-json/wp/v2",
    testUrl: "https://guest:aufhg307qfg283y92801dhow88s93@provider-staging.skibro.net",
    wsUrl: "wss://ws-admin.skibro.net",
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_mh5roAIzp",
    APP_CLIENT_ID: "rrir09grojv7dpe64mvinoio8",
    // USER_POOL_ID: "eu-west-1_x3OaeYM6Q",
    // APP_CLIENT_ID: "1d4cijgsqoelhg5lerq9381k5n"
  },
  s3: {
    REGION: "eu-west-1",
  },
};
export const adminEndpoint = config[process.env.REACT_APP_STAGE || "develop"].ADMIN_API;
export const adminApiKey = config[process.env.REACT_APP_STAGE || "develop"].API_KEY;
export const bookingsEndPoint = config[process.env.REACT_APP_STAGE || "develop"].BOOKINGS_API_URL;
export const baseUrl = config[process.env.REACT_APP_STAGE || "develop"].BASE_URL;
export const publicUrl = config[process.env.REACT_APP_STAGE || "develop"].PUBLIC_URL;
export const userPoolDomain = config[process.env.REACT_APP_STAGE || "develop"].userPoolDomain;
export const wpRestUrl = config[process.env.REACT_APP_STAGE || "develop"].wpRestUrl;
export default config[process.env.REACT_APP_STAGE || "local"];
