import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const skiBroColors = {
  snow: ["#FAFAFA", "#FAFAFA", "#FAFAFA", "#FAFAFA", "#F2F2F2"],
  frenchRaspberry: ["#EE2152", "#F14D75", "#F57A97", "#F8A6BA", "#FCD3DC"],
  bluebird: ["#2578BF", "#5193CC", "#66A1D2", "#92BCDF", "#BED7EC"],
  paleAzure: ["#D7EDF7", "#DFF1F9", "#E7F4FA", "#EFF8FC", "#F7FBFD"],
  prussianBlue: ["#1D3856", "#4A6078", "#77889A", "#A5AFBB", "#D2D7DD"],
  slateBlack: ["#1A1B1B", "#484949", "#767676", "#A3A4A4", "#D1D1D1"],
};

const common = {
  black: skiBroColors.slateBlack[0],
  white: skiBroColors.snow[0],
};

const focusedInputOverrideColor = `${skiBroColors.bluebird[0]} !important`;

let theme = createTheme({
  typography: {
    fontFamily: "Poppins, 'Open Sans', sans-serif",
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
    },
    subtitle2: {
      textTransform: "uppercase",
      fontSize: 16,
      fontWeight: 400,
    },
    h1: {
      fontWeight: 700,
      fontSize: 32,
      letterSpacing: 0.6,
    },
    h2: {
      fontSize: 24,
      fontWeight: 700,
      letterSpacing: -0.25,
    },
    h3: {
      fontSize: 22,
      fontWeight: 600,
    },
    h4: {
      fontSize: 18,
      fontWeight: 600,
    },
    h5: {
      fontSize: 16,
      fontWeight: 500,
    },
    h6: {
      fontSize: 12,
      textTransform: "uppercase",
      fontWeight: 700,
      letterSpacing: 1,
    },
    body1: {
      fontFamily: "Poppins, 'Open Sans', sans-serif",
      fontSize: 14,
      fontWeight: 400,
      primary: skiBroColors.slateBlack[1],
      "& a": {
        color: "#66A1D2",
        textDecoration: "none",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    body2: {
      fontFamily: "Poppins, 'Open Sans', sans-serif",
      fontSize: 12,
      fontWeight: 400,
      primary: skiBroColors.slateBlack[1],
      "& a": {
        color: "#66A1D2",
        textDecoration: "none",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    caption: {
      fontFamily: "'Open Sans', 'Work Sans', sans-serif",
      fontSize: 14,
      fontStyle: "italic",
    },
  },
  palette: {
    common,
    primary: {
      light: "#FFFFFF",
      main: "#EE2152",
      // dark: "#D1D1D1",
      contrastText: "#fafafa",
    },
    secondary: {
      light: skiBroColors.bluebird[4],
      main: skiBroColors.bluebird[0],
      dark: skiBroColors.bluebird[1],
      contrastText: skiBroColors.snow[0],
    },
    text: {
      primary: skiBroColors.slateBlack[1],
      secondary: skiBroColors.slateBlack[2],
      disabled: skiBroColors.slateBlack[4],
    },
    info: {
      light: skiBroColors.bluebird[1],
      main: skiBroColors.bluebird[0],
      dark: skiBroColors.prussianBlue[1],
      contrastText: skiBroColors.snow[0],
    },
    action: {
      disabledBackground: skiBroColors.slateBlack[2],
      disabled: skiBroColors.slateBlack[4],
    },
    background: {
      default: skiBroColors.snow[0],
      paper: "#FFF",
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#66A1D2",
          textDecorationColor: "#66A1D2",
          textDecoration: "none",
        },
        underlineHover: {
          color: "#66A1D2",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow:
            " 0px 3px 10px -1px rgb(0 0 0 / 20%), 0px 1px 0px -1px rgb(0 0 0 / 14%), 0px 1px 4px 0px rgb(0 0 0 / 12%)",
        },
        rounded: {
          borderRadius: 16,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          ":last-of-type": {
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16,
          },
          ":first-of-type": {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          },
          borderRadius: 16,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused fieldset": {
            borderColor: focusedInputOverrideColor,
          },
          borderRadius: 16,
          background: "snow",
          fontWeight: 500,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          "&.MuiFilledInput-underline:after": {
            borderBottomColor: focusedInputOverrideColor,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&.MuiInput-underline:after": {
            borderBottomColor: focusedInputOverrideColor,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: focusedInputOverrideColor,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          fontWeight: 500,
          textTransform: "none",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "5px 10px",
          "&:first-child": {
            paddingLeft: 16,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          color: "unset",
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

const ampTheme = {
  googleSignInButton: { backgroundColor: "red", borderColor: "red" },
  signInButtonIcon: { display: "none" },
  sectionHeader: Object.assign({}, theme.typography.subtitle2, theme.typography.h4),
  button: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
  },
  a: {
    color: theme.palette.secondary.main,
  },
  toast: {
    top: "auto",
    bottom: 0,
  },
};

const reservationIndicatorColors = {
  authenticating: "gold",
  awaiting: "#BED7EC",
  amending: "orangered",
  cancelled: "indianred",
  declined: "#484949",
  completed: "darkseagreen",
  confirmed: "royalblue",
};

export default theme;
export { ampTheme, common, reservationIndicatorColors, skiBroColors };
