import { Amplify, Auth, Storage } from "aws-amplify";

import { adminEndpoint, baseUrl, bookingsEndPoint, config, userPoolDomain } from "./settings";

const awsConfig = {
  API: {
    endpoints: [
      {
        name: "Admin API",
        endpoint: adminEndpoint,
        region: config.cognito.REGION,
      },
      {
        name: "Bookings API",
        endpoint: bookingsEndPoint,
        region: config.cognito.REGION,
      },
    ],
  },
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config[process.env.REACT_APP_STAGE || "develop"].USER_POOL_ID,
    identityPoolId: config[process.env.REACT_APP_STAGE || "develop"].IDENTITY_POOL_ID,
    userPoolWebClientId: config[process.env.REACT_APP_STAGE || "develop"].APP_CLIENT_ID,
    authState: "signIn",
    verification: "link",
  },
  Storage: {
    region: config.s3.REGION,
    userPoolId: config[process.env.REACT_APP_STAGE || "develop"].USER_POOL_ID,
    bucket: config[process.env.REACT_APP_STAGE || "develop"].S3BUCKETNAME,
    identityPoolId: config[process.env.REACT_APP_STAGE || "develop"].IDENTITY_POOL_ID,
    level: "public",
  },
  oauth: {
    redirectSignIn: `http://localhost:3005,${baseUrl}`,
    domain: userPoolDomain,
    scope: ["phone", "email", "profile", "openid"],
    redirectSignOut: `http://localhost:3005,${baseUrl}`,
    responseType: "code",
  },
};

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [localRedirectSignIn, productionRedirectSignIn] = awsConfig.oauth.redirectSignIn.split(",");

const [localRedirectSignOut, productionRedirectSignOut] = awsConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  },
};

export const configureAmplify = () => {
  Amplify.configure(updatedAwsConfig);
  Auth.configure(updatedAwsConfig);
};

export const refreshAccessToken = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const { refreshToken } = cognitoUser.getSignInUserSession();
    cognitoUser.refreshSession(refreshToken, () => {
      return;
    });
  } catch (e) {
    console.log("Unable to refresh Token", e);
  }
};

// Configure Storage with S3 bucket information
export const setS3Config = (bucket, level) => {
  Storage.configure({
    bucket: bucket,
    level: level,
    region: config.s3.REGION,
    identityPoolId: config[process.env.REACT_APP_STAGE || "develop"].IDENTITY_POOL_ID,
  });
};
