/* eslint-disable import/no-named-as-default-member */
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import EmojiPeopleOutlinedIcon from "@mui/icons-material/EmojiPeopleOutlined";
import EventIcon from "@mui/icons-material/Event";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import InboxIcon from "@mui/icons-material/InboxOutlined";
import MapIcon from "@mui/icons-material/MapOutlined";
import PlayLesson from "@mui/icons-material/PlayLesson";
import ReviewsIcon from "@mui/icons-material/Reviews";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import { Badge, Box } from "@mui/material";
import i18n from "i18next";
import React, { Suspense } from "react";
import { lazily } from "react-lazily";
import { Navigate, Route, RouteObject, Routes } from "react-router-dom";
import { Dashboard } from "./Dashboard";
import "../lib/i18n";
import { CircularLoader } from "../Components/CircularLoader";
import { PageHeader } from "../Components/PageHeader";
import { AvailabilityProvider } from "../Context/AvailabilityContext";
import { FormProvider } from "../Context/FormContext";
import { InstructorAvailabilityProvider } from "../Context/InstructorAvailabilityContext";
import { useProvider } from "../Context/ProviderContext";
import { ReservationsProvider, useReservations } from "../Context/ReservationsContext";
import { useChat } from "../Context/useChat";

const { InstructorVariants } = lazily(() => import("./InstructorVariants"));
const { Inbox } = lazily(() => import("./Inbox"));
const { CalendarView } = lazily(() => import("./Calendar"));
const { Tutorials } = lazily(() => import("./Tutorials"));
const { SchoolProducts } = lazily(() => import("./SchoolProducts"));
const { SchoolProduct } = lazily(() => import("./SchoolProduct"));
const { Profile } = lazily(() => import("./Profile"));
const { BookingV2 } = lazily(() => import("./BookingV2"));
const { BookingsV2 } = lazily(() => import("./BookingsV2"));
const { Export } = lazily(() => import("./BookingsV2/Export"));
const { CreateSchoolProduct } = lazily(() => import("./CreateSchoolProduct"));
const { MeetingPoints } = lazily(() => import("./MeetingPoints"));
const { ReviewsPage } = lazily(() => import("./Reviews"));
const { GoogleSetup } = lazily(() => import("./GoogleSetup"));

const InboxIconWithBadge: React.FC = () => {
  const { unreadMessages } = useChat();

  return (
    <Badge
      sx={{ "& .MuiBadge-badge": { color: "primary.contrastText", bgcolor: "primary.main" } }}
      badgeContent={unreadMessages}
    >
      <InboxIcon />
    </Badge>
  );
};

const BookingIconWithBadge: React.FC = () => {
  const { pendingReservations } = useReservations();

  return (
    <Badge
      sx={{ "& .MuiBadge-badge": { color: "primary.contrastText", bgcolor: "primary.main" } }}
      badgeContent={pendingReservations}
    >
      <EmojiPeopleOutlinedIcon />
    </Badge>
  );
};

export const routes: (RouteObject & { [x: string]: any })[] = [
  {
    name: i18n.t("Home"),
    menuNav: true,
    tabNav: true,
    icon: <HomeRoundedIcon />,
    exact: true,
    path: "/:providerId/home",
    accountTypes: ["instructor", "school", "guide"],
    props: false,
    element: (
      <ReservationsProvider>
        <Dashboard />
      </ReservationsProvider>
    ),
  },
  {
    name: i18n.t("Calendar"),
    menuNav: true,
    tabNav: true,
    icon: <EventIcon />,
    exact: false,
    path: "/:providerId/calendar",
    accountTypes: ["instructor", "school", "guide"],
    props: false,
    element: (
      <AvailabilityProvider>
        <InstructorAvailabilityProvider>
          <ReservationsProvider>
            <FormProvider>
              <CalendarView />
            </FormProvider>
          </ReservationsProvider>
        </InstructorAvailabilityProvider>
      </AvailabilityProvider>
    ),
  },
  {
    name: i18n.t("Bookings"),
    menuNav: true,
    tabNav: true,
    icon: <BookingIconWithBadge />,
    exact: true,
    path: "/:providerId/bookings",
    props: false,
    element: <BookingsV2 />,
    accountTypes: ["school", "instructor", "guide"],
  },
  {
    name: i18n.t("Booking"),
    menuNav: false,
    tabNav: false,
    icon: <BookingIconWithBadge />,
    exact: true,
    path: "/:providerId/bookings/:reservationId",
    props: false,
    element: <BookingV2 />,
    accountTypes: ["school", "instructor", "guide"],
  },
  {
    name: i18n.t("Profile"),
    menuNav: true,
    tabNav: false,
    icon: <AccountCircleOutlinedIcon />,
    exact: true,
    path: "/:providerId/profile",
    accountTypes: ["school", "instructor", "guide"],
    props: true,
    element: (
      <FormProvider>
        <AvailabilityProvider>
          <InstructorAvailabilityProvider>
            <Profile />
          </InstructorAvailabilityProvider>
        </AvailabilityProvider>
      </FormProvider>
    ),
  },
  {
    name: i18n.t("Products"),
    menuNav: true,
    tabNav: true,
    icon: <ShoppingBasketOutlinedIcon />,
    exact: true,
    path: "/:providerId/products",
    accountTypes: ["school"],
    props: false,
    element: (
      <AvailabilityProvider>
        <FormProvider>
          <SchoolProducts />
        </FormProvider>
      </AvailabilityProvider>
    ),
  },
  {
    name: i18n.t("Meeting Points"),
    menuNav: true,
    tabNav: false,
    icon: <MapIcon />,
    exact: true,
    path: "/:providerId/meeting-points",
    accountTypes: ["school", "instructor", "guide"],
    props: false,
    element: (
      <FormProvider>
        <MeetingPoints />
      </FormProvider>
    ),
  },
  {
    // might seem odd to set count: 2 but it ensures we get the plural form of 'Review'
    name: i18n.t("reviews.reviews", { count: 2 }),
    menuNav: true,
    tabNav: false,
    icon: <ReviewsIcon />,
    exact: true,
    path: "/:providerId/reviews",
    accountTypes: ["school", "instructor", "guide"],
    props: false,
    element: <ReviewsPage />,
  },
  {
    name: i18n.t("Inbox"),
    menuNav: true,
    tabNav: true,
    icon: <InboxIconWithBadge />,
    exact: true,
    path: "/:providerId/inbox",
    accountTypes: ["school", "instructor", "guide"],
    props: false,
    element: (
      <AvailabilityProvider>
        <FormProvider>
          <Inbox />
        </FormProvider>
      </AvailabilityProvider>
    ),
  },
  {
    name: i18n.t("Product Detail"),
    menuNav: false,
    tabNav: false,
    exact: true,
    path: "/:providerId/products/:productId",
    accountTypes: ["school"],
    props: false,
    element: (
      <FormProvider>
        <SchoolProduct />
      </FormProvider>
    ),
  },
  {
    name: i18n.t("createProductPage.New Product"),
    menuNav: false,
    tabNav: false,
    exact: true,
    path: "/:providerId/products/create",
    accountTypes: ["school"],
    props: false,
    element: <CreateSchoolProduct />,
  },
  {
    name: i18n.t("Export"),
    menuNav: false,
    tabNav: false,
    exact: true,
    path: "/:providerId/bookings/export",
    accountTypes: ["school", "instructor", "guide"],
    props: false,
    element: <Export />,
  },
  {
    name: i18n.t("Tutorials"),
    menuNav: true,
    tabNav: false,
    icon: <PlayLesson />,
    exact: true,
    path: "/:providerId/tutorials",
    accountTypes: ["school", "instructor", "guide"],
    props: false,
    element: (
      <FormProvider>
        <Tutorials />
      </FormProvider>
    ),
  },
  {
    name: i18n.t("Google Setup"),
    menuNav: false,
    tabNav: false,
    exact: false,
    path: "/google-setup",
    element: <GoogleSetup />,
    accountTypes: ["instructor", "guide"],
  },
  {
    name: i18n.t("Times & Prices"),
    menuNav: true,
    tabNav: false,
    icon: <SettingsApplicationsIcon />,
    exact: true,
    path: "/:providerId/times-prices",
    accountTypes: ["instructor", "guide"],
    props: false,
    element: (
      <InstructorAvailabilityProvider>
        <InstructorVariants />
      </InstructorAvailabilityProvider>
    ),
  },
];

export const RouteConfig: React.FC = () => {
  const { loading, provider, providerId } = useProvider();
  if (!providerId) return;

  return (
    <Suspense
      fallback={
        <>
          <PageHeader />
          <Box sx={{ position: "relative", height: "90vh" }} maxWidth="lg">
            <CircularLoader transparent visible />
          </Box>
        </>
      }
    >
      <Routes>
        {routes
          .filter((route) =>
            !loading ? route.accountTypes?.indexOf(provider?.providerType?.name?.toLowerCase()) >= 0 : true
          )
          .map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        <Route path="/" element={<Navigate to={`/${providerId}/home`} replace />} />
      </Routes>
    </Suspense>
  );
};
