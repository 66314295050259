import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router";

import RouteChangeGuard from "../Components/RouteChangeGuard";

type FormContextType = {
  dirty: boolean;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
};

const FormContext = createContext<FormContextType>({
  dirty: false,
  setDirty: () => undefined,
});

const useProviderForm = () => {
  const [dirty, setDirty] = useState(false);

  return {
    dirty,
    setDirty,
  };
};

// hook
export const useForm = () => {
  return useContext(FormContext);
};

// provider
export const FormProvider = ({ children }) => {
  const formContext = useProviderForm();
  const navigate = useNavigate();
  return (
    <FormContext.Provider value={formContext}>
      <RouteChangeGuard
        when={formContext.dirty}
        navigate={(path) => navigate(path)}
        shouldBlockNavigation={(_location) => formContext.dirty}
      />
      {children}
    </FormContext.Provider>
  );
};
