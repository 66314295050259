import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";

import React, { ChangeEvent, useState } from "react";
import { TypeAnimation } from "react-type-animation";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import api from "../lib/api";
import { useProvider } from "../Context/ProviderContext";

export const DescriptionGenerator: React.FC<{
  productId;
  formData;
  onGenerateDescription: (newDesc) => void;
  type?: "product" | "instructor" | "guide" | "school" | string;
  optionalIncludes?: ("reviews" | "oldDescription" | string)[] | null;
}> = ({
  onGenerateDescription,
  formData,
  productId,
  type = "product",
  optionalIncludes = ["reviews", "oldDescription"],
}) => {
  const { providerId } = useProvider();
  const { t } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [descriptionStyle, setDescriptionStyle] = useState<string[]>([]);
  const [extraInfo, setExtraInfo] = useState<string>("");
  const [toInclude, setToInclude] = useState<string[]>([]);
  const [length, setLength] = useState<number>(450);
  const [apiResult, setApiResult] = useState<string>("");

  const handleSelectChange = (event: SelectChangeEvent<typeof descriptionStyle>) => {
    const {
      target: { value },
    } = event;
    setDescriptionStyle(typeof value === "string" ? value.split(",") : value);
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setToInclude(
      event.target.checked
        ? [...toInclude, event.target.value]
        : toInclude.filter((item) => item !== event.target.value)
    );
  };

  const descStyles = [
    "fun",
    "exciting",
    "serious",
    "neutral",
    "approachable",
    "playful",
    "adventurous",
    "dynamic",
    "inspiring",
  ];

  const generateDescription = async () => {
    setIsLoading(true);
    const payload: any = {
      keywords: descriptionStyle,
      includes: toInclude,
      extraInfo: extraInfo,
      length: length,
      providerId: providerId,
      productId: productId,
    };
    type === "product" ? (payload.product = formData) : (payload.baseProduct = formData);
    const newDescription = await api.generateDescription(payload, type);
    setApiResult(newDescription[0].message.content);
    setIsLoading(false);
    setDialogOpen(false);
    setPreviewOpen(true);
  };

  const handleClick = async () => {
    setDialogOpen(true);
  };

  return (
    <>
      <Stack direction={"row"} justifyContent={"right"}>
        <Paper
          elevation={1}
          variant="outlined"
          sx={{
            width: isMobile ? "100%" : "auto",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            p: 1,
            borderTop: 0,
          }}
        >
          <Button
            color="secondary"
            startIcon={<EditNoteIcon />}
            endIcon={
              <SvgIcon inheritViewBox color="primary">
                <svg viewBox="0 0 320 320" xmlns="http://www.w3.org/2000/svg">
                  <path d="m297.06 130.97c7.26-21.79 4.76-45.66-6.85-65.48-17.46-30.4-52.56-46.04-86.84-38.68-15.25-17.18-37.16-26.95-60.13-26.81-35.04-.08-66.13 22.48-76.91 55.82-22.51 4.61-41.94 18.7-53.31 38.67-17.59 30.32-13.58 68.54 9.92 94.54-7.26 21.79-4.76 45.66 6.85 65.48 17.46 30.4 52.56 46.04 86.84 38.68 15.24 17.18 37.16 26.95 60.13 26.8 35.06.09 66.16-22.49 76.94-55.86 22.51-4.61 41.94-18.7 53.31-38.67 17.57-30.32 13.55-68.51-9.94-94.51zm-120.28 168.11c-14.03.02-27.62-4.89-38.39-13.88.49-.26 1.34-.73 1.89-1.07l63.72-36.8c3.26-1.85 5.26-5.32 5.24-9.07v-89.83l26.93 15.55c.29.14.48.42.52.74v74.39c-.04 33.08-26.83 59.9-59.91 59.97zm-128.84-55.03c-7.03-12.14-9.56-26.37-7.15-40.18.47.28 1.3.79 1.89 1.13l63.72 36.8c3.23 1.89 7.23 1.89 10.47 0l77.79-44.92v31.1c.02.32-.13.63-.38.83l-64.41 37.19c-28.69 16.52-65.33 6.7-81.92-21.95zm-16.77-139.09c7-12.16 18.05-21.46 31.21-26.29 0 .55-.03 1.52-.03 2.2v73.61c-.02 3.74 1.98 7.21 5.23 9.06l77.79 44.91-26.93 15.55c-.27.18-.61.21-.91.08l-64.42-37.22c-28.63-16.58-38.45-53.21-21.95-81.89zm221.26 51.49-77.79-44.92 26.93-15.54c.27-.18.61-.21.91-.08l64.42 37.19c28.68 16.57 38.51 53.26 21.94 81.94-7.01 12.14-18.05 21.44-31.2 26.28v-75.81c.03-3.74-1.96-7.2-5.2-9.06zm26.8-40.34c-.47-.29-1.3-.79-1.89-1.13l-63.72-36.8c-3.23-1.89-7.23-1.89-10.47 0l-77.79 44.92v-31.1c-.02-.32.13-.63.38-.83l64.41-37.16c28.69-16.55 65.37-6.7 81.91 22 6.99 12.12 9.52 26.31 7.15 40.1zm-168.51 55.43-26.94-15.55c-.29-.14-.48-.42-.52-.74v-74.39c.02-33.12 26.89-59.96 60.01-59.94 14.01 0 27.57 4.92 38.34 13.88-.49.26-1.33.73-1.89 1.07l-63.72 36.8c-3.26 1.85-5.26 5.31-5.24 9.06l-.04 89.79zm14.63-31.54 34.65-20.01 34.65 20v40.01l-34.65 20-34.65-20z" />
                </svg>
              </SvgIcon>
            }
            size="small"
            onClick={handleClick}
          >
            <Typography sx={{ whiteSpace: "pre-wrap" }}>{t("Generate description ")}</Typography>
            <Typography sx={{ whiteSpace: "pre-wrap", fontSize: isMobile ? 10 : 12 }} color="primary">
              {t(" Powered by OpenAI")}
            </Typography>
          </Button>
        </Paper>
      </Stack>
      <Dialog open={dialogOpen} fullScreen={isMobile}>
        <DialogTitle>{t("Customize your description")}</DialogTitle>
        <DialogContent>
          <DialogContentText gutterBottom>
            {t(
              "These are all completely optional but the more information you fill in (including the other product details), the more customised your description will be."
            )}
          </DialogContentText>
          <FormControl sx={{ my: 2 }} fullWidth>
            <InputLabel id="demo-multiple-chip-label">{t("Select description style")}</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={descriptionStyle}
              onChange={handleSelectChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {descStyles.map((style) => (
                <MenuItem key={style} value={style}>
                  {style}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{t("Select up to 3")}</FormHelperText>
          </FormControl>
          <Grid container direction="row" justifyContent="space-between" spacing={3}>
            {optionalIncludes.length ? (
              <Grid item>
                <FormGroup>
                  <FormLabel>{t("Include information from")}</FormLabel>
                  {optionalIncludes.includes("reviews") && (
                    <FormControlLabel
                      control={<Checkbox value="reviews" onChange={handleCheckboxChange} />}
                      label="Reviews"
                    />
                  )}
                  {optionalIncludes.includes("oldDescription") && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={formData.description === ""}
                          value="oldDescription"
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Existing description"
                    />
                  )}
                </FormGroup>
              </Grid>
            ) : null}
            <Grid item>
              <FormGroup>
                <FormLabel>{t("Select description length")}</FormLabel>
                <RadioGroup
                  defaultValue={450}
                  onChange={(event: ChangeEvent, value: string) => {
                    setLength(Number(value));
                  }}
                >
                  <FormControlLabel value={150} control={<Radio />} label={t("Brief (roughly 200 words)")} />
                  <FormControlLabel value={300} control={<Radio />} label={t("Standard (roughly 300 words)")} />
                </RadioGroup>
              </FormGroup>
            </Grid>
          </Grid>
          <TextField
            autoFocus
            margin="dense"
            id="extraInfo"
            label="Extra Information"
            type="text"
            fullWidth
            variant="standard"
            multiline
            rows="3"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setExtraInfo(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>{t("Cancel")}</Button>
          <Button disabled={isLoading} onClick={() => generateDescription()}>
            {isLoading ? <CircularProgress size={18} /> : t("Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={previewOpen} fullScreen={isMobile}>
        <DialogTitle>{t("Description preview")}</DialogTitle>
        <DialogContent>
          <DialogContentText whiteSpace="pre-wrap">
            <TypeAnimation
              sequence={[apiResult.replace("</p>", "\n").replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, "")]}
              speed={90}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPreviewOpen(false);
              setDialogOpen(true);
            }}
          >
            {t("Cancel")}
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => {
              onGenerateDescription(apiResult);
              setPreviewOpen(false);
            }}
          >
            {t("Use description")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
