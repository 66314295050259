import { Reservation, User } from "@skibro/types";
import { createContext, useContext, useEffect, useState } from "react";
import Talk from "talkjs";
import { ConversationBuilder, Session } from "talkjs/all";

import { useProvider } from "./ProviderContext";

import envConfig from "../config/settings";

type ChatType = {
  startChat: (customer: User, reservationId: Reservation["id"]) => Promise<ConversationBuilder>;
  session: null | Session;
  unreadMessages: number | null;
};

const ChatContext = createContext<ChatType>({
  startChat: () => undefined,
  session: null,
  unreadMessages: null,
});

const useProviderChat = () => {
  const { provider } = useProvider();

  const [me, setMe] = useState<any>();
  const [session, setSession] = useState<Session>((window as any).talkSession);
  const [unreadMessages, setUnreadMessages] = useState<number | null>(null);

  useEffect(() => {
    if (!provider) return;
    let photoUrl;
    if (provider.media?.logo || provider.media?.portrait) {
      if (provider.providerType.name === "School") {
        photoUrl = provider.media.logo?.includes("https")
          ? provider.media.logo
          : `${envConfig.BASE_URL}${provider.media.logo}`;
      } else {
        photoUrl = provider.media.portrait?.includes("https")
          ? provider.media.portrait
          : `${envConfig.BASE_URL}${provider.media.portrait}`;
      }
    }
    Talk.ready.then(() => {
      const me = new Talk.User({
        id: provider.id,
        name: provider.name || "SkiBro",
        email: provider.contact.bookingsEmail,
        photoUrl,
        role: "Provider",
        locale: provider.contact?.language || "en",
      });
      setMe(me);
      (window as any).talkSession = new Talk.Session({
        appId: envConfig.chatAppId,
        me: me,
        signature: provider.chatToken,
      });
      (window as any).talkSession?.unreads.on("change", (conversationIds) => {
        setUnreadMessages(conversationIds.length);
      });

      setSession((window as any).talkSession);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  const startChat = async (customer: User, reservationId: Reservation["id"]) => {
    if (!provider?.messengerActive) return;
    const other = new Talk.User({
      id: customer.id,
      name: customer.firstName || customer.name,
      email: customer.email,
      role: "Customer",
    });

    if (!session) {
      const newSession = new Talk.Session({
        appId: envConfig.chatAppId,
        me: me,
        signature: provider.chatToken,
      });
      await setSession(newSession);
    }

    const conversation = await session.getOrCreateConversation(`${customer.id}${provider.id}${reservationId}`);
    conversation.setParticipant(me);
    conversation.setParticipant(other);
    conversation.subject = `Reservation ${reservationId}`;
    return conversation;
  };

  return {
    startChat,
    session,
    unreadMessages,
  };
};

// hook
export const useChat = () => {
  return useContext(ChatContext);
};

// provider
export const ChatProvider = ({ children }) => {
  const chat = useProviderChat();
  return <ChatContext.Provider value={chat}>{children}</ChatContext.Provider>;
};
