import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExitToApp from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SxProps,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { ProviderPicker } from "./ProviderPicker";

import { useProvider } from "../Context/ProviderContext";
import { useSideBar } from "../Context/SideBarContext";
import { useUser } from "../Context/UserContext";
import { useWidth } from "../lib/hooks";
import { ProfileAvatar } from "../Views/Profile/Details";
import { routes } from "../Views/routes";
import SessionTimeoutModal from "./SessionTimeoutModal";

const Nav: React.FC = () => {
  const auth = useUser();
  const { provider, providerId } = useProvider();
  const { t } = useTranslation();
  const { signOut } = useUser();
  const width = useWidth();
  const { isOpen, toggleSideBar } = useSideBar();
  const { pathname } = useLocation();

  const isDesktop = !["xs", "sm"].includes(width);

  return (
    <Drawer
      sx={styles.drawer}
      variant={isDesktop ? "permanent" : "persistent"}
      anchor="left"
      open={isOpen}
      onClose={() => toggleSideBar()}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <Grid
        sx={{ overflowX: "hidden", overflowY: "scroll" }}
        container
        direction="column"
        justifyContent="space-between"
      >
        <Grid container item direction="column">
          <Grid item>
            <Box sx={styles.drawerHeader}>
              <Hidden mdDown>
                <ProfileAvatar provider={provider} />
              </Hidden>
              <IconButton sx={{ display: { md: "none" } }} onClick={() => toggleSideBar()}>
                <ChevronLeftIcon />
              </IconButton>
            </Box>
          </Grid>

          <Grid item>
            <Divider />
          </Grid>

          <Grid item>
            <List>
              {routes
                .filter((route) => route.menuNav)
                .filter((route) => (provider?.messengerActive && route.path === "/inbox") || route.path !== "/inbox")
                .filter((route) => route.accountTypes.indexOf(auth.accountType) >= 0)
                .map((route) => (
                  <ListItem
                    button
                    color="secondary"
                    to={`${route.path.replace(":providerId", `${providerId}`)}`}
                    onClick={() => toggleSideBar()}
                    component={Link}
                    key={route.path}
                    selected={pathname.includes(route.path)}
                  >
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText color="inherit" primary={route.name} />
                  </ListItem>
                ))}
              <ListItem sx={{ display: { md: "none" } }}>
                <ProviderPicker />
              </ListItem>
            </List>
          </Grid>
          <Grid item>
            <Toolbar sx={{ marginBottom: (theme) => theme.spacing(3) }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => toggleSideBar()}
                sx={{ mr: 2, display: isOpen || isDesktop ? "none" : "inline-flex" }}
                size="large"
              >
                <MenuIcon />
              </IconButton>

              <Typography align="left" variant="subtitle2" color="inherit">
                {`SkiBro ${t("providerPortal")}`}
              </Typography>
              <IconButton color="inherit" aria-label="Logout" onClick={async () => await signOut()} size="large">
                <ExitToApp />
              </IconButton>
            </Toolbar>
          </Grid>
        </Grid>
      </Grid>
      <SessionTimeoutModal signOut={signOut} />
    </Drawer>
  );
};

const drawerWidth = 240;
const styles: { [x: string]: SxProps } = {
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    px: 1,
    py: 0,
    justifyContent: { md: "center", xs: "flex-end" },
  },
};

export default Nav;
