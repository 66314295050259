import Close from "@mui/icons-material/Close";
import { Box, Hidden, IconButton, Snackbar } from "@mui/material";
import React, { useEffect } from "react";

import { RouteConfig } from "./routes";

import Nav from "../Components/Nav";
import { TabBar } from "../Components/TabBar";
import { useAdminWebSocket } from "../Context/WebsocketContext";
import { CircularLoader } from "../Components/CircularLoader";
import { useUser } from "../Context/UserContext";

const AppContent: React.FC = () => {
  const [messageOpen, setMessageOpen] = React.useState<boolean>(false);
  const { loading: accountLoading } = useUser();
  const { message, clientData } = useAdminWebSocket();
  useEffect(() => {
    if (message?.length > 0) setMessageOpen(true);
  }, [message, clientData]);
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        padding: 0,
        height: "100vh",
        pb: { xs: 7, sm: 0 },
        pl: { md: 30 },
      }}
    >
      <Nav />
      <Hidden smUp>
        <TabBar />
      </Hidden>

      <Box display="flex" flexDirection="column" flexGrow="1">
        {accountLoading ? <CircularLoader visible /> : <RouteConfig />}
        <Snackbar
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          onClose={() => setMessageOpen(false)}
          open={messageOpen}
          message={message}
          autoHideDuration={10000}
          action={
            <IconButton color="info" size="small" onClick={() => setMessageOpen(false)}>
              <Close />
            </IconButton>
          }
        />
      </Box>
    </Box>
  );
};
export default AppContent;
