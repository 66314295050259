import { Grid, SxProps, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import React from "react";

const OfferedDayTimeCompared = ({ dayTime, change }) => {
  const atLeastOneTimeChange = (from, to): boolean =>
    from.date !== to.date || from.start !== to.start || from.duration !== to.duration;

  return (
    <Grid container xs={12} md={10} key={`dayTime-${dayTime}`} sx={styles.bookingDetailsSingle}>
      {change.from && change.to && atLeastOneTimeChange(change.from, change.to) && (
        <>
          <Grid item xs={6} sm={4}>
            <Typography
              variant="body1"
              align="left"
              color="textSecondary"
              component="p"
              sx={styles.changedDetailValueLineThrough}
              display="inline"
              gutterBottom={false}
            >
              {format(parseISO(change.from.date), "yyyy/MM/dd")}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Typography
              variant="body1"
              align="left"
              color="textSecondary"
              component="p"
              sx={styles.changedDetailValueLineThrough}
              display="inline"
              gutterBottom={false}
            >
              {` ${Math.floor(change.from.start / 60)}:${
                change.from.start % 60 < 10 ? "0" + (change.from.start % 60) : change.from.start % 60
              } `}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Typography
              variant="body1"
              align="left"
              color="textSecondary"
              component="p"
              sx={styles.changedDetailValueLineThrough}
              display="inline"
              gutterBottom={false}
            >
              {`${Math.floor((change.from.start + change.from.duration) / 60)}:${
                (change.from.start + change.from.duration) % 60 < 10
                  ? "0" + ((change.from.start + change.from.duration) % 60)
                  : (change.from.start + change.from.duration) % 60
              }`}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography
              variant="body1"
              align="left"
              color="textSecondary"
              component="p"
              sx={change.from.date !== change.to.date ? styles.changedDetailValue : styles.unchangedDetailValue}
              display="inline"
              gutterBottom={false}
            >
              {format(parseISO(change.to.date), "yyyy/MM/dd")}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Typography
              variant="body1"
              align="left"
              color="textSecondary"
              component="p"
              sx={change.from.start !== change.to.start ? styles.changedDetailValue : styles.unchangedDetailValue}
              display="inline"
              gutterBottom={false}
            >
              {` ${Math.floor(change.to.start / 60)}:${
                change.to.start % 60 < 10 ? "0" + (change.to.start % 60) : change.to.start % 60
              } `}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Typography
              variant="body1"
              align="left"
              color="textSecondary"
              component="p"
              sx={
                change.from.duration !== change.to.duration || change.from.start !== change.to.start
                  ? styles.changedDetailValue
                  : styles.unchangedDetailValue
              }
              display="inline"
              gutterBottom={false}
            >
              {`${Math.floor((change.to.start + change.to.duration) / 60)}:${
                (change.to.start + change.to.duration) % 60 < 10
                  ? "0" + ((change.to.start + change.to.duration) % 60)
                  : (change.to.start + change.to.duration) % 60
              }`}
            </Typography>
          </Grid>
        </>
      )}
      {change.from && change.to && !atLeastOneTimeChange(change.from, change.to) && (
        <>
          <Grid item xs={6} sm={4}>
            <Typography
              variant="body1"
              align="left"
              color="textSecondary"
              component="p"
              sx={styles.unchangedDetailValue}
              display="inline"
              gutterBottom={false}
            >
              {format(parseISO(change.from.date), "yyyy/MM/dd")}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Typography
              variant="body1"
              align="left"
              color="textSecondary"
              component="p"
              sx={styles.unchangedDetailValue}
              display="inline"
              gutterBottom={false}
            >
              {` ${Math.floor(change.from.start / 60)}:${
                change.from.start % 60 < 10 ? "0" + (change.from.start % 60) : change.from.start % 60
              } `}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Typography
              variant="body1"
              align="left"
              color="textSecondary"
              component="p"
              sx={styles.unchangedDetailValue}
              display="inline"
              gutterBottom={false}
            >
              {`${Math.floor((change.from.start + change.from.duration) / 60)}:${
                (change.from.start + change.from.duration) % 60 < 10
                  ? "0" + ((change.from.start + change.from.duration) % 60)
                  : (change.from.start + change.from.duration) % 60
              }`}
            </Typography>
          </Grid>
        </>
      )}
      {change.from && !change.to && (
        <>
          <Grid item xs={6} sm={4}>
            <Typography
              variant="body1"
              align="left"
              color="textSecondary"
              component="p"
              sx={styles.changedDetailValueLineThrough}
              display="inline"
              gutterBottom={false}
            >
              {format(parseISO(change.from.date), "yyyy/MM/dd")}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Typography
              variant="body1"
              align="left"
              color="textSecondary"
              component="p"
              sx={styles.changedDetailValueLineThrough}
              display="inline"
              gutterBottom={false}
            >
              {` ${Math.floor(change.from.start / 60)}:${
                change.from.start % 60 < 10 ? "0" + (change.from.start % 60) : change.from.start % 60
              } `}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Typography
              variant="body1"
              align="left"
              color="textSecondary"
              component="p"
              sx={styles.changedDetailValueLineThrough}
              display="inline"
              gutterBottom={false}
            >
              {`${Math.floor((change.from.start + change.from.duration) / 60)}:${
                (change.from.start + change.from.duration) % 60 < 10
                  ? "0" + ((change.from.start + change.from.duration) % 60)
                  : (change.from.start + change.from.duration) % 60
              }`}
            </Typography>
          </Grid>
        </>
      )}
      {!change.from && change.to && (
        <>
          <Grid item xs={6} sm={4}>
            <Typography
              variant="body1"
              align="left"
              color="textSecondary"
              component="p"
              sx={styles.changedDetailValue}
              display="inline"
              gutterBottom={false}
            >
              {format(parseISO(change.to.date), "yyyy/MM/dd")}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Typography
              variant="body1"
              align="left"
              color="textSecondary"
              component="p"
              sx={styles.changedDetailValue}
              display="inline"
              gutterBottom={false}
            >
              {` ${Math.floor(change.to.start / 60)}:${
                change.to.start % 60 < 10 ? "0" + (change.to.start % 60) : change.to.start % 60
              } `}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Typography
              variant="body1"
              align="left"
              color="textSecondary"
              component="p"
              sx={styles.changedDetailValue}
              display="inline"
              gutterBottom={false}
            >
              {`${Math.floor((change.to.start + change.to.duration) / 60)}:${
                (change.to.start + change.to.duration) % 60 < 10
                  ? "0" + ((change.to.start + change.to.duration) % 60)
                  : (change.to.start + change.to.duration) % 60
              }`}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const styles: { [x: string]: SxProps } = {
  blockStyleMimic: {
    pt: 0.5,
  },
  changedDetailValueLineThrough: {
    m: 0.5,
    textDecoration: "line-through",
  },
  changedDetailValue: {
    m: 0.5,
    color: "primary.dark",
  },
  unchangedDetailValue: {
    m: 0.5,
  },
  bookingDetailsSingle: {
    pl: {
      xs: 0,
      md: 2.5,
    },
  },
};

export { OfferedDayTimeCompared };
