import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Greeting = () => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="center">
      <Grid
        item
        xs={10}
        sx={{
          textAlign: "center",
        }}
      >
        <Typography variant="body1">
          {t(
            "Welcome to the provider portal for instructors and schools that work in partnership with SkiBro, please log in to adjust your season dates, pricing, products, and more!"
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Greeting;
