import { AppBar, BottomNavigation, BottomNavigationAction, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useUser } from "../Context/UserContext";
import { routes } from "../Views/routes";

export const TabBar: React.FC = function TabBar() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const auth = useUser();
  const tabs = routes.filter((route) => route.tabNav).filter((route) => route.accountTypes.includes(auth.accountType));
  const initialTab = tabs.findIndex((tab) => tab.path.includes(pathname));
  const [tab, setTab] = useState(initialTab);

  useEffect(() => {
    setTab(tabs.findIndex((tab) => tab.path.includes(pathname)));
  }, [tabs, pathname]);

  return (
    <AppBar
      color="inherit"
      position="fixed"
      sx={{
        top: "auto",
        bottom: 0,
      }}
    >
      <Toolbar disableGutters>
        <BottomNavigation
          showLabels={true} // May need to disable this if we increase from 4 to 5 icons (5 is maximum https://material-ui.com/components/bottom-navigation/)
          sx={{
            width: "100%",
          }}
          value={tab !== -1 && tab}
          onChange={(_e, newTab) => {
            setTab(newTab);
            navigate(tabs[newTab].path);
          }}
        >
          {tabs.map((tab) => (
            <BottomNavigationAction
              color="primary"
              sx={{
                color: "primary.main",
                "&.Mui-selected": {
                  color: "primary.dark",
                },
              }}
              label={tab.name}
              icon={tab.icon}
              key={tab.name}
            />
          ))}
        </BottomNavigation>
      </Toolbar>
    </AppBar>
  );
};
