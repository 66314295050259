import AccessTime from "@mui/icons-material/AccessTime";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Grid, IconButton } from "@mui/material";
import { DatePicker, MobileTimePicker } from "@mui/x-date-pickers-pro";
import { Reservation, ReservationDayTime } from "@skibro/types";
import { format, getHours, getMinutes, setHours, setMinutes } from "date-fns";

import React from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const DayTimeSelector: React.FC<{
  dayTime: ReservationDayTime & { day?: number };
  index: number;
  control: Control<any, any>;
  fieldStub?: string;
  onChange: (index: number, dayTime: Reservation["daysTimes"][0]) => void;
  onDelete: (index: number) => void;
}> = ({ dayTime, index, control, fieldStub = "daysTimes", onChange, onDelete }) => {
  const { t } = useTranslation();
  return (
    <Box key={`${dayTime.date}-${index}`}>
      <Grid container direction="row" spacing={1} key={`${dayTime.date}-${index}`}>
        <Grid item xs={5}>
          <Controller
            name={`${fieldStub}.${index}.date`}
            control={control}
            defaultValue={dayTime.date}
            render={({ fieldState }) => (
              <DatePicker
                label={t("Date")}
                value={new Date(dayTime.date)}
                onChange={(newDate: Date) => {
                  dayTime.date = `${format(newDate, "yyyy-MM-dd")}`;
                  dayTime.day = newDate.getDay();
                  onChange(index, dayTime);
                }}
                slotProps={{
                  textField: {
                    helperText: fieldState.error?.message,
                    error: !!fieldState.error?.message,
                    fullWidth: true,
                    type: "datetime",
                    InputLabelProps: {
                      shrink: !!dayTime.date,
                    },
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name={`${fieldStub}.${index}.start`}
            control={control}
            defaultValue={dayTime.start}
            render={({ fieldState }) => (
              <MobileTimePicker
                label={t("From")}
                ampm={false}
                value={setMinutes(setHours(new Date(), Math.floor(dayTime.start / 60)), dayTime.start % 60)}
                onAccept={(newTime) => {
                  dayTime.start = getHours(newTime) * 60 + getMinutes(newTime);
                  onChange(index, dayTime);
                }}
                slotProps={{
                  textField: {
                    helperText: fieldState.error?.message,
                    error: !!fieldState.error?.message,
                    InputProps: {
                      endAdornment: (
                        <IconButton edge="end">
                          <AccessTime />
                        </IconButton>
                      ),
                    },
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name={`${fieldStub}.${index}.duration`}
            control={control}
            defaultValue={dayTime.duration}
            render={({ fieldState }) => (
              <MobileTimePicker
                label={t("To")}
                ampm={false}
                minTime={new Date(0, 0, 0, Math.floor(dayTime.start / 60), dayTime.start % 60)}
                maxTime={new Date(0, 0, 0, 23, 59)}
                value={setMinutes(
                  setHours(new Date(), Math.floor((dayTime.start + dayTime.duration) / 60)),
                  (dayTime.start + dayTime.duration) % 60 || 0
                )}
                onAccept={(newTime) => {
                  dayTime.duration = getHours(newTime) * 60 + getMinutes(newTime) - dayTime.start;
                  onChange(index, dayTime);
                }}
                slotProps={{
                  textField: {
                    helperText: fieldState.error?.message,
                    error: !!fieldState.error?.message,
                    InputProps: {
                      endAdornment: (
                        <IconButton edge="end">
                          <AccessTime />
                        </IconButton>
                      ),
                    },
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={() => onDelete(index)} size="large">
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};
