import { memo } from "react";
import DraggableListItem from "./DraggableListItem";
import { DragDropContext, Droppable, OnDragEndResponder } from "@hello-pangea/dnd";
import { List } from "@mui/material";

export type DraggableListProps = {
  items: { id: string; primary?: string; secondary?: string }[];
  onDragEnd: OnDragEndResponder;
  onDelete?: (id: string) => void;
  onSave?: (id: string, text: string) => void;
};

const DraggableList = memo(({ items, onDragEnd, onDelete, onSave }: DraggableListProps) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <List ref={provided.innerRef} {...provided.droppableProps}>
            {items?.map((item, index) => (
              <DraggableListItem item={item} index={index} key={item.id} onDelete={onDelete} onSave={onSave} />
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
});

export default DraggableList;
