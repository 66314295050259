import TodayRoundedIcon from "@mui/icons-material/TodayRounded";
import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { Reservation } from "@skibro/types";
import { addDays, addMinutes, eachDayOfInterval, format, isSameDay } from "date-fns/esm";
import { t } from "i18next";
import React, { useEffect, useState } from "react";

interface Props {
  reservations: Reservation[];
  handleEventClick: (reservation: Reservation) => void;
}

interface Schedule {
  date: string;
  events: ScheduleEvent[];
}

interface ScheduleEvent {
  ubr: string;
  start: number;
  end: number;
  resort: string;
  pointOfContactName: string;
  reservation: Reservation;
}

export const UpcomingSchedule: React.FC<Props> = ({ reservations, handleEventClick }) => {
  const [daySchedules, setDaySchedules] = useState<Schedule[]>();

  useEffect(() => {
    if (!reservations?.length) return;

    const daySchedules = createDaySchedules();

    setDaySchedules(daySchedules);
  }, [reservations]);

  const createDaySchedules = (): Schedule[] => {
    const startDate = new Date();
    const endDate = addDays(startDate, 7);
    const weekRange = eachDayOfInterval({ start: startDate, end: endDate });

    const daySchedules = weekRange.map((date, index) => {
      const matchingReservations = reservations.filter(
        (res) => res.daysTimes.some((dT) => isSameDay(new Date(dT.date), date)) && res.status === "CONFIRMED"
      );

      const scheduleEvents = matchingReservations.map((res) => createDayEvents(date, res));

      return {
        date: index === 0 ? "Today" : format(date, "EEEE do"),
        events: scheduleEvents.sort((a, b) => (a.start > b.start ? 1 : -1)),
      };
    });

    return daySchedules;
  };

  const createDayEvents = (date: Date, reservation: Reservation): ScheduleEvent => {
    const dayTime = reservation.daysTimes.find((dT) => isSameDay(new Date(dT.date), date));

    return {
      ubr: reservation?.booking?.uniqueBookingReference,
      start: dayTime.start,
      end: dayTime.start + dayTime.duration,
      resort: reservation?.resort?.name,
      pointOfContactName: reservation?.booking?.partyDetails?.fullName,
      reservation,
    };
  };

  return (
    <>
      <Typography variant="h3" gutterBottom>
        {t<string>("Upcoming schedule")}
      </Typography>

      <List disablePadding>
        {daySchedules?.length &&
          daySchedules.map((schedule, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <ListItemText primary={<Typography sx={{ fontWeight: 600 }}>{schedule.date}</Typography>} />
              </ListItem>

              <List dense>
                {schedule.events.length ? (
                  schedule.events.map((event, index) => (
                    <ListItem
                      key={index}
                      onClick={() => handleEventClick(event.reservation)}
                      sx={{ cursor: "pointer" }}
                    >
                      <ListItemAvatar sx={{ transform: "scale(0.7)" }}>
                        <Avatar>
                          <TodayRoundedIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText
                        primary={
                          event.reservation.source === "GOOGLE"
                            ? "External Reservation"
                            : `${event.ubr} - ${event.pointOfContactName}`
                        }
                        secondary={`${format(
                          addMinutes(new Date().setHours(0, 0, 0, 0), event.start),
                          "HH:mm"
                        )} to ${format(addMinutes(new Date().setHours(0, 0, 0, 0), event.end), "HH:mm")}${
                          event.reservation.source === "GOOGLE" ? "" : `, ${event.resort}`
                        }`}
                      />
                    </ListItem>
                  ))
                ) : (
                  <ListItem key={index}>
                    <ListItemText secondary={t<string>("No events on this date")} />
                  </ListItem>
                )}
              </List>
              <Divider />
            </React.Fragment>
          ))}
      </List>
    </>
  );
};
