import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import { Alert, Button, FormControl, InputAdornment, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { addDays, format, max, min, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm, Field } from "react-hook-form";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

import { DayTimeSelector } from "../../Components/Forms/DayTimeSelector";
import { ParticipantSelector } from "../../Components/Forms/ParticipantSelector";
import { BaseActivity, Participant, Reservation, ReservationDayTime } from "@skibro/types";
import { useContent } from "../../Context/ContentContext";
import { useReservations } from "../../Context/ReservationsContext";
import { MeetingPointSelector } from "../../Components/Forms/MeetingPointSelector";
import { useProvider } from "../../Context/ProviderContext";
import { isMobile } from "react-device-detect";

interface Props {
  reservation: Reservation | any;
  closeDialog: () => void;
  submit: (reservation: Reservation | any, amendmentReason: string) => void;
}

export const AmendmentForm: React.FC<Props> = ({ reservation, closeDialog, submit }) => {
  const { currencies, activities, resorts } = useContent();
  const { meetingPoints, loadMeetingPoints, providerId } = useProvider();
  const { isLoading } = useReservations();
  const { t } = useTranslation();

  const [confirmationToggle, setConfirmationToggle] = useState<boolean>(false);
  const [updatingPrice, setUpdatingPrice] = useState<boolean>(false);

  useEffect(() => {
    loadMeetingPoints(providerId);
  }, [providerId]);

  useEffect(() => {
    if (!Object.keys(reservation).length || !activities?.length) return;

    reservation.meetingPoint = { ...reservation.meetingPoint, resort: reservation.resort };

    reset({
      startDate: format(parseISO(`${reservation.startDate}`), "yyyy-MM-dd"),
      endDate: format(parseISO(`${reservation.endDate}`), "yyyy-MM-dd"),
      productValue: reservation.productValue as number,
      daysTimes: reservation.daysTimes,
      participants: reservation.participants,
      meetingPoint: reservation.meetingPoint,
      activity: activities.find((a) => a.id === reservation.activity.id),
      amendmentReason: "",
    });
  }, [reservation, activities]);

  const validationSchema = yup.object().shape({
    startDate: yup.string(),
    endDate: yup.string(),
    productValue: yup
      .number()
      .min(1, t("Please enter a price"))
      .typeError(t("Please enter a price"))
      .test("decimal", "Price must have two decimal places", (value) => /^\d+(\.\d{1,2})?$/.test(value.toString()))
      .required(),
    daysTimes: yup.array().of(
      yup.object().shape({
        date: yup.string().required(),
        start: yup.number().required(t("Please enter a start time")),
        duration: yup
          .number()
          .positive(t("Please ensure end time is after start time"))
          .required(t("Please enter an end time")),
      })
    ),
    participants: yup.array().of(
      yup.object().shape({
        firstName: yup.string().required("Please enter a first name"),
        lastName: yup.string().required(t("Please enter a last name")),
        age: yup.number().typeError(t("Please enter an age")).required(t("Please enter an age")),
        reservationLevels: yup.array().of(
          yup.object().shape({
            reservationId: yup.number(),
            baseLevel: yup
              .object()
              .shape({
                id: yup.number().required(t("Please enter a level")),
                name: yup.string().required(t("Please enter a level")),
              })
              .nullable()
              .required(t("Please enter a level")),
          })
        ),
      })
    ),
    activity: yup.object().shape({
      id: yup.number().required(t("Please select an activity")),
      name: yup.string().required(t("Please select an activity")),
    }),
    amendmentReason: yup.string().required(t("Please enter a reason for the offer")),
  });

  const { handleSubmit, watch, control, setValue, reset } = useForm({
    defaultValues: {
      startDate: format(parseISO(`${reservation.startDate}`), "yyyy-MM-dd"),
      endDate: format(parseISO(`${reservation.endDate}`), "yyyy-MM-dd"),
      productValue: reservation.productValue as number,
      daysTimes: reservation.daysTimes,
      participants: reservation.participants,
      activity: reservation.activity,
      meetingPoint: reservation.meetingPoint,
      amendmentReason: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const watchedActivity: BaseActivity = watch("activity");
  const watchedParticipants: Participant[] = watch("participants");
  const meetingPoint = watch("meetingPoint", { description: "", lat: undefined, long: undefined });

  const {
    fields: daysTimes,
    remove: removeDayTime,
    append: appendDayTime,
    update: updateDayTime,
    replace: replaceDaysTimes,
  } = useFieldArray({
    control,
    name: "daysTimes",
    keyName: "key",
  });

  const {
    fields: participants,
    append: appendParticipant,
    remove: removeParticipant,
    update: updateParticipant,
    replace: replaceParticipants,
  } = useFieldArray({
    control,
    name: "participants",
    keyName: "key",
  });

  const handleUpdateDayTime = (index: number, dayTime: ReservationDayTime): void => {
    updateDayTime(index, dayTime);
    const sortedFields = sortByDate(daysTimes as any);
    replaceDaysTimes(sortedFields);

    updateStartAndEndDate();
  };

  const handleRemoveDayTime = (dayTimeIndex: number): void => {
    if (daysTimes.length === 1) return;
    removeDayTime(dayTimeIndex);
    updateStartAndEndDate();
  };

  const handleDayTimeAdd = (): void => {
    const lastDate = daysTimes[daysTimes.length - 1] as any;

    let newDate;

    if (lastDate) {
      newDate = {
        date: format(addDays(new Date(lastDate.date), 1), "yyyy-MM-dd"),
        start: lastDate.start,
        duration: lastDate.duration,
      };
    } else {
      newDate = {
        date: format(new Date(), "yyyy-MM-dd"),
        start: 540,
        duration: 180,
      };
    }

    appendDayTime(newDate);

    updateStartAndEndDate();
  };

  const handleAddParticipant = (): void => {
    appendParticipant({
      id: uuidv4(),
      firstName: "",
      lastName: "",
      age: null,
      language: "",
      reservationLevels: [
        {
          reservationId: reservation.id,
          baseLevel: {},
        },
      ],
    });
  };

  const handleRemoveParticipant = (index: number): void => {
    if (participants.length === 1) return;
    removeParticipant(index);
  };

  const updateStartAndEndDate = (): void => {
    const newStart = format(
      min(daysTimes.map((day) => parseISO(new Date((day as any).date).toISOString()))),
      "yyyy-MM-dd"
    );
    const newEnd = format(
      max(daysTimes.map((day) => parseISO(new Date((day as any).date).toISOString()))),
      "yyyy-MM-dd"
    );

    setValue("startDate", newStart);
    setValue("endDate", newEnd);
  };

  const sortByDate = (daysTimes: ReservationDayTime[]): ReservationDayTime[] => {
    return daysTimes.sort((a: any, b: any) => {
      if (parseISO(a.date.slice(0, 10)) > parseISO(b.date.slice(0, 10))) {
        return 1;
      }
      if (parseISO(a.date.slice(0, 10)) < parseISO(b.date.slice(0, 10))) {
        return -1;
      }
      return 0;
    });
  };

  const handleChangeActivity = (activityId: string): void => {
    const activity = activities?.find((activity) => activity.id === parseInt(activityId, 10));
    setValue("activity", activity);

    const clearedActivityLevelParticipants = watchedParticipants.map((p) => {
      if (p.reservationLevels[0]?.baseLevel) {
        delete p.reservationLevels[0].baseLevel;
      }

      return p;
    });

    replaceParticipants(clearedActivityLevelParticipants);
  };

  return (
    <form
      onSubmit={handleSubmit((data) => {
        const newReservation = { ...reservation };
        newReservation.daysTimes = data.daysTimes.map(({ key, ...rest }) => rest);
        newReservation.participants = data.participants.map(({ key, ...rest }) => rest);
        newReservation.startDate = data.startDate;
        newReservation.endDate = data.endDate;
        newReservation.productValue = data.productValue;
        newReservation.activity = data.activity;
        newReservation.meetingPoint = data.meetingPoint;
        const amendmentReason = data.amendmentReason;
        delete data.amendmentReason;
        submit(newReservation, amendmentReason);
      })}
    >
      <Stack spacing={3}>
        <Alert severity="info">{t("Last confirmed booking")}</Alert>

        <Stack direction="row" spacing={2}>
          <Controller name="startDate" control={control} render={({ field }) => <input type="hidden" {...field} />} />
          <Controller name="endDate" control={control} render={({ field }) => <input type="hidden" {...field} />} />
          <Controller
            name="activity.id"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                select
                onChange={(e) => {
                  handleChangeActivity(e.target.value);
                }}
              >
                {activities?.map((activity, i) => (
                  <MenuItem key={`activity-${i}`} value={activity.id}>
                    {activity.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <Controller
            name="productValue"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="number"
                label={t("booking.lessonPrice")}
                fullWidth
                InputProps={{
                  inputProps: { min: 0, step: 0.01 },
                  startAdornment: (
                    <InputAdornment position="start">
                      {" "}
                      {currencies?.length && currencies.find((c) => c.code === reservation.productCurrency).symbol}
                    </InputAdornment>
                  ),
                }}
                error={!!fieldState.error?.message || (confirmationToggle && !updatingPrice)}
                helperText={fieldState.error?.message}
                id="gross-value-amendment-input"
                value={field.value / 100}
                onChange={(e) => {
                  setValue("productValue", Math.round(parseFloat(e.target.value) * 100) as never);
                  setUpdatingPrice(true);
                }}
              />
            )}
          />
        </Stack>
        {confirmationToggle && !updatingPrice && <Alert severity="warning">Did you want to update the price?</Alert>}
        <Stack spacing={2}>
          <Typography variant="h5"> {t("Lesson Dates")}</Typography>

          {daysTimes.map((item, index) => (
            <DayTimeSelector
              fieldStub="daysTimes"
              key={item.key}
              index={index}
              dayTime={item as any}
              onChange={handleUpdateDayTime}
              onDelete={handleRemoveDayTime}
              control={control}
            />
          ))}
        </Stack>

        <Stack direction="row" justifyContent="center">
          <Button onClick={handleDayTimeAdd} variant="contained" color="secondary" endIcon={<AddIcon />}>
            {t("Add time")}
          </Button>
        </Stack>

        <Stack spacing={2}>
          <Typography variant="h5"> {t("Participants")}</Typography>

          {participants.map((item, index) => (
            <ParticipantSelector
              fieldStub="participants"
              activity={watchedActivity}
              key={item.key}
              index={index}
              participant={item as any}
              onDelete={handleRemoveParticipant}
              onChange={(index, participant) => updateParticipant(index, participant)}
              control={control}
            />
          ))}
        </Stack>

        <Stack direction="row" justifyContent="center">
          <Button onClick={handleAddParticipant} variant="contained" color="secondary" endIcon={<AddIcon />}>
            {t("Add Participant")}
          </Button>
        </Stack>

        <Controller
          control={control}
          name="meetingPoint"
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              value={field.value?.id}
              select
              fullWidth
              label={t("Meeting point")}
              error={!!error}
              helperText={error?.message}
              onChange={(event) => {
                setValue(
                  "meetingPoint",
                  meetingPoints.find((m) => m.id === parseInt(event.target.value, 10))
                );
              }}
              SelectProps={{
                native: isMobile,
              }}
            >
              {meetingPoints?.map((meetingPoint, index) => (
                <MenuItem key={`mP-${index}`} component={isMobile ? "option" : MenuItem} value={meetingPoint.id}>
                  {meetingPoint.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <Stack>
          <FormControl fullWidth>
            <MeetingPointSelector
              isAmendment={true}
              meetingPoint={meetingPoint}
              resorts={resorts as any[]}
              setMeetingPoint={(meetingPoint) => setValue("meetingPoint", meetingPoint)}
            />
          </FormControl>
        </Stack>

        <Controller
          name="amendmentReason"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              onChange={(e) => field.onChange(e)}
              multiline
              rows={4}
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              variant="filled"
              placeholder={t("Write your attached message here, please include the reason for your new offer")}
            />
          )}
        />

        {!confirmationToggle ? (
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button onClick={closeDialog} variant="outlined" color="secondary">
              {t("Cancel")}
            </Button>

            <Button onClick={() => setConfirmationToggle(true)} variant="contained" color="primary">
              {t("Continue")}
            </Button>
          </Stack>
        ) : (
          <Stack spacing={2}>
            <Alert severity="warning">
              {`${t("If this new offer becomes unavailable, you must either cancel or make a new amendment")}`}
            </Alert>

            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button onClick={closeDialog} variant="outlined" color="secondary">
                {t("Cancel")}
              </Button>

              <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                {t("Submit")}
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    </form>
  );
};
